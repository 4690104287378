import { AssignmentJob, AssignmentStage } from 'constants/assignment'
import { isEditingCategory, isShootingCategory } from 'utils/validators/categoryUtils'

import { ProductDTO } from 'models/product'
import { isProductWithCT } from 'utils/product/productUtils'

interface ProgressStep {
  step: number
  jobDone: string
  jobTodo: string
  activeStep: boolean
}

const shootingWithCTSteps = [
  { step: 1, jobDone: AssignmentJob.ORDER_RECEIVED, jobTodo: AssignmentJob.MATCHING_CREATIVE, activeStep: false },
  { step: 2, jobDone: AssignmentJob.CREATIVE_BOOKED, jobTodo: AssignmentJob.WAITING_FOR_SHOOTING, activeStep: false },
  { step: 3, jobDone: AssignmentJob.SHOOTING_PERFORMED, jobTodo: AssignmentJob.EDITING_VISUALS, activeStep: false },
  { step: 4, jobDone: AssignmentJob.ORDER_DELIVERED, jobTodo: '', activeStep: false }
]

const editingWithShootingSteps = [
  { step: 1, jobDone: AssignmentJob.ORDER_RECEIVED, jobTodo: AssignmentJob.WAITING_FOR_SELECTION, activeStep: false },
  { step: 2, jobDone: AssignmentJob.SELECTION_DONE, jobTodo: AssignmentJob.EDITING_VISUALS, activeStep: false },
  { step: 3, jobDone: AssignmentJob.ORDER_DELIVERED, jobTodo: '', activeStep: false }
]

const editingWithoutShootingSteps = [
  { step: 1, jobDone: AssignmentJob.ORDER_RECEIVED, jobTodo: AssignmentJob.EDITING_VISUALS, activeStep: false },
  { step: 2, jobDone: AssignmentJob.ORDER_DELIVERED, jobTodo: '', activeStep: false }
]

/** TBD we are missing AssignmentStages and sync for AssignmentJobs namings with BE team */
// const documentsSteps = [
//   { step: 1, jobDone: AssignmentJob.ORDER_RECEIVED, jobTodo: AssignmentJob.DOCUMENTS_WAITING_FOR_SIGNATURE, activeStep: false },
//   { step: 2, jobDone: AssignmentJob.DOCUMENTS_SIGNED, jobTodo: AssignmentJob.DOCUMENTS_IN_PROGRESS, activeStep: false },
//   { step: 3, jobDone: AssignmentJob.ORDER_DELIVERED, jobTodo: '', activeStep: false }
// ]

export const getProgressCategoryData = (stage: AssignmentStage, products: ProductDTO[]): Array<ProgressStep> => {
  if (!products[0]) return []

  let data = []

  const productCategory = products[0].category
  const isSomeProductWithCT = products.some(product => isProductWithCT(product))

  /** SHOOTING WITH CT PROGRESS */
  if (isShootingCategory(productCategory) && isSomeProductWithCT) {
    data = shootingWithCTSteps.map(step => ({ ...step }))

    switch (stage) {
      case AssignmentStage.MISSION_ORDER_PLACED:
      case AssignmentStage.PRE_PRODUCTION:
      case AssignmentStage.AUTOMATED:
      case AssignmentStage.WAITING_FOR_CT_TO_ACCEPT:
        data[0].activeStep = true
        break

      case AssignmentStage.CREATIVE_BOOKED:
        data[1].activeStep = true
        break

      case AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN:
      case AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR:
      case AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN:
        data[2].activeStep = true
        break

      case AssignmentStage.VISUALS_SENT_TO_CLIENT:
        data[3].activeStep = true
        break

      default:
        break
    }

    /** EDITING WITH SHOOTING */
  } else if (isShootingCategory(productCategory) && !isSomeProductWithCT) {
    data = editingWithShootingSteps.map(step => ({ ...step }))

    switch (stage) {
      case AssignmentStage.MISSION_ORDER_PLACED:
      case AssignmentStage.PRE_PRODUCTION:
      case AssignmentStage.AUTOMATED:
      case AssignmentStage.WAITING_FOR_CT_TO_ACCEPT:
      case AssignmentStage.CREATIVE_BOOKED:
        data[0].activeStep = true
        break

      case AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN:
      case AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR:
      case AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN:
        data[1].activeStep = true
        break

      case AssignmentStage.VISUALS_SENT_TO_CLIENT:
        data[2].activeStep = true
        break

      default:
        break
    }

    /** EDITING WITHOUT SHOOTING */
  } else if (isEditingCategory(productCategory)) {
    data = editingWithoutShootingSteps.map(step => ({ ...step }))
    switch (stage) {
      case AssignmentStage.MISSION_ORDER_PLACED:
      case AssignmentStage.PRE_PRODUCTION:
      case AssignmentStage.AUTOMATED:
      case AssignmentStage.WAITING_FOR_CT_TO_ACCEPT:
      case AssignmentStage.CREATIVE_BOOKED:
      case AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN:
      case AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR:
      case AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN:
        data[0].activeStep = true
        break

      case AssignmentStage.VISUALS_SENT_TO_CLIENT:
        data[1].activeStep = true
        break

      default:
        break
    }
    /** DOCUMENTS - TBD we are missing AssignmentStages from BE team */
    // } else if (isDocumentCategory(productCategory)) {
    //   data = documentsSteps.map(step => ({ ...step }))
    //   switch (stage) {
    //     case AssignmentStage.MISSION_ORDER_PLACED:
    //     case AssignmentStage...:
    //       data[0].activeStep = true
    //       break

    //     case AssignmentStage...:
    //     case AssignmentStage...:
    //       data[1].activeStep = true
    //       break

    //     case AssignmentStage...:
    //       data[2].activeStep = true
    //       break

    //     default:
    //       break
    //   }
  } else {
    return []
  }

  return data
}
