import { useCallback, useMemo, useState } from 'react'

import { APIRequestState } from 'constants/API'
import { AssignmentStage } from 'constants/assignment'
import { ActionTypeAPIData } from 'constants/redux'
import constate from 'constate'
import { AssignmentDTO } from 'models/assignment'
import { RootStore } from 'models/redux'
import { useSelector } from 'react-redux'
import { useGalleryStagingFlow } from '../GalleryStagingFlow'
import { useGalleryDeal } from '../_main/contexts/GalleryDeal.context'

/** Helper function, that returns whether assignment is in correct stage for staging */
export const _isStagingAvailable = (assignmentStage: AssignmentStage) => {
  return [AssignmentStage.MISSION_ORDER_PLACED, AssignmentStage.PRE_PRODUCTION].includes(assignmentStage)
}

export const [AvailableStagingsListContextProvider, useAvailableStagingsList] = constate(() => {

  const { stagingAssignments } = useGalleryDeal()
  const { stagingAssignmentId } = useGalleryStagingFlow()

  const stagingRequest = useSelector((state: RootStore) => stagingAssignmentId
    ? state.APIData[ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING][stagingAssignmentId]
    : undefined
  )

  const stagingRequestState = useMemo(() => {
    if (!!stagingRequest) {
      const stagingState = stagingRequest.state
      if (stagingState) return stagingState
      return APIRequestState.BEFORE_START
    }
    else return APIRequestState.BEFORE_START
  }, [stagingRequest])

  const [assignmentsToStage, setAssignmentsToStage] = useState<AssignmentDTO[]>([])
  const [finishedStagings, setFinishedStagings] = useState<Set<string>>(new Set([]))
  const [skipStaging, setSkipStaging] = useState(false)

  const hasStaging = useMemo(() => assignmentsToStage.length > 0, [assignmentsToStage.length])
  const isStagingFinished = useMemo(() => skipStaging || assignmentsToStage.length === finishedStagings.size, [assignmentsToStage.length, finishedStagings.size, skipStaging])
  const remainingStagingsCount = useMemo(() => assignmentsToStage.length - finishedStagings.size, [assignmentsToStage.length, finishedStagings.size])

  /** Initialize context with filtered assignments ready for staging */
  const initializeStaging = useCallback(() => {
    const assignmentsToSet = stagingAssignments.filter((assignment) => _isStagingAvailable(assignment.stage))
    setAssignmentsToStage(assignmentsToSet)
  }, [stagingAssignments])

  /** Resets staging data */
  const resetStaging = () => {
    setAssignmentsToStage([])
    setFinishedStagings(new Set([]))
    setSkipStaging(false)
  }

  return {
    hasStaging,
    isStagingFinished,
    stagingRequest,
    stagingRequestState,
    finishedStagings,
    assignmentsToStage,
    remainingStagingsCount,
    skipStaging,
    setFinishedStagings,
    initializeStaging,
    resetStaging,
    setSkipStaging,
  }

})
