import { GalleryStagingStep, useGalleryStagingFlow } from '../Gallery/GalleryStagingFlow'
import { useCallback, useState } from 'react'

import constate from 'constate'
import { useStyleSelectionContext } from 'components/common/StagingSelection'

export const [GalleryStagingContextProvider, useGalleryStaging] = constate(() => {

  const { setActiveStep } = useGalleryStagingFlow()

  const [isStagingsListContent, setIsStagingsListContent] = useState<boolean>(false)

  const { activeRoomIndex, setActiveRoomIndex } = useStyleSelectionContext()

  const onNextStep = useCallback(() => {
    // Goes to the next room
    setActiveRoomIndex(activeRoomIndex + 1)
  }, [activeRoomIndex, setActiveRoomIndex])

  const onPrevStep = useCallback(() => {
    // Goes to prev step
    if (activeRoomIndex === 0) return setActiveStep(GalleryStagingStep.VISUAL_SELECTION)
    // Goes to prev room
    if (activeRoomIndex > 0) return setActiveRoomIndex(activeRoomIndex - 1)

    return
  }, [activeRoomIndex, setActiveRoomIndex, setActiveStep])

  return {
    isStagingsListContent,
    setIsStagingsListContent,
    onNextStep,
    onPrevStep,
  }
})
