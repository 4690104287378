import { FC, MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo } from 'react'

import { APIRequestState } from 'constants/API'
import { VisualDownloadPopup } from 'components/pages/Gallery/VisualDownloadPopup'
import { useGalleryConstants } from 'components/pages/Gallery/_main/contexts/GalleryConstants.context'
import { useGalleryVisualsDownloadArchive } from 'components/pages/Gallery/_main/contexts/GalleryVisualsDownloadArchive.context'

/**
 * Gallery download popup component for wrapping and handling download and archive process. Displaying download Popup.
 * @example <GalleryVisualDownloadPopup />
 */
export const GalleryVisualDownloadPopup: FC = () => {
  const {
    beforeUnloadHandler,
  } = useGalleryConstants()
  const {
    isDownloadModalOpen,
    setDownloadModalOpen,
    initiateDownloadVisualsArchive,
    downloadVisualsArchive,
    archiveAdmin,
    downloadVisualsArchiveAdmin
  } = useGalleryVisualsDownloadArchive()

  const archiveDownloadPending = useMemo(() => {
    if (initiateDownloadVisualsArchive) {
      if (initiateDownloadVisualsArchive.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchive?.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchive?.state === APIRequestState.OK) return false
      return true
    } else return false
  }, [initiateDownloadVisualsArchive, downloadVisualsArchive])

  const archiveAdminDownloadPending = useMemo(() => {
    if (archiveAdmin) {
      if (archiveAdmin.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchiveAdmin?.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchiveAdmin?.state === APIRequestState.OK) return false
      return true
    } else return false
  }, [archiveAdmin, downloadVisualsArchiveAdmin])

  const closeDownloadModal = useCallback((e?: ReactMouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    if (archiveDownloadPending) return
    setDownloadModalOpen(false)
    // don't use setDownloadModalOpen as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveDownloadPending])

  // Warn about archive download in progress while leaving the browser tab
  useEffect(() => {
    if (archiveDownloadPending || archiveAdminDownloadPending) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    }
  }, [archiveDownloadPending, archiveAdminDownloadPending, beforeUnloadHandler])

  return (
    <VisualDownloadPopup
      isOpen={isDownloadModalOpen}
      onClickClose={!archiveDownloadPending ? closeDownloadModal : undefined}
    />
  )
}
