import React, { FC, useState } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import { Transition, TransitionGroup } from 'react-transition-group'
import { standardEnterEffect, standardExitEffect } from 'utils/animations'

import { CreativesUnavailableListDTO } from 'models/assignment'
import { DatesRange } from 'components/common/DatesRange'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { Search } from '@mui/icons-material'
import moment from 'moment'
import styles from './GalleryUnavailableCreativesPopup.module.sass'
import { useDebouncedMemo } from 'utils/helpers'
import { useGalleryAssignment } from '../../_main/contexts/GalleryAssignment.context'
import { useListUnavailableCTs } from 'dataQueries'
import { useTranslation } from 'react-i18next'

/**
 * Returns the key for a CtCard component.
 * @param ctData - The creative data.
 * @returns The unique key for the CtCard component.
 */
const _getCTCardKey = (ctData: CreativesUnavailableListDTO) => (
  `${ctData.creativeName}-${ctData.endUnavailability}-${ctData.workingHoursForAssignmentDay.length}`
)


/**
 * Represents a Creative Card component.
 */
const CtCard: FC<{ ctData: CreativesUnavailableListDTO, timezone: Nullable<string> }> = ({
  ctData: { workingHoursForAssignmentDay, startUnavailability, endUnavailability, creativeName },
  timezone,
}) => {
  const { t } = useTranslation(['gallery'])

  return (

    <div className={styles.ctCard}>

      <div className={styles.ctCardHeader}>
        <h3>{creativeName}</h3>
        <p>
          {t(!!startUnavailability && !!endUnavailability
            ? 'gallery:creatives_unavailable_list_popup:has_unavailability'
            : 'gallery:creatives_unavailable_list_popup:not_working'
          )}
        </p>
      </div>

      {!!startUnavailability && !!endUnavailability &&
        <div className={styles.section}>
          <DatesRange
            from={moment().startOf('day').toISOString(true)}
            to={moment().add(1, 'day').endOf('day').toISOString(true)}
            timezone={timezone}
          />
        </div>
      }

      {(!startUnavailability || !endUnavailability) &&
        <div className={styles.section}>

          <h4>
            {t(workingHoursForAssignmentDay.length > 0
              ? 'gallery:creatives_unavailable_list_popup:shooting_schedule'
              : 'gallery:creatives_unavailable_list_popup:day_off'
            )}
          </h4>

          {workingHoursForAssignmentDay.length > 0 &&
            <ul>
              {workingHoursForAssignmentDay.map((hours) =>
                <li key={`${hours.from}-${hours.to}`}>{hours.from} - {hours.to}</li>
              )}
            </ul>
          }

        </div>
      }

    </div>
  )
}

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether the modal is open */
  isOpen: boolean
  /** Onclick action triggered when user clicks on the close button */
  onClickClose: () => void
}

/**
 * @component GalleryUnavailableCreativesPopup displayed in Gallery page only for admins
 * @example
 * <GalleryUnavailableCreativesPopup isOpen={false} onClickClose={() => null} />
 */
export const GalleryUnavailableCreativesPopup: React.FC<Props> = ({ isOpen, onClickClose }) => {
  const { t } = useTranslation(['gallery'])
  const { assignmentData } = useGalleryAssignment()
  const isSmallScreen = useMediaQuery(MOBILE_VIEW_QUERY)

  const unavailableCTs = useListUnavailableCTs(assignmentData?.id, !!assignmentData?.id && isOpen)

  const [search, setSearch] = useState('')

  // List of creative's which are unavailable for the current assignment 
  const ctUnavailableList: CreativesUnavailableListDTO[] = useDebouncedMemo(() => {
    if (!unavailableCTs.data) return []
    if (search === '') return unavailableCTs.data

    const lowerCaseSearch = search.toLowerCase()

    return unavailableCTs.data.filter((item) => item.creativeName.toLowerCase().includes(lowerCaseSearch))
  }, [search, unavailableCTs.data])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClickClose}
      className={styles.modal}
      modalContentClassName={styles.modalContent}
      onClickOutside={onClickClose}
      afterClosed={() => setSearch('')}
      hasHeaderContentDivider
      title={t('gallery:creatives_unavailable_list_popup:title')}
    >
      <Stack direction="column" gap={3}>

        <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-start">

          <div className={styles.searchInput}>
            <Search fontSize="large" className={styles.icon} />
            <input
              type="text"
              value={search}
              placeholder={t('gallery:creatives_unavailable_list_popup:filter')}
              onChange={({ target }) => setSearch(target.value)}
            />
          </div>

        </Stack>

        {isSmallScreen
          ? (
            <Stack
              direction='column'
              justifyContent="flex-start"
              gap={2}
            >
              <TransitionGroup component={null}>
                {ctUnavailableList.map((ctData) =>
                  <Transition
                    key={_getCTCardKey(ctData)}
                    appear={true}
                    in={true}
                    onEnter={standardEnterEffect}
                    onExit={standardExitEffect}
                    timeout={{ enter: 350, exit: 350 }}
                  >
                    <CtCard ctData={ctData} timezone={assignmentData?.timezone} />
                  </Transition>
                )}
              </TransitionGroup>
            </Stack>
          )
          : (
            <div className={styles.wrap}>

              <Stack
                direction='column'
                justifyContent="flex-start"
                flex="0 0 50%"
                gap={2}
              >
                <TransitionGroup component={null}>
                  {ctUnavailableList.map((ctData, index) => index % 2 === 0 &&
                    <Transition
                      key={_getCTCardKey(ctData)}
                      appear={true}
                      in={true}
                      onEnter={standardEnterEffect}
                      onExit={standardExitEffect}
                      timeout={{ enter: 350, exit: 350 }}
                    >
                      <CtCard ctData={ctData} timezone={assignmentData?.timezone} />
                    </Transition>
                  )}
                </TransitionGroup>
              </Stack>

              <Stack
                direction='column'
                justifyContent="flex-start"
                flex="0 0 50%"
                gap={2}
              >
                <TransitionGroup component={null}>
                  {ctUnavailableList.map((ctData, index) => index % 2 !== 0 &&
                    <Transition
                      key={_getCTCardKey(ctData)}
                      appear={true}
                      in={true}
                      onEnter={standardEnterEffect}
                      onExit={standardExitEffect}
                      timeout={{ enter: 350, exit: 350 }}
                    >
                      <CtCard ctData={ctData} timezone={assignmentData?.timezone} />
                    </Transition>
                  )}
                </TransitionGroup>
              </Stack>

            </div>
          )

        }

      </Stack>
    </Modal>
  )
}
