import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionGetSubscriptionsStatus } from './getSubscriptionStatus.actions'
import { SubscriptionStatusStore } from './getSubscriptionStatus.interfaces'

/** Redux data reducer for getting subcription status */
export const reduceGetSubscriptionStatus = (state: SubscriptionStatusStore, action: ActionGetSubscriptionsStatus) => {
  const [eventType, dataType] = action.type

  if (dataType !== ActionTypeAPIData.GET_SUBSCRIPTION_STATUS) return state

  const { request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return request

    default:
      return state
  }
}
