import { AxiosError, AxiosResponse } from 'axios'
import { FC, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import Modal from 'components/common/Modals/Modal/Modal'
import { QueryStatus } from 'components/common/QueryStatus'
import { StatusResponse } from 'models/redux'
import { SubmitPayload } from 'dataQueries'
import { UseMutationResult } from '@tanstack/react-query'
import styles from './SubmitAssignmentModal.module.sass'
import { useAuth0 } from 'utils/auth'
import { useGalleryVisuals } from '../_main/contexts/GalleryVisuals.context'
import { useGalleryVisualsMeta } from '../_main/contexts/GalleryVisualsMeta.context'

interface Props {
  isOpen: boolean
  submitAssignmentResponse: UseMutationResult<AxiosResponse<StatusResponse, any>, AxiosError<unknown, any>, SubmitPayload, unknown>
  onSubmit: (creativeNote: string) => void
  onClose: () => void
  onAfterClosed: () => void
}

/**
 * Displays modal for finishing assignment submission.
 * Textarea for optional CT note for BKBN is present.
 * 
 * @example
 * <SubmitAssignmentModal
 *  isOpen={false}
 *  onSubmit={(creativeNote) => console.log(creativeNote)}
 *  onClose={() => alert('I've used to be an adventurer like you')}
 *  onAfterClosed={() => alert('But then i took an arrow into knee')}
 * />
 */
export const SubmitAssignmentModal: FC<Props> = ({
  isOpen,
  submitAssignmentResponse,
  onSubmit,
  onClose,
  onAfterClosed,
}) => {

  const { t } = useTranslation(['submit_assignment', 'gallery'])

  const { roles } = useAuth0()

  const {
    uploadMinCount,
    uploadMaxCount,
  } = useGalleryVisualsMeta()

  const {
    visualsCount,
  } = useGalleryVisuals()

  const [creativeNote, setCreativeNote] = useState<string>('')

  const handleClose = useCallback(() => {
    setCreativeNote('')
    onClose()
  }, [onClose])

  return (
    <Modal
      isOpen={isOpen}
      modalContentClassName={styles.modalBody}
      afterClosed={onAfterClosed}
      title={t('submit_assignment')}
    >

      <Trans t={t} i18nKey="info" parent="p">
        &nbsp;
      </Trans>

      <div className={styles.inputRow}>
        <label htmlFor="creativeNote">
          {t('note_label')}
        </label>
        <textarea
          rows={5}
          name="creativeNote"
          value={creativeNote}
          onChange={e => setCreativeNote(e.target.value)}
        ></textarea>
      </div>

      <div className={styles.footer}>

        <QueryStatus
          query={submitAssignmentResponse}
          successMessage={t('success')}
          spaceBottomRem={2}
        />

        {roles.isCreative && visualsCount < uploadMinCount &&
          <BlockInfo className={styles.warning}>
            <Trans
              t={t}
              i18nKey="gallery:min_upload_limit_hint"
              values={{ count: uploadMinCount }}
            />
          </BlockInfo>
        }

        {roles.isCreative && visualsCount > uploadMaxCount &&
          <BlockInfo className={styles.warning}>
            <Trans
              t={t}
              i18nKey="gallery:max_upload_limit_hint"
              values={{ count: uploadMaxCount }}
            />
          </BlockInfo>
        }

        <div className={styles.actionRow}>

          <Button
            type="secondary nobackground"
            onClick={handleClose}
            disabled={submitAssignmentResponse.isPending}
          >
            {t(!submitAssignmentResponse.isSuccess ? 'cancel' : 'close')}
          </Button>

          {!submitAssignmentResponse.isSuccess &&
            <Button
              onClick={_ => onSubmit(creativeNote)}
              disabled={submitAssignmentResponse.isPending}
            >
              {t('submit_assignment')}
            </Button>
          }

        </div>

      </div>

    </Modal>
  )
}
