import { FC } from 'react'
import { GalleryVirtualVisitInstructionsContextProvider } from './GalleryVirtualVisitInstructions.context'
import { GalleryVirtualVisitInstructionsController } from '../GalleryVirtualVisitInstructions/GalleryVirtualInstructions.controller'

/**
 * Section displaying the content of virtual visits upload instructions.
 * 
 * DEPENDENCIES:
 * - GalleryVirtualVisitInstructionsContextProvider
 * - GalleryAssignmentContextProvider
 *  
 * @example
 * <GalleryVirtualVisitInstructionsContextProvider>
 *   <GalleryVirtualVisitInstrucitionsController /> 
 * </GalleryVirtualVisitInstructionsContextProvider>
 */
export const GalleryVirtualVisitInstructions: FC = () => {

  return (
    <GalleryVirtualVisitInstructionsContextProvider>
      <GalleryVirtualVisitInstructionsController />
    </GalleryVirtualVisitInstructionsContextProvider>
  )
}
