import { BEIGE_400, BLACK_TEXT, CORAL_700, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { AssignmentDTOCreative } from 'models/assignment'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { DateTimeValidationError } from '@mui/x-date-pickers'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import { MUIButton } from 'components/common/MUIButton'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUISwitch } from 'components/common/MUISwitch'
import { MUITooltip } from 'components/common/MUITooltip'
import Modal from 'components/common/Modals/Modal/Modal'
import { QueryStatus } from 'components/common/QueryStatus'
import moment from 'moment-timezone'
import styles from './ScheduleMeetingPopup.module.sass'
import { supportEmailHref } from 'constants/contacts'
import { useArrangeDateTimeAssignment } from 'dataQueries'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTimezone } from 'components/contexts/timezone.context'

const ContactInfo: FC<{ title: string, icon: ReactNode }> = ({
  title,
  icon
}) => (
  <Stack flexDirection="row" justifyContent="center" alignItems="center" gap=".4rem">
    {icon}
    <Typography variant="text-sm" fontWeight={600} color={BLACK_TEXT}>{title}</Typography>
  </Stack>
)

interface Props {
  /** Creative assignment data to display and use */
  assignment: AssignmentDTOCreative
  /** Whether the popup is open or not */
  isOpen: boolean
  /** OnClose action to close the popup */
  onClose: () => void
}

/**
 * Popup for creative schedule meeting date and time with client.
 * @example
 *  <ScheduleMeetingPopup
 *    assignment={assignment}
 *    isOpen={true}
 *    onClose={() => setIsOpen(false)}
 *  />
 */
export const ScheduleMeetingPopup: FC<Props> = ({
  assignment,
  isOpen,
  onClose,
}) => {
  const minutesStep = 15
  const { t } = useTranslation(['deal_assignment_card', 'common'])
  const { userTimezone } = useTimezone()
  const { spawnSuccessToast } = useSnackbar()

  const scheduleAssignment = useArrangeDateTimeAssignment()

  const defaultDate = useMemo(() => {
    const now = moment()
    const minutesDiff = minutesStep - (now.minutes() % minutesStep)

    return moment(now)
      .add(minutesDiff, 'minutes')
      .set({ seconds: 0, milliseconds: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  const maxDate = useMemo(() => moment().add(1, 'month'), [])

  const [meetingDate, setMeetingDate] = useState(defaultDate)
  const [autoScheduleChecked, setAutoScheduleChecked] = useState<boolean>(false)
  const [agreementChecked, setAgreementChecked] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<DateTimeValidationError>(null)

  const submitArrangeDateTimeMeeting = useCallback(() => {
    if (!meetingDate || !agreementChecked) return

    scheduleAssignment.mutate(
      {
        assignmentId: assignment.id,
        dateTime: meetingDate.toISOString(true),
        arrangeAllAssignments: autoScheduleChecked
      },
      {
        onSuccess: () => {
          spawnSuccessToast(t('schedule_meeting_popup.submit_success_message'), { title: t('schedule_meeting_popup.submit_success_title') })
          onClose()
        },
      }
    )
  }, [agreementChecked, assignment.id, autoScheduleChecked, meetingDate, onClose, scheduleAssignment, spawnSuccessToast, t])

  const resetPopup = useCallback(() => {
    scheduleAssignment.reset()
    setMeetingDate(defaultDate)
    setAgreementChecked(false)
    setAutoScheduleChecked(false)
  }, [defaultDate, scheduleAssignment])

  return (
    <Modal
      isOpen={isOpen}
      title={t('schedule_meeting_popup.title')}
      modalContentClassName={styles.modal}
      onClose={onClose}
      afterClosed={resetPopup}
      footerContent={
        <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={2}>

          <MUIButton type="secondaryBorder" onClick={onClose}>
            {t('Cancel')}
          </MUIButton>

          <MUITooltip content={!!validationError && t('schedule_meeting_popup.validation_datetime_error')}>
            <MUIButton
              disabled={!agreementChecked || !!validationError}
              onClick={() => submitArrangeDateTimeMeeting()}
              isLoading={scheduleAssignment.isPending}
            >
              {t('schedule_meeting_popup.submit_schedule_meeting')}
            </MUIButton>
          </MUITooltip>

        </Stack>
      }
    >

      <Stack gap="2.4rem">

        <Typography variant="text-md" color={GRAY_900}>
          <Trans
            t={t}
            i18nKey={'schedule_meeting_popup.description'}
          >
            <a className={styles.contactLink} href={supportEmailHref()}>&nbsp;</a>
          </Trans>
        </Typography>

        <Stack gap=".9rem">
          <Typography variant="text-sm" fontWeight="medium" color={BLACK_TEXT}>{t('schedule_meeting_popup.contact_person')}</Typography>

          <BorderBoxWrapper
            boxShadow={undefined}
            backgroundColor={BEIGE_400}
            padding="1.2rem"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <ContactInfo title={assignment.contactPerson.name} icon={<AccountCircleOutlinedIcon />} />

            <ContactInfo title={assignment.contactPerson.phone} icon={<LocalPhoneRoundedIcon />} />

            <ContactInfo title={assignment.contactPerson.email} icon={<EmailRoundedIcon />} />
          </BorderBoxWrapper>
        </Stack>

        <Stack gap=".4rem">
          <Typography variant="text-xs" color={BLACK_TEXT}>{t('schedule_meeting_popup.date_and_time')}</Typography>

          <MUIDatePicker
            defaultValue={defaultDate}
            value={meetingDate}
            maxDate={maxDate}
            minDateTime={defaultDate}
            onChange={(date) => setMeetingDate(date || defaultDate)}
            timeSteps={{ minutes: minutesStep }}
            timezone={userTimezone}
            disablePast
            format='DD/MM/YYYY HH:mm'
            ampm={false}
            onError={(error) => setValidationError(error)}
          />
          {validationError === 'invalidDate' &&
            <Typography variant="text-sm" fontWeight="medium" color={CORAL_700}>{t('schedule_meeting_popup.validation_datetime_error')}</Typography>
          }
        </Stack>

        <Stack flexDirection="row" justifyContent="center" alignItems="flex-start" gap=".4rem">
          <MUISwitch
            checked={autoScheduleChecked}
            onChange={() => setAutoScheduleChecked(!autoScheduleChecked)}
            size='small'
            mt={1}
          />

          <Stack>
            <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>{t('schedule_meeting_popup.auto_schedule_title')}</Typography>
            <Typography variant="text-xs" color={GRAY_700}>{t('schedule_meeting_popup.auto_schedule_description')}</Typography>
          </Stack>
        </Stack>

        <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap=".8rem">
          <MUICheckbox
            checked={agreementChecked}
            onChange={(e) => setAgreementChecked(e.target.checked)}
          />

          <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>{t('schedule_meeting_popup.confirm_checkbox')}</Typography>
        </Stack>

      </Stack>

      {/* QUERY STATUS */}
      <Stack marginTop={2}>
        <QueryStatus query={scheduleAssignment} spaceTopRem={1} showStates={['error']} />
      </Stack>

    </Modal>
  )
}
