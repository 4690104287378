import { FC, Fragment, useEffect, useRef } from 'react'
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react'

import { ActionPopup } from 'components/common/Modals/ActionPopup/ActionPopup'
import { BKBNThemeWrapper } from 'components/common/BKBNThemeWrapper'
import { FeatureFlag } from 'utils/featureFlags/featureFlags'
import { MaintenancePage } from 'components/pages/Maintenance'
import PathLogger from '../PathLogger/PathLogger'
import { RouteSwitch } from '../RouteSwitch'
import { Snackbar } from 'components/common/Snackbar'
import TopBar from 'components/common/TopBar/TopBar'
import { hotjar } from 'react-hotjar'
import { initBecka } from 'utils/becka'
import styles from './App.module.sass'
import { useAppHeaderSwitch } from '../AppHeaderSwitch'
import { useAppStyleSwitch } from '../AppStyleSwitch'
import { useAuth0 } from '../../../utils/auth'
import { useLocation } from 'react-router'
import { useUserData } from 'components/contexts/UserDataContext'

export const AppController: FC = () => {

  const { baseUserData } = useUserData()
  const { routeHasNewDesign } = useAppStyleSwitch()
  const { routeHasNoHeader } = useAppHeaderSwitch()
  const { pathname } = useLocation()

  const isSiteInMaintenance = useFlag(FeatureFlag.IS_SITE_IN_MAINTANANCE)
  const { isAuthenticated, user } = useAuth0()
  const updateFFContext = useUnleashContext()

  const isBeckaInitialized = useRef(false)

  // Initialize Becka if allowed
  useEffect(() => {
    if (baseUserData && !isBeckaInitialized.current) {
      const initialized = initBecka(baseUserData.language?.toLowerCase())
      isBeckaInitialized.current = initialized
    }
  }, [baseUserData])

  useEffect(() => {
    if (isAuthenticated) updateFFContext({ userId: user?.email })
  }, [isAuthenticated, user, updateFFContext])

  // Update SPA state for hotjar
  useEffect(() => {
    const currentPath = pathname

    // Check if Hotjar has been initialized before calling its methods
    if (hotjar.initialized()) {
      hotjar.stateChange(currentPath)
    }
  }, [pathname])

  if (isSiteInMaintenance) return <MaintenancePage />

  const showHeader = !routeHasNoHeader

  return (
    <Fragment>
      <ActionPopup isGlobal={true} />

      <Snackbar />

      <PathLogger />

      {routeHasNewDesign &&
        <BKBNThemeWrapper>

          <div className={styles.appView}>

            {/* TODO: NEW MENU  -- this is only a placeholder*/}
            <div className={styles.menu}></div>
            <div className={styles.routerView}>
              <RouteSwitch />
            </div>
          </div>

        </BKBNThemeWrapper>
      }

      {!routeHasNewDesign &&
        <Fragment>
          {showHeader && <TopBar />}
          <RouteSwitch />
        </Fragment>
      }

    </Fragment>
  )
}
