import { ClientGalleryController } from 'components/pages/Client/ClientGallery/_main/ClientGallery.controller'
import { GalleryControllerLegacy } from './GalleryLegacy.controller'
import { useAuth0 } from 'utils/auth'

/**
 * @component GalleryRoleSwitch to resolve paths and functions shared for all roles Galleries
 */
export const GalleryRoleSwitch: React.FC = () => {
  const { roles } = useAuth0()

  if (roles.isClient) return <ClientGalleryController />

  return (
    <GalleryControllerLegacy />
  )
}
