export enum AnalyticsEvent {
  CLICK_ORDER_INFO_TAB = 'Order info tab clicked',
  CLICK_GALLERY_TAB = 'Gallery tab clicked',
  CLICK_GALLERY_STAGING = 'Gallery stage card clicked',
  CLICK_GALLERY_TAB_STAGE = 'Gallery stage tab clicked',
  CLICK_ORDER_DETAILS = 'See order details clicked',
  USE_GALLERY_FILTER = 'Gallery filter clicked',
  USE_COPY_LINK = 'Use Copy Link',
  GALLERY_PHOTO_FULL_PREVIEW = 'Full preview entered',
  CLICK_DOWNLOAD_VISUALS = 'Gallery floating bar download clicked',
  CLICK_DOWNLOAD_ALL_PURCHASED = 'Download all purchased clicked',
  CLICK_EDIT_ALL_PURCHASED = 'Edit all purchased clicked',
  CLICK_EDIT_VISUALS = 'Gallery floating bar edit visuals clicked',
  CLICK_APPLY_TEMPLATE_TO_ALL_PURCHASED = 'Apply template to all purchased clicked',
  CLICK_CONFIRM_VISUAL_SELECTION = 'Visual selection confirmed',
  GALLERY_SAAS_UPSELL_EDIT_VISUALS = 'Gallery saas upsell - edit visuals',
  GALLERY_SAAS_UPSELL_APPLY_TEMPLATE = 'Gallery saas upsell - apply template',
  GALLERY_SAAS_UPSELL_FLOATING_BAR_APPLY_TEMPLATE = 'Gallery saas upsell - floating bar apply template',
  GALLERY_SAAS_UPSELL_EDIT_ALL_PURCHASED = 'Gallery saas upsell - edit all purchased',
  GALLERY_SAAS_UPSELL_DOWNLOAD_WITH_TEMPLATE = 'Gallery saas upsell - floating bar download with template',
  GALLERY_SAAS_UPSELL_TEXT_GENERATOR = 'Gallery saas upsell - text generator',
  GALLERY_DOWNLOAD_TRIGGERED = 'download_triggered',
  GALLERY_MARK_FAVORITE = 'Gallery mark favorite',
  GALLERY_UNMARK_FAVORITE = 'Gallery unmark favorite',
  GALLERY_VIRTUAL_VISIT_OPEN_CSAT = 'Open gallery Virtual Visit CSAT',
  GALLERY_VIRTUAL_VISIT_CLOSE_CSAT = 'Close gallery Virtual Visit CSAT',
  REVOKE_INVITATION = 'Revoke Invitation',
  CREATIVE_FIRST_TIME_DASHBOARD = 'creative_first_time_dashboard',
  CLIENT_FIRST_TIME_DASHBOARD = 'client_first_time_dashboard',
  UPLOAD_ERROR = 'Upload Error',
  MEMBER_INVITATION_UPSELL = 'Member invitation upsell',
  ADMIN_DASHBOARD_ORDER_STATUS_SELECTION = 'OPS Filter - Status Tab',
  CREATIVE_DASHBOARD_ORDER_STATUS_SELECTION = 'CT Filter - Status Tab',
  CLIENT_DASHBOARD_ORDER_STATUS_SELECTION_ALL_VISUALS = 'All Visuals Filter - Status Tab',
  CLIENT_DASHBOARD_ORDER_STATUS_SELECTION_MY_VISUALS = 'My Visuals Filter - Status Tab',
  DASHBOARD_ORDER_DATE_SELECTION_ALL_VISUALS = 'All Visuals Filter - Date Tab',
  DASHBOARD_ORDER_DATE_SELECTION_MY_VISUALS = 'My Visuals Filter - Date Tab',
  ORDER_PURCHASED = 'Purchased',
  CLICK_SAVE_TEMPLATE = 'Click Save Template',
  CANCEL_DOWNLOAD_BULK_EDITING = 'Cancel Download Bulk Editing',
  WORKSPACE_PROFILE_CLICK_DELETE_USER = 'Delete_User_Request',
  WORKSPACE_PROFILE_CLICK_ADD_MEMBER = 'Add_Member_Click',
  WORKSPACE_PROFILE_CLICK_UNSUBSCRIBE = 'Unsubscribe_Request',
  WORKSPACE_PROFILE_ENTERED_WORKSPACES_PROFILE = 'Workspaces_Profile_Click',
  WORKSPACE_PROFILE_MANAGE_BILLING = 'Manage Billing',
  PURCHASE_FLOW_VAT_DISPLAYED_ON_BILLING_STEP = 'VAT_number_input_displayed_on_order_billing_selection_screen',
  PURCHASE_FLOW_ENTERS_ORDER_BILLING_STEP = 'enters_order_billing_selection_screen',
  PURCHASE_FLOW_ENTERS_ORDER_INSTRUCTIONS_STEP = 'enters_order_instructions_selection_screen',
  PURCHASE_FLOW_PURCHASE = 'Purchase',
  STAGING_OPEN_COMMENT_POPUP = 'Staging_open_comment_popup',
  STAGING_SAVE_COMMENT_POPUP = 'Staging_save_comment_popup',
  STAGING_EDIT_COMMENT_POPUP = 'Staging_edit_comment_popup',
  STAGING_REMOVE_COMMENT = 'Staging_remove_comment',
  CLOSE_PURCHASE_FLOW = 'Close_purchase_flow',
  PURCHASE_FLOW_CLICK_PREVIOUS_STEP = 'Clicks_previous_step_purchase_flow',
  PURCHASE_FLOW_CLICK_NEXT_STEP = 'Clicks_next_step_purchase_flow',
  PURCHASE_FLOW_SIZE_PROPERTY_SELECTED = 'Size_property_selected',
  PURCHASE_FLOW_OPTION_SELECTED = 'option_selected',
  PURCHASE_FLOW_OPTION_REMOVED = 'option_removed',
  PURCHASE_FLOW_FAST_DELIVERY_SELECTED = 'fast_delivery_selected',
  PURCHASE_FLOW_FAST_DELIVERY_REMOVED = 'fast_delivery_removed',
  PURCHASE_FLOW_I_ON_SITE_SELECTED = 'I_am_on_site_selected',
  PURCHASE_FLOW_PAYMENT_METHOD_SELECTED = 'Payment_method_selected',
  PURCHASE_FLOW_PAYMENT_METHOD_SAVED = 'Payment_method_saved_as_default',
  PURCHASE_FLOW_ORGANISATION_METHOD_SELECTED = 'organisation_method_selected',
  ROOM_TYPE_SELECTED = 'Room_types_selected',
  ROOM_TYPE_SAVED = 'Room_types_saved',
  FURNITURE_FLOW_OPENED = 'Furniture_flow_opened',
  FURNITURE_FLOW_CLOSED = 'Furniture_flow_closed',
  FURNITURE_TEMPLATES_SELECTED = 'Furniture_templates_selected',
  FURNITURE_TEMPLATES_SAVED = 'Furniture_templates_saved',
  FURNITURE_TEMPLATES_FILTER = 'Furniture_templates_filter',
  FLOOR_FLOW_OPENED = 'Floor_flow_opened',
  FLOOR_FLOW_CLOSED = 'Floor_flow_closed',
  FLOOR_TEMPLATES_SELECTED = 'Floor_templates_selected',
  FLOOR_TEMPLATES_SAVED = 'Floor_templates_saved',
  DECORATION_FLOW_OPENED = 'Decoration_flow_opened',
  DECORATION_FLOW_CLOSED = 'Decoration_flow_closed',
  DECORATION_TEMPLATES_SELECTED = 'Decoration_templates_selected',
  DECORATION_TEMPLATES_SAVED = 'Decoration_templates_saved',
  WALL_FLOW_OPENED = 'Wall_flow_opened',
  WALL_FLOW_CLOSED = 'Wall_flow_closed',
  WALL_TEMPLATES_SELECTED = 'Wall_templates_selected',
  WALL_TEMPLATES_SAVED = 'Wall_templates_saved',
  REMOVED_OBJECT_TYPE = 'Removed_object_type',
  PROFOLIO_OPENED = 'Portfolio_opened',
  VISUAL_QUALITY_DECLINED = 'Visual_quality_declined',
  LET_BKBN_DECIDE_SELECTED = 'Let_bkbn_decide_selected',
  CUSTOMISE_MYSELF_SELECTED = 'Customise_myself_selected',
  CREATIVE_REMOVED_BY_ADMIN = 'creative_removed_by_admin',
  CREATIVE_SELECTED_BY_ADMIN = 'creative_selected_by_admin',
  CREATIVE_ONBOARDED_BY_ADMIN = 'onboard_creative',
  CLIENT_ONBOARDED_BY_ADMIN = 'onboard_client',
  GUIDELINES_DENIED = 'guidelines_denied',
  GUIDELINES_VALIDATED = 'guidelines_validated',
  CREATIVE_ACCEPTS_ASSIGNMENT = 'creative_accepts_assignment',
  CREATIVE_SELECTED_BY_CLIENT = 'creative_selected_by_client',
  SELECT_CREATIVE_OPTION_OPENED = 'select_creative_option_opened',
}
