import { BundleProductTypes, ProductKind, ProductSegment, ProductType } from 'constants/product'
import { CatalogueProduct, useGetAddressConfig$, useGetCategoryConfig$, useGetInstructionConfig$, useGetProducts$ } from 'dataQueries/purchase.query'
import { GoogleAPIPlace, InstructionOptionDTO, Place } from 'models/purchaseFlow'
import { InstructionType, editingProductKindSortingOrder } from 'constants/purchaseFlow'
import { useEffect, useMemo, useState } from 'react'

import { Nullable } from 'models/helpers'
import { ProductCategory } from 'models/product'
import { bigFromDecimal } from 'utils/price'
import constate from 'constate'
import { getCityFromGooglePlace } from 'utils/location'
import { getCountryCodeFromGooglePlace } from 'utils/location/googlePlaceUtils'
import { isShootingCategory } from 'utils/validators'

type ProductTypeWithProducts = {
  productType: ProductType
  products: Array<CatalogueProduct>
}

type ProductKindWithProducts = {
  productKind: ProductKind
  products: Array<CatalogueProduct>
}

type ProductTypesPerSegmentCollection = Partial<Record<ProductSegment, {
  bundle: Array<ProductTypeWithProducts>,
  nonBundle: Array<ProductTypeWithProducts>,
  all: Array<ProductTypeWithProducts>,
}>>

type ProductKindsPerSegmentCollection = Partial<Record<ProductSegment, Array<ProductKindWithProducts>>>

export const [PurchaseFlowConfigProvider, usePurchaseFlowConfig] = constate(() => {

  // CATEGORY CONFIG
  const getCategoryConfig = useGetCategoryConfig$()

  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null)
  const [enableSkipAutomation, setEnableSkipAutomation] = useState<boolean>(false)

  const computedCategoryData = useMemo(
    () => ({
      sessionId: getCategoryConfig.data?.data.sessionId ?? null,
      skipCategoryStep: getCategoryConfig.data?.data.data.visible !== undefined ? !getCategoryConfig.data?.data.data.visible : false,
      defaultCategory: getCategoryConfig.data?.data.data.defaultCategory ?? null,
      shootingCategories: getCategoryConfig.data?.data.data.shootingCategories ?? [],
      editingCategories: getCategoryConfig.data?.data.data.editingCategories ?? [],
      documentCategories: getCategoryConfig.data?.data.data.documentCategories ?? [],
      fallbackCountryCode: getCategoryConfig.data?.data.data.countryCode,
    }),
    [getCategoryConfig.data?.data.sessionId, getCategoryConfig.data?.data.data]
  )

  // ADDRESS CONFIG
  const getAddressConfig = useGetAddressConfig$()

  const [selectedAssignmentPlace, setSelectedAssignmentPlace] = useState<GoogleAPIPlace | null>(null)

  const computedAddressData = useMemo(() => {
    let defaultAddressPlace: Place | null = null

    if (
      getAddressConfig.data?.data.data.defaultAddress
      && getAddressConfig.data?.data.data.defaultCoordinates
      && getAddressConfig.data?.data.data.defaultCountryCode
    ) {
      defaultAddressPlace = {
        address: getAddressConfig.data.data.data.defaultAddress,
        countryCode: getAddressConfig.data.data.data.defaultCountryCode,
        coordinate: getAddressConfig.data.data.data.defaultCoordinates,
      }
    }

    return {
      defaultAddressPlace,
      skipAddressStep: getAddressConfig.data?.data.data.visible !== undefined ? !getAddressConfig.data?.data.data.visible : false,
      selectedCity: getCityFromGooglePlace(selectedAssignmentPlace),
      selectedCountryCode: getCountryCodeFromGooglePlace(selectedAssignmentPlace),
    }
  }, [getAddressConfig?.data?.data.data, selectedAssignmentPlace])

  // PRODUCT CONFIG
  const getProductCatalogue = useGetProducts$()

  // It's a bit expensive, but is triggered only once per unique product config fetch
  const computedProductData = useMemo(() => {
    const availableSegments: Array<ProductSegment> = []
    const availableProductTypesPerSegment: ProductTypesPerSegmentCollection = {}
    const availableProductKindsPerSegment: ProductKindsPerSegmentCollection = {}

    const catalogueCurrency = getProductCatalogue.data?.data.data.currency
    const catalogueDiscount = bigFromDecimal(getProductCatalogue.data?.data.data.decimalDiscount.value || 0)
    const catalogueVat = bigFromDecimal(getProductCatalogue.data?.data.data.decimalVat.value || 0)

    if (!getProductCatalogue.isSuccess || !getProductCatalogue?.data?.data.data) return {
      availableSegments,
      availableProductTypesPerSegment,
      availableProductKindsPerSegment,
      hasSegments: false,
      catalogueCurrency,
      catalogueDiscount,
      catalogueVat,
    }

    for (const segment of Object.values(getProductCatalogue.data.data.data.segments)) {
      if (!segment?.key || !segment.productTypes) continue

      const productKindProductsMap: Partial<Record<ProductKind, ProductKindWithProducts>> = {}

      availableSegments.push(segment.key)

      for (const productTypeObject of Object.values(segment.productTypes)) {
        // Initialize values if undefined
        if (!availableProductTypesPerSegment[segment.key]) availableProductTypesPerSegment[segment.key] = {
          bundle: [],
          nonBundle: [],
          all: [],
        }
        if (!availableProductKindsPerSegment[segment.key]) availableProductKindsPerSegment[segment.key] = []

        const typeWithProducts = {
          productType: productTypeObject.key,
          products: productTypeObject.products
            .sort((productA, productB) => productA.price - productB.price)
            .map((product) => Object.assign(product, { type: productTypeObject.key }))
        }

        // Sort the kinds to bundled and nonBundled
        if (BundleProductTypes.has(productTypeObject.key)) availableProductTypesPerSegment[segment.key]!.bundle.push(typeWithProducts)
        else availableProductTypesPerSegment[segment.key]!.nonBundle.push(typeWithProducts)

        // Don't forget to push it to all collection
        availableProductTypesPerSegment[segment.key]!.all.push(typeWithProducts)

        // Group all products by kind -- yes, yes, another for loop
        for (const product of productTypeObject.products) {
          productKindProductsMap[product.kind] = {
            productKind: product.kind,
            products: [...(productKindProductsMap[product.kind]?.products || []), Object.assign(product, { type: productTypeObject.key })]
          }
        }
      }

      // Transform grouped products into sorted array of { kind: ProductKind, products: Array<CatalogueProduct> } objects
      availableProductKindsPerSegment[segment.key] = Object.values(productKindProductsMap)
        .sort((kindGroupA, kindGroupB) => editingProductKindSortingOrder.indexOf(kindGroupA.productKind) - editingProductKindSortingOrder.indexOf(kindGroupB.productKind))

      // Sort them
      availableProductTypesPerSegment[segment.key]?.bundle.sort((typeA, typeB) => {
        if (typeA.productType < typeB.productType) return -1
        if (typeA.productType > typeB.productType) return 1
        return 0
      })
      availableProductTypesPerSegment[segment.key]?.nonBundle.sort((typeA, typeB) => {
        if (typeA.productType < typeB.productType) return -1
        if (typeA.productType > typeB.productType) return 1
        return 0
      })

    }

    return {
      availableSegments: availableSegments.sort((segmentA, segmentB) => {
        if (segmentA < segmentB) return -1
        if (segmentA > segmentB) return 1
        return 0
      }),
      availableProductTypesPerSegment,
      availableProductKindsPerSegment,
      hasSegments: availableSegments.length > 0,
      catalogueCurrency,
      catalogueDiscount,
      catalogueVat,
    }

  }, [getProductCatalogue?.data?.data.data, getProductCatalogue.isSuccess])

  // INSTRUCTION CONFIG
  const getInstructionCatalogue = useGetInstructionConfig$()

  const computedInstructionData = useMemo(() => {
    const extractedData = getInstructionCatalogue.data?.data.data || null

    const allInstructionsByKind: Partial<Record<ProductKind, InstructionOptionDTO>> = {}
    const organizationInstructions: Partial<Record<ProductKind, InstructionOptionDTO>> = {}
    const availablePaymentMethods: Partial<Record<ProductKind, InstructionOptionDTO>> = {}
    // Extra Services can be only FAST_DELIVERY, CT_BOOKING options
    const extraServices: Partial<Record<ProductKind, InstructionOptionDTO>> = {}
    let externalReportingInstruction: Nullable<InstructionOptionDTO> = null

    for (const group of Object.values(extractedData?.instructionTypes || {})) {
      const instructionType = group.key

      for (const instruction of group.instructionOptions) {
        allInstructionsByKind[instruction.kind] = instruction


        if (instructionType === InstructionType.BILLING && instruction.kind === ProductKind.EXTERNAL_REPORTING) {
          externalReportingInstruction = instruction
          continue
        }

        if (instructionType === InstructionType.BILLING && instruction.isPrimary) {
          availablePaymentMethods[instruction.kind] = instruction
          continue
        }

        if (instructionType === InstructionType.ORGANIZATION) {
          organizationInstructions[instruction.kind] = instruction
          continue
        }

        extraServices[instruction.kind] = instruction
      }
    }

    // In case of adding any further conditions in InstructionListing file for SectionedBorderBox rendering, 
    // update also isInstructionStepSkipped
    const isInstructionStepSkipped = Object.values(organizationInstructions).length === 0
      && Object.values(extraServices).length === 0
      && (!selectedCategory || !isShootingCategory(selectedCategory))

    return {
      organizationInstructions,
      availablePaymentMethods,
      extraServices,
      externalReportingInstruction,
      billingAddress: extractedData?.billingAddress,
      catalogueTimezone: extractedData?.timezone,
      allInstructionsByKind,
      isInstructionStepSkipped,
    }
  }, [getInstructionCatalogue.data?.data.data, selectedCategory])

  // Invalidate productCatalogue on address/category change
  useEffect(
    () => {
      getProductCatalogue.reset()
    },

    // Exclude getProductCatalogue to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCategory, selectedAssignmentPlace]
  )

  return {
    getAddressConfig,
    getCategoryConfig,
    getProductCatalogue,
    getInstructionCatalogue,
    selectedCategory,
    selectedAssignmentPlace,
    enableSkipAutomation,
    setEnableSkipAutomation,
    setSelectedCategory,
    setSelectedAssignmentPlace,
    ...computedCategoryData,
    ...computedAddressData,
    ...computedProductData,
    ...computedInstructionData,
  }
})
