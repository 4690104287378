import { EntityKeys, QueryType } from 'utils/reactQuery'
import { FC, useCallback, useMemo } from 'react'
import { GRAY_900, GREEN_600, ORANGE_600 } from 'constants/styling/theme'
import { GalleryStagingStep, useGalleryStagingFlow, useGalleryStagingVisualSelection } from '../main/context'

import { APIRequestState } from 'constants/API'
import Box from '@mui/material/Box'
import { Color } from 'constants/assets'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { MUIButton } from 'components/common/MUIButton'
import { Path } from 'constants/router'
import ReactLoading from 'react-loading'
import Stack from '@mui/material/Stack'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import Typography from '@mui/material/Typography'
import { useClientGallery } from 'components/pages/Client/ClientGallery/_main/contexts/ClientGallery.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryStagingFlowList } from '../main/context/GalleryStagingFlowList.context'
import { useNavigate } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useRoomInventory } from 'components/contexts/RoomInventory.context'
import { useTranslation } from 'react-i18next'

/** @interface Props for the GalleryStagingFlowStatus component. */
interface Props {
  /** Request status of the staging. */
  status: APIRequestState
  /** Error message from the  */
  errorMessage?: string
}

/**
 * Gallery staging flow status after submitting the staging.
 * @example <GalleryStagingFlowStatus />
 */
export const GalleryStagingFlowStatus: FC<Props> = ({ status, errorMessage }) => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const { t } = useTranslation(['gallery_staging_flow'])

  const { remainingStagingsCount, setFinishedStagings } = useGalleryStagingFlowList()
  const { assignmentId } = useClientGallery()
  const { setSelectedVisuals } = useGalleryStagingVisualSelection()
  const {
    stagingAssignmentId,
    isAdvanceStagingPurchaseModalOpen,
    setIsAdvanceStagingPurchaseModalOpen,
    cleanupStaging,
    setActiveStep
  } = useGalleryStagingFlow()
  const { setRooms } = useRoomInventory()
  const { dealId } = useGalleryDeal()

  const countAfterCurrentStaging = useMemo(() => remainingStagingsCount - 1, [remainingStagingsCount])
  const isSuccess = useMemo(() => status === APIRequestState.OK, [status])
  const isLoading = useMemo(() => status === APIRequestState.RUNNING, [status])

  const handleGoToGallery = useCallback(async () => {
    // Refresh order data so the staging changes are reflected in the gallery.
    client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { dealId }] })
    cleanupStaging()
    navigate(Path.GALLERY.replace(':id', assignmentId))
  }, [assignmentId, cleanupStaging, client, dealId, navigate])

  const handleContinueStaging = useCallback(() => {
    setActiveStep(GalleryStagingStep.STAGING_LIST)
    setSelectedVisuals({})
    setRooms({})
    setFinishedStagings(prevStagings => {
      if (stagingAssignmentId) prevStagings.add(stagingAssignmentId)
      return prevStagings
    })

    if (isAdvanceStagingPurchaseModalOpen) {
      setIsAdvanceStagingPurchaseModalOpen(false)
    }

  }, [isAdvanceStagingPurchaseModalOpen, setActiveStep, setFinishedStagings, setIsAdvanceStagingPurchaseModalOpen, setRooms, setSelectedVisuals, stagingAssignmentId])

  if (isLoading) return (
    <Stack alignItems="center">
      <ReactLoading
        type="spin"
        color={Color.GRAY_TEXT}
      />
    </Stack>
  )

  return (
    <Box display="flex" alignItems="center" gap={1} flexDirection="column">

      {isSuccess
        ? (
          <TaskAltRoundedIcon
            sx={{
              fontSize: 48,
              color: GREEN_600,
            }}
          />
        )
        : (
          <HighlightOffRoundedIcon
            sx={{
              fontSize: 48,
              color: ORANGE_600,
            }}
          />
        )
      }

      <Stack alignItems="center">

        <Typography variant='text-xl' color={GRAY_900} fontWeight={600} textAlign="center">
          {isSuccess ? t('success_text') : t('error_text')}
        </Typography>

        {countAfterCurrentStaging > 0 &&
          <Typography variant='text-md' color={GRAY_900}>
            {t('finish_stagings_text')}
          </Typography>
        }

        {errorMessage &&
          <Typography variant='text-md' color={GRAY_900} marginTop={1}>
            {errorMessage ?? ''}
          </Typography>
        }

        <Stack direction="row" justifyContent="space-between" gap={3} marginTop={2}>

          <MUIButton type='secondaryBorder' onClick={(handleGoToGallery)}>
            {t('go_to_gallery')}
          </MUIButton>

          {countAfterCurrentStaging > 0 &&
            <MUIButton onClick={handleContinueStaging}>
              {t('go_to_staging_list')}
            </MUIButton>
          }

        </Stack>

      </Stack>

    </Box>
  )
}
