import { VisualsMarketingCategory, VisualsMarketingListingGoal, VisualsMarketingPropertyType, VisualsMarketingRoomType, VisualsMarketingSizeUnit, VisualsMarketingTargetAudience, VisualsMarketingToneOfVoice, propertyTypesWithFloor } from '../VisulalsMarketing.constants'
import { isEmail, isMinNumber, isPhoneNumber, isPositiveNumber } from 'utils/forms'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { Currency } from 'constants/misc'
import { FeatureFlag } from 'utils/featureFlags/featureFlags'
import { Language } from 'translations/Language'
import { MarketingRoomDTO } from 'models/visualsMarketing'
import { Nullable } from 'models/helpers'
import constate from 'constate'
import { getDefaultErrorMessage } from 'utils/forms/getDefaultErrorMessage'
import { useFlag } from '@unleash/proxy-client-react'
import { useForm } from 'react-hook-form'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useSavedMarketingPropertyDetails } from 'dataQueries/visualsMarketing.query'
import { useUserData } from 'components/contexts/UserDataContext'

interface FormValues {
  category: VisualsMarketingCategory
  propertyType: VisualsMarketingPropertyType
  goal: VisualsMarketingListingGoal
  floor: Nullable<number>
  floorSize: Nullable<number>
  floorSizeUnit: VisualsMarketingSizeUnit
  listingPrice: Nullable<number>
  listingPriceUnit: Currency

  propertyKeywords: string
  freeTextHighlights: string

  bedrooms: Nullable<number>
  bathrooms: Nullable<number>
  livingRooms: Nullable<number>
  kitchens: Nullable<number>
  totalRooms: Nullable<number>

  outputLanguage: Language
  toneOfVoice: Nullable<VisualsMarketingToneOfVoice>
  targetAudience: Nullable<VisualsMarketingTargetAudience>

  name: string
  phoneNumber: string
  email: string
}

/**
 * Converts nullable room count to either number or -1 for nullish values.
 * 0 is treated as a non-nullish number
 */
const _coerceRoomCount = (roomCount: Nullable<number>): number => {
  if (typeof roomCount !== 'number') return -1
  if (roomCount === null || roomCount === undefined) return -1
  if (isNaN(roomCount)) return -1
  if (roomCount < 0) return -1
  return roomCount
}

export const [VisualsMarketingFormProvider, useVisualsMarketingForm] = constate(() => {

  const allowPropertyKeywords = useFlag(FeatureFlag.ALLOW_PROPERTY_KEYWORDS)
  const allowFreeTextHighlights = useFlag(FeatureFlag.ALLOW_PROPERTY_FREETEXT)
  const { dealId } = useGalleryDeal()
  const savedPropertyDetailsQuery = useSavedMarketingPropertyDetails(dealId)
  const { baseUserData } = useUserData()

  const { dealData } = useGalleryDeal()

  const formUtils = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      category: VisualsMarketingCategory.RESIDENTIAL,
      propertyType: VisualsMarketingPropertyType.APARTMENT,
      goal: VisualsMarketingListingGoal.SELL,
      floor: null,
      floorSize: null,
      floorSizeUnit: VisualsMarketingSizeUnit.SQM,
      listingPrice: null,
      listingPriceUnit: Currency.EUR,

      propertyKeywords: '',
      freeTextHighlights: '',

      bedrooms: null,
      bathrooms: null,
      livingRooms: null,
      kitchens: null,
      totalRooms: null,

      outputLanguage: baseUserData?.language as Language || Language.EN,
      toneOfVoice: null,
      targetAudience: null,

      name: '',
      phoneNumber: '',
      email: '',
    },
  })

  // FIELDS
  const category = useMemo(() => formUtils.register('category', { required: getDefaultErrorMessage('required') }), [formUtils])
  const propertyType = useMemo(() => formUtils.register('propertyType', { required: getDefaultErrorMessage('required') }), [formUtils])
  const goal = useMemo(() => formUtils.register('goal', { required: getDefaultErrorMessage('required') }), [formUtils])
  const floor = useMemo(() => formUtils.register('floor', { valueAsNumber: true }), [formUtils])
  const floorSize = useMemo(() => formUtils.register('floorSize', { valueAsNumber: true, validate: { isMinNumber: value => isMinNumber({ value, min: 1 }) } }), [formUtils])
  const floorSizeUnit = useMemo(() => formUtils.register('floorSizeUnit', { required: getDefaultErrorMessage('required') }), [formUtils])
  const listingPrice = useMemo(() => formUtils.register('listingPrice', { valueAsNumber: true, validate: { isMinNumber: value => isMinNumber({ value, min: 1 }) } }), [formUtils])
  const listingPriceUnit = useMemo(() => formUtils.register('listingPriceUnit', { required: getDefaultErrorMessage('required') }), [formUtils])
  const propertyKeywords = useMemo(() => formUtils.register('propertyKeywords'), [formUtils])
  const freeTextHighlights = useMemo(() => formUtils.register('freeTextHighlights'), [formUtils])
  const bedrooms = useMemo(() => formUtils.register('bedrooms', { valueAsNumber: true, validate: { isPositive: value => isPositiveNumber({ value }) } }), [formUtils])
  const bathrooms = useMemo(() => formUtils.register('bathrooms', { valueAsNumber: true, validate: { isPositive: value => isPositiveNumber({ value }) } }), [formUtils])
  const livingRooms = useMemo(() => formUtils.register('livingRooms', { valueAsNumber: true, validate: { isPositive: value => isPositiveNumber({ value }) } }), [formUtils])
  const kitchens = useMemo(() => formUtils.register('kitchens', { valueAsNumber: true, validate: { isPositive: value => isPositiveNumber({ value }) } }), [formUtils])
  const totalRooms = useMemo(() => formUtils.register('totalRooms', { valueAsNumber: true, validate: { isPositive: value => isPositiveNumber({ value }) } }), [formUtils])
  const outputLanguage = useMemo(() => formUtils.register('outputLanguage', { required: getDefaultErrorMessage('required') }), [formUtils])
  const toneOfVoice = useMemo(() => formUtils.register('toneOfVoice'), [formUtils])
  const targetAudience = useMemo(() => formUtils.register('targetAudience'), [formUtils])
  const name = useMemo(() => formUtils.register('name'), [formUtils])
  const phoneNumber = useMemo(
    () => formUtils.register('phoneNumber', {
      validate: {
        isPhoneNumber: value => isPhoneNumber({ value: value.replace(' ', '') }),
      },
    }),
    [formUtils]
  )
  const email = useMemo(
    () => formUtils.register('email', {
      validate: {
        isEmail: value => !value || isEmail({ value }),
      },
    }),
    [formUtils]
  )

  // VALUE WATCHERS
  const goalValue = formUtils.watch('goal')
  const propertyKeywordsValue = formUtils.watch('propertyKeywords')
  const freeTextHighlightsValue = formUtils.watch('freeTextHighlights')
  const propertyTypeValue = formUtils.watch('propertyType')
  const propertyCategoryValue = formUtils.watch('category')

  // UTILS
  /** Fills form with data from propertyDetails */
  const fillWithFetchedData = useCallback(() => {
    const savedData = savedPropertyDetailsQuery.data
    if (!savedData) return

    const roomMap: Partial<Record<VisualsMarketingRoomType, number>> = !savedData.rooms.length
      ? {}
      : savedData.rooms.reduce((obj, room) => ({
        ...obj,
        [room.roomType]: room.count,
      }), {})

    formUtils.setValue('category', savedData?.usage || VisualsMarketingCategory.RESIDENTIAL)
    formUtils.setValue('propertyType', savedData?.type || VisualsMarketingPropertyType.APARTMENT)
    formUtils.setValue('goal', savedData?.listingType || VisualsMarketingListingGoal.SELL)
    formUtils.setValue('floor', savedData?.floorLevel || null)
    formUtils.setValue('floorSize', savedData?.area?.value || null)
    formUtils.setValue('floorSizeUnit', savedData?.area?.unit || VisualsMarketingSizeUnit.SQM)
    formUtils.setValue('listingPrice', savedData?.price?.amount || null)
    formUtils.setValue('listingPriceUnit', savedData?.price?.currency || Currency.EUR)
    formUtils.setValue('propertyKeywords', !!savedData?.keywords ? savedData.keywords.join(';') : '')
    formUtils.setValue('freeTextHighlights', savedData?.freeTextHighlights || '')
    formUtils.setValue('bedrooms', roomMap[VisualsMarketingRoomType.BEDROOM] || null)
    formUtils.setValue('bathrooms', roomMap[VisualsMarketingRoomType.BATHROOM] || null)
    formUtils.setValue('livingRooms', roomMap[VisualsMarketingRoomType.LIVING_ROOM] || null)
    formUtils.setValue('kitchens', roomMap[VisualsMarketingRoomType.KITCHEN] || null)
    formUtils.setValue('totalRooms', savedData?.numberOfRooms || null)
    formUtils.setValue('name', savedData?.contactPerson?.name || '')
    formUtils.setValue('phoneNumber', savedData?.contactPerson?.phoneNumber || '')
    formUtils.setValue('email', savedData?.contactPerson?.email || '')
  }, [formUtils, savedPropertyDetailsQuery.data])

  /** Serialize form data into proper DTO formats */
  const getSerializedData = useCallback(() => {
    if (!formUtils.formState.isValid) return
    if (!dealData?.id) return

    const values = formUtils.getValues()

    const rooms: MarketingRoomDTO[] = [
      {
        roomType: VisualsMarketingRoomType.BATHROOM,
        count: _coerceRoomCount(values.bathrooms),
      },
      {
        roomType: VisualsMarketingRoomType.BEDROOM,
        count: _coerceRoomCount(values.bedrooms),
      },
      {
        roomType: VisualsMarketingRoomType.KITCHEN,
        count: _coerceRoomCount(values.kitchens),
      },
      {
        roomType: VisualsMarketingRoomType.LIVING_ROOM,
        count: _coerceRoomCount(values.livingRooms),
      },
    ]

    return {
      textSettings: {
        tone: values.toneOfVoice || null,
        targetAudience: values.targetAudience || null,
        language: values.outputLanguage,
      },
      propertyInfo: {
        address: dealData.address,
        usage: values.category,
        type: values.propertyType,
        floorLevel: propertyTypesWithFloor.has(values.propertyType) ? values.floor : undefined,
        area: typeof values.floorSize === 'number'
          ? {
            value: values.floorSize,
            unit: values.floorSizeUnit,
          }
          : undefined,
        price: typeof values.listingPrice === 'number'
          ? {
            amount: values.listingPrice,
            currency: values.listingPriceUnit,
          }
          : undefined,
        numberOfRooms: typeof values.totalRooms === 'number' ? values.totalRooms : undefined,
        rooms: rooms.filter(({ count }) => count !== -1),
        contactPerson: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
        },
        listingType: values.goal,
        keywords: allowPropertyKeywords && !allowFreeTextHighlights && !!values.propertyKeywords ? values.propertyKeywords.split(';') : [],
        freeTextHighlights: allowFreeTextHighlights && !!values.freeTextHighlights ? values.freeTextHighlights : '',
      },
    }
  }, [allowFreeTextHighlights, allowPropertyKeywords, dealData?.address, dealData?.id, formUtils])

  // PREFILL FORM WITH SAVED DATA
  const hasBeenPrefilled = useRef(false)
  useEffect(() => {
    if (savedPropertyDetailsQuery.isSuccess && !hasBeenPrefilled.current && !!savedPropertyDetailsQuery.data) {
      fillWithFetchedData()
      hasBeenPrefilled.current = true
    }
  }, [fillWithFetchedData, formUtils, savedPropertyDetailsQuery.data, savedPropertyDetailsQuery.isSuccess])

  // RESET FORM ON DESTROY
  useEffect(() => {
    return () => formUtils.reset()
  }, [formUtils])

  return {
    // FIELDS
    category,
    propertyType,
    goal,
    floor,
    floorSize,
    floorSizeUnit,
    listingPrice,
    listingPriceUnit,
    propertyKeywords,
    freeTextHighlights,
    bedrooms,
    bathrooms,
    livingRooms,
    kitchens,
    totalRooms,
    outputLanguage,
    toneOfVoice,
    targetAudience,
    name,
    phoneNumber,
    email,

    // VALUES
    goalValue,
    propertyKeywordsValue,
    freeTextHighlightsValue,
    propertyTypeValue,
    propertyCategoryValue,

    // UTILS
    formUtils,
    savedPropertyDetailsQuery,
    getSerializedData,
    fillWithFetchedData,
  }
})
