import { useGetOrganization$, useGetUserClient$ } from 'dataQueries'
import { ClientType, PlatformUserBase, PreferredPaymentMethodType, SubscriptionPlan } from 'models/user'
import { useMemo, useState } from 'react'

import { useUserData } from 'components/contexts/UserDataContext'
import { CommonStatusCode } from 'constants/API'
import { FINISHED_QUERY_STATES } from 'constants/misc'
import { PreferredPaymentMethodEnum } from 'constants/user'
import constate from 'constate'
import { Nullable } from 'models/helpers'
import { ActiveSubscriptionStatuses } from 'models/user/userWorkspaceDTOs'
import { useAuth0 } from 'utils/auth'
import { getUserTimezone } from 'utils/time'
import { UserIsPlatformUserClient } from 'utils/typeguards'

const differentRoleStatus = 422

const orderBlockingClientPaymentMethods = new Set<PreferredPaymentMethodType>([PreferredPaymentMethodEnum.CARD_PAYMENT, PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT])

export enum PFClientSelectionError {
  NOT_FOUND = 'NOT_FOUND',
  NOT_CLIENT = 'NOT_CLIENT',
  CANNOT_ORDER_FOR = 'CANNOT_ORDER_FOR',
  NO_DATA = 'NO_DATA'
}

/**
 * Describes user for whom is order being filled for.   
 * Extends PlatformUserBase.
 */
export interface PurchaseFlowTargetOrderUser extends PlatformUserBase {
  /** Whether user is subscribed (Is member of at least one non-personal workspace) */
  isSubscribed: boolean
  /** Preferred payment method of user */
  preferredPaymentMethod: Nullable<PreferredPaymentMethodType>
  /** Client type of user */
  clientType: Nullable<ClientType>
  /** VAT number of user */
  vatNumber: Nullable<string>
  /** User's timezone calculated with defaultTimezone with fallback to browser timezone */
  timezone: string,
  /** Whether client can use INVOICE_BY_EMAIL payment method */
  invoiceAllowed: boolean
}

export const [TargetOrderUserProvider, useTargetOrderUser] = constate(() => {
  const { roles } = useAuth0()
  const { hasUserActiveSubscription, baseUserData, clientData, organizationData } = useUserData()

  const [adminSelectedUserEmail, setAdminSelectedUserEmail] = useState<string>('')

  const getPFClient = useGetUserClient$()
  const getPFOrganization = useGetOrganization$()

  const clientSelectionError = useMemo(() => {
    const userData = getPFClient.data?.data
    const requestStatus = getPFClient.data?.status

    if (!FINISHED_QUERY_STATES.has(getPFClient.status)) return PFClientSelectionError.NO_DATA

    if (getPFClient.isError && getPFClient.error.response?.status === CommonStatusCode.NOT_FOUND) return PFClientSelectionError.NOT_FOUND
    if (!userData) return PFClientSelectionError.NOT_FOUND

    if (requestStatus === differentRoleStatus) return PFClientSelectionError.NOT_CLIENT

    if (!UserIsPlatformUserClient(userData)) return undefined

    const clientHasDirectPreferredPaymentMethod = !!userData.preferredPaymentMethod && orderBlockingClientPaymentMethods.has(userData.preferredPaymentMethod)
    if (roles.isAdmin && clientHasDirectPreferredPaymentMethod) return PFClientSelectionError.CANNOT_ORDER_FOR

    return undefined
  }, [getPFClient.data?.data, getPFClient.data?.status, getPFClient.error?.response?.status, getPFClient.isError, getPFClient.status, roles.isAdmin])

  /**
   * Contains data of User that is the target (owner) of this Order.   
   * In case of User ordering, or Admin ordering on behalf of him/her self, it will contain data from user/me.   
   * In case of Admin ordering on behalf of other Client/Admin it will contain fetched data.   
   *    
   * **!BECAUSE OF TECHNICAL LIMITATION, ADMINS ARE TREATED AS CLIENTS!**
   */
  const targetUser = useMemo<PurchaseFlowTargetOrderUser>(() => {
    const userMeBasedTargetUser: PurchaseFlowTargetOrderUser = {
      ...baseUserData,
      isSubscribed: hasUserActiveSubscription,
      preferredPaymentMethod: clientData?.preferredPaymentMethod,
      clientType: clientData?.clientType,
      vatNumber: clientData?.vatNumber,
      timezone: getUserTimezone(baseUserData),
      invoiceAllowed: hasUserActiveSubscription || !!organizationData?.invoiceAllowed,
    }

    const fetchedUserData = getPFClient.data?.data

    // If logged user is Client OR no selectedClientData is in state OR selectedClient is the same as current user
    if (roles.isClient) return userMeBasedTargetUser
    if (!fetchedUserData) return userMeBasedTargetUser
    if (fetchedUserData.email === baseUserData?.email) return userMeBasedTargetUser

    // TYPEGUARD ONLY - provide most restrictive values
    if (!UserIsPlatformUserClient(fetchedUserData)) return {
      isSubscribed: false,
      preferredPaymentMethod: null,
      vatNumber: null,
      clientType: null,
      timezone: getUserTimezone(),
      invoiceAllowed: false,
    }

    const {
      id,
      name,
      email,
      phone,
      country,
      defaultTimezone,
      language,
      preferredPaymentMethod,
      vatNumber,
      clientType,
      workspaces
    } = fetchedUserData

    return {
      id,
      name,
      email,
      phone,
      country,
      defaultTimezone,
      language,
      preferredPaymentMethod,
      vatNumber,
      clientType,
      isSubscribed: !!workspaces.find(workspace => workspace.plan !== SubscriptionPlan.PERSONAL && ActiveSubscriptionStatuses.has(workspace.status)),
      timezone: getUserTimezone(fetchedUserData),
      invoiceAllowed: !!getPFOrganization.data?.data?.invoiceAllowed,
    }

  }, [baseUserData, hasUserActiveSubscription, clientData?.preferredPaymentMethod, clientData?.clientType, clientData?.vatNumber, organizationData?.invoiceAllowed, getPFClient.data?.data, roles.isClient, getPFOrganization.data?.data?.invoiceAllowed])


  return {
    targetUser,
    adminSelectedUserEmail,
    getPFClient,
    clientSelectionError,
    getPFOrganization,
    setAdminSelectedUserEmail,
  }
})
