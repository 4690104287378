import { APIRequestErrorType, APIRequestState } from 'constants/API'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArchiveVisualsTag, purgeArchiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals'

import { ActionTypeAPIData } from 'constants/redux'
import constate from 'constate'
import { APIRequest } from 'models/API'
import { RootStore } from 'models/redux'
import { resetDownloadArchiveVisuals } from 'redux/Individual/Visual/DownloadArchive'
import { useGalleryAssignment } from '../../_main/contexts/GalleryAssignment.context'

export const [VisualDownloadPopupContextProvider, useVisualDownloadPopup] = constate(() => {

  const dispatch = useDispatch()

  const { isRatingsFinished, assignmentData } = useGalleryAssignment()

  // DOWNLOAD THINGS
  const prepareVisualsDownloadSlice = useSelector((state: RootStore) => {
    if (!assignmentData?.id) return null
    return state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentData.id]?.[ArchiveVisualsTag.CLIENT_ARCHIVE]
  })
  const prepareVisualsDownloadJobId = useMemo(() => (prepareVisualsDownloadSlice && prepareVisualsDownloadSlice.data?.id) || '', [prepareVisualsDownloadSlice])
  const downloadVisualsSlice = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[prepareVisualsDownloadJobId])

  const syntheticDownloadRequest = useMemo(() => {
    let request: APIRequest<null> = APIRequest.factoryRunning(null)

    if (downloadVisualsSlice?.state === APIRequestState.OK) {
      if (downloadVisualsSlice.data?.url) {
        request = APIRequest.factoryOK(null)
      } else {
        request = APIRequest.factoryError(APIRequestErrorType.UNKNOWN_ERROR, {
          data: {
            message: 'No url in returned data',
            status: 'BAD RESPONSE',
            timestamp: new Date().toISOString()
          }
        })
      }
    } else if (prepareVisualsDownloadSlice?.state === APIRequestState.ERROR) {
      request = APIRequest.factoryError(prepareVisualsDownloadSlice.error_type, prepareVisualsDownloadSlice.error)
    } else if (downloadVisualsSlice?.state === APIRequestState.ERROR) {
      request = APIRequest.factoryError(downloadVisualsSlice.error_type, downloadVisualsSlice.error)
    }
    return request
  }, [prepareVisualsDownloadSlice, downloadVisualsSlice])

  const hasDownloadFailed = useMemo(() => syntheticDownloadRequest.state === APIRequestState.ERROR, [syntheticDownloadRequest])
  const isDownloadReady = useMemo(() => syntheticDownloadRequest.state === APIRequestState.OK, [syntheticDownloadRequest])
  const [hasDownloadStarted, setHasDownloadStarted] = useState<boolean>(false)
  const downloadUrl = useMemo(() => downloadVisualsSlice?.data?.url, [downloadVisualsSlice?.data?.url])

  // CSAT
  const [hasRatingBeenFinishedFromStart, setHasRatingBeenFinishedFromStart] = useState(isRatingsFinished)
  const hasFinishedStagingBeenSet = useRef(false)

  useEffect(() => {
    if (assignmentData?.id && !hasFinishedStagingBeenSet.current) {
      setHasRatingBeenFinishedFromStart(isRatingsFinished)
      hasFinishedStagingBeenSet.current = true
    }
  }, [assignmentData, isRatingsFinished])

  // MISC
  const resetPopup = useCallback(() => {
    setHasDownloadStarted(false)
    setHasRatingBeenFinishedFromStart(isRatingsFinished)
    if (assignmentData?.id) {
      dispatch(resetDownloadArchiveVisuals(prepareVisualsDownloadJobId, assignmentData.id))
      dispatch(purgeArchiveVisuals(assignmentData.id, ArchiveVisualsTag.CLIENT_ARCHIVE))
    }
  }, [assignmentData, dispatch, isRatingsFinished, prepareVisualsDownloadJobId])

  return {
    // VALUES
    isCSATFinished: hasRatingBeenFinishedFromStart,
    downloadRequest: syntheticDownloadRequest,
    isDownloadReady,
    hasDownloadFailed,
    hasDownloadStarted,
    downloadUrl,

    // SETTERS
    setHasDownloadStarted,

    // FNC
    resetPopup,
  }
})
