// TODO: Revisit everything in this file with the release of the 3D version after closed deal with Esoft

import { FloorPlan3DOptions } from './floorPlanOptions'
import basic_scalebar from 'assets/img/floorplan/2D/2D_scalebar.webp'
import compass_3D from 'assets/img/floorplan/3D/compass_45_degrees.webp'
import dimensions_chains from 'assets/img/floorplan/2D/2D_dimension_chains.webp'
import dimensions_each_room from 'assets/img/floorplan/2D/2D_dimensions_each_room.webp'
import logo_3D from 'assets/img/floorplan/3D/logo_3D.webp'
import modern_grey_3D from 'assets/img/floorplan/3D/modern_grey_3D.webp'
import modern_grey_3D_45_deg from 'assets/img/floorplan/3D/modern_grey_3D_45_deg.webp'
import modern_grey_3D_furniture from 'assets/img/floorplan/3D/modern_grey_3D_furniture.webp'
import modern_white_3D from 'assets/img/floorplan/3D/modern_white_3D.webp'
import modern_white_3D_45_deg from 'assets/img/floorplan/3D/modern_white_3D_45_deg.webp'
import modern_white_3D_furniture from 'assets/img/floorplan/3D/modern_white_3D_furniture.webp'
import neutral_stock_3D from 'assets/img/floorplan/3D/neutral_stock_3D.webp'
import neutral_stock_3D_45_deg from 'assets/img/floorplan/3D/neutral_stock_3D_45_deg.webp'
import neutral_stock_3D_furniture from 'assets/img/floorplan/3D/neutral_stock_3D_furniture.webp'
import new_building_grey_3D from 'assets/img/floorplan/3D/new_building_grey_3D.webp'
import new_building_grey_3D_45_deg from 'assets/img/floorplan/3D/new_building_grey_3D_45_deg.webp'
import new_building_grey_3D_furniture from 'assets/img/floorplan/3D/new_building_grey_3D_furniture.webp'
import new_building_white_3D from 'assets/img/floorplan/3D/new_building_white_3D.webp'
import new_building_white_3D_45_deg from 'assets/img/floorplan/3D/new_building_white_3D_45_deg.webp'
import new_building_white_3D_furniture from 'assets/img/floorplan/3D/new_building_white_3D_furniture.webp'
import room_area_3D from 'assets/img/floorplan/3D/room_area_3D.webp'
import room_names_3D from 'assets/img/floorplan/3D/room_names_3D.webp'

export enum Styles {
  MODERN_GREY = 'MODERN_GREY',
  MODERN_WHITE = 'MODERN_WHITE',
  NEUTRAL_STOCK = 'NEUTRAL_STOCK',
  NEW_BUILDING_GREY = 'NEW_BUILDING_GREY',
  NEW_BUILDING_WHITE = 'NEW_BUILDING_WHITE'
}

export enum Orientations {
  BIRD_VIEW = 'BIRD_VIEW',
  ANGLE_VIEW = 'ANGLE_VIEW',
}

export const stylesMap: Record<Styles, string> = {
  [Styles.MODERN_GREY]: modern_grey_3D,
  [Styles.MODERN_WHITE]: modern_white_3D,
  [Styles.NEUTRAL_STOCK]: neutral_stock_3D,
  [Styles.NEW_BUILDING_GREY]: new_building_grey_3D,
  [Styles.NEW_BUILDING_WHITE]: new_building_white_3D,
}

export const styleAngleViewMap: Record<Styles, string> = {
  [Styles.MODERN_GREY]: modern_grey_3D_45_deg,
  [Styles.MODERN_WHITE]: modern_white_3D_45_deg,
  [Styles.NEUTRAL_STOCK]: neutral_stock_3D_45_deg,
  [Styles.NEW_BUILDING_GREY]: new_building_grey_3D_45_deg,
  [Styles.NEW_BUILDING_WHITE]: new_building_white_3D_45_deg,
}

const fixturesMap: Record<Styles, string> = {
  [Styles.MODERN_GREY]: modern_grey_3D,
  [Styles.MODERN_WHITE]: modern_white_3D,
  [Styles.NEUTRAL_STOCK]: neutral_stock_3D,
  [Styles.NEW_BUILDING_GREY]: new_building_grey_3D,
  [Styles.NEW_BUILDING_WHITE]: new_building_white_3D,
}

const furnitureMap: Record<Styles, string> = {
  [Styles.MODERN_GREY]: modern_grey_3D_furniture,
  [Styles.MODERN_WHITE]: modern_white_3D_furniture,
  [Styles.NEUTRAL_STOCK]: neutral_stock_3D_furniture,
  [Styles.NEW_BUILDING_GREY]: new_building_grey_3D_furniture,
  [Styles.NEW_BUILDING_WHITE]: new_building_white_3D_furniture,
}

const fixturesFurnitureMap: Record<Styles, string> = {
  [Styles.MODERN_GREY]: modern_grey_3D,
  [Styles.MODERN_WHITE]: modern_white_3D,
  [Styles.NEUTRAL_STOCK]: neutral_stock_3D,
  [Styles.NEW_BUILDING_GREY]: new_building_grey_3D,
  [Styles.NEW_BUILDING_WHITE]: new_building_white_3D,
}

const logo3D = logo_3D
const roomNames3D = room_names_3D
const basicDimensionsChains = dimensions_chains
const basicDimensionsEachRoom = dimensions_each_room
const basicScalebar = basic_scalebar
const roomArea3D = room_area_3D
const compass3D = compass_3D



/** Gets an array of image URLs based on the selected options. */
export const getPreview = ({
  style,
  orientation,
  showEachRoomDimensions,
  showDimensionChain,
  showScalebar,
  showFurniture,
  showLogo,
  showRoomArea,
  showRoomNames,
  showCompass,
  showFixtures
}: FloorPlan3DOptions) => {

  const images: string[] = []

  // 3D image
  if (showFurniture && !showFixtures) images.push(furnitureMap[style])
  if (showFixtures && !showFurniture) images.push(fixturesMap[style])
  if (showFurniture && showFixtures) images.push(fixturesFurnitureMap[style])
  if (!showFixtures && !showFurniture && orientation === Orientations.BIRD_VIEW) images.push(stylesMap[style])
  if (!showFixtures && !showFurniture && orientation === Orientations.ANGLE_VIEW) images.push(styleAngleViewMap[style])
  if (showEachRoomDimensions) images.push(basicDimensionsEachRoom) //used basic version as placeholder
  if (showDimensionChain) images.push(basicDimensionsChains) // used basic version as placeholder
  if (showScalebar) images.push(basicScalebar) // used basic version as placeholder
  if (showLogo) images.push(logo3D)
  if (showRoomArea) images.push(roomArea3D)
  if (showRoomNames) images.push(roomNames3D)
  if (showCompass) images.push(compass3D)

  return images
}
