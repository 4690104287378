import { FC, useEffect } from 'react'

import { StagingFlow } from 'components/common/StagingFlow/main'
import { StepWrapper } from '../../common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useStyleSelection } from 'components/common/StagingFlow/main/contexts'
import { useTranslation } from 'react-i18next'
import { useUploadedPurchaseFlowVisuals } from '../../_main/contexts'

export const StyleSelectionStepController: FC = () => {
  const { t } = useTranslation(['style_selection_order_step'])

  const { uploadFinished, uploadedImagesArray } = useUploadedPurchaseFlowVisuals()
  const { allRooms } = useStyleSelection()
  const flowCtrl = useDynamicFlowController()

  // Take user a step back if no rooms have been generated, no images uploaded or upload haven't finished yet
  useEffect(() => {
    if (!flowCtrl.currentNode?.prev) return

    if (!uploadFinished) flowCtrl.setHead(flowCtrl.currentNode.prev)
    if (!uploadedImagesArray.length) flowCtrl.setHead(flowCtrl.currentNode.prev)
    if (!allRooms?.length) flowCtrl.setHead(flowCtrl.currentNode.prev)

    // No broad flowCtrl dependency to prevent looping 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRooms, flowCtrl.currentNode?.prev, uploadFinished, uploadedImagesArray.length])

  return (
    <TransitionAppear visible>
      <StepWrapper
        title={t('title')}
        subtitle={t('description')}
      >
        <StagingFlow />
      </StepWrapper>
    </TransitionAppear>
  )
}
