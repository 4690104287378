import { BLUE_100, BLUE_300, BLUE_600, GRAY_900 } from 'constants/styling/theme'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UploadWrapper } from './UploadWrapper.component'

/** @interface Props for the UploadInfoBlock component. */
interface Props {
  /** The description to be displayed in the info block. */
  description: string
}

/** 
 * @component 
 * UploadInfoBlock displays an styled block with important information for the user related to the files.
 * 
 * @example
 * <UploadInfoBlock description="Undefined." />
 */
export const UploadInfoBlock: React.FC<Props> = ({ description }) => {

  return (
    <UploadWrapper borderColor={BLUE_300} backgroundColor={BLUE_100}>
      <Stack direction="row" gap={1}>

        <InfoOutlinedIcon sx={{ fontSize: 20, color: BLUE_600 }} />

        <Stack>
          <Typography variant='text-sm' color={GRAY_900}>
            {description}
          </Typography>
        </Stack>

      </Stack>
    </UploadWrapper>
  )
}
