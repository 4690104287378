import { FC, useCallback, useEffect, useState } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Stack, Typography } from '@mui/material'

import { AnalyticsEvent } from 'utils/analytics'
import { MUIButton } from 'components/common/MUIButton'
import { MUIInputField } from 'components/common/MUIInputField'
import Modal from 'components/common/Modals/Modal/Modal'
import styles from './StagingSpecialRequestPopup.module.sass'
import { useRoomInventory } from 'components/contexts/RoomInventory.context'
import { useStyleSelection } from '../main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * Popup component for handling staging special request.
 * 
 * @example
 * <StagingSpecialRequestPopup />
 */
export const StagingSpecialRequestPopup: FC = () => {
  const { t } = useTranslation(['style_selection_order_step', 'common'])

  const [specialRequest, setSpecialRequest] = useState<string>('')

  const {
    isSpecialRequestPopupOpen,
    setIsSpecialRequestPopupOpen,
    stagingFlowAnalytics,
    activeRoom,
  } = useStyleSelection()

  const { updateRoom } = useRoomInventory()

  const onClosePopup = useCallback(() => {
    setIsSpecialRequestPopupOpen(false)
  }, [setIsSpecialRequestPopupOpen])

  const onConfirmRequest = useCallback(() => {
    stagingFlowAnalytics(AnalyticsEvent.STAGING_SAVE_COMMENT_POPUP)

    updateRoom(activeRoom.key, { comment: specialRequest })
    onClosePopup()
  }, [stagingFlowAnalytics, onClosePopup, updateRoom, activeRoom.key, specialRequest])

  const stagingComment = activeRoom.comment

  useEffect(() => {
    if (isSpecialRequestPopupOpen && stagingComment) {
      setSpecialRequest(stagingComment)
    }
    // Only need to be triggered when popup open.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpecialRequestPopupOpen])

  return (
    <Modal
      isOpen={isSpecialRequestPopupOpen}
      onClickOutside={onClosePopup}
      onClose={onClosePopup}
      title={t('special_request.title')}
      hasHeaderContentDivider
      modalContentClassName={styles.modal}
      afterClosed={() => { setSpecialRequest('') }}
      footerContent={
        <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={2}>

          <MUIButton type="secondaryBorder" onClick={onClosePopup}>
            {t('Cancel')}
          </MUIButton>

          <MUIButton onClick={onConfirmRequest}>
            {t('special_request.send_request')}
          </MUIButton>

        </Stack>
      }
    >

      <Stack gap="2.4rem" justifyContent="space-between">

        <Stack>
          <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900} marginBottom=".6rem">
            {t('special_request.instruction_title')}
          </Typography>

          <Typography variant="text-md" color={GRAY_700}>
            {t('special_request.instruction_content')}
          </Typography>
        </Stack>

        <Stack marginBottom="3rem">
          <MUIInputField
            isMultiline
            value={specialRequest}
            title={t('special_request.instruction')}
            onChange={(e) => setSpecialRequest(e.target.value)}
          />
        </Stack>

      </Stack>

    </Modal>
  )
}
