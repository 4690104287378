import './DealDetailPopup.sass'

import { AssignmentDTOAdministrator, AssignmentDTOClient } from 'models/assignment'
import { BillingMethod, CANCELLATION_DISCOUNT_MULTIPLIER, CancellationReason, DUPLICATE_DEAL_CANCELLATION_ERROR_CODE, DealState, maxCancellationReasonTextCharacters } from 'constants/deal'
import { ColorClass, IconType } from 'constants/assets'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { bigFromFee, formatPrice } from 'utils/price'
import { chooseProductContainingVisuals, isProductWithCT } from 'utils/product'
import { useActionPopup, useStateRef } from 'utils/hooks'
import { useCancelOrder, useEditOrderReference, useSyncOrder } from 'dataQueries/order.query'

import { ActionPopup } from '../Modals/ActionPopup/ActionPopup'
import { AssignmentStage } from 'constants/assignment'
import Big from 'big.js'
import BlockInfo from '../BlockInfo/BlockInfo'
import Button from '../Button/Button'
import { DealDTO } from 'models/deal'
import Icon from '../Icon/Icon'
import Modal from '../Modals/Modal/Modal'
import { QueryStatus } from '../QueryStatus'
import TransitionAppear from '../TransitionAppear/TransitionAppear'
import TriangleIcon from '../TriangleIcon/TriangleIcon'
import { getTermsLink } from 'utils/localization'
import i18n from 'translations/i18n'
import { isEditingCategory } from 'utils/validators'
import moment from 'moment-timezone'
import { multilineText } from 'utils/formatting'
import { standardEnterEffect } from 'utils/animations'
import { useAuth0 } from 'utils/auth'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Decides when is modal open */
  isOpen: boolean
  /** The data object */
  data: DealDTO
  /** Onclick action triggered when user clicks outside the modal content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button*/
  onClickClose?: (e: React.MouseEvent) => unknown
}

const stagesLockingClientSeeCreative = new Set([
  AssignmentStage.MISSION_ORDER_PLACED,
  AssignmentStage.PRE_PRODUCTION,
  AssignmentStage.AUTOMATED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
])

export const stagesUnlockingOrderCancellation = new Set([
  AssignmentStage.MISSION_ORDER_PLACED,
  AssignmentStage.PRE_PRODUCTION,
  AssignmentStage.AUTOMATED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
  AssignmentStage.WAITING_FOR_DATE_AND_TIME,
  AssignmentStage.CREATIVE_BOOKED,
])

/**
 * @component Display deal detail in a popup
 * @example
 */
export const DealDetailPopup: React.FC<Props> = ({
  className = '',
  isOpen,
  data,
  onClickOutside,
}) => {
  const { t } = useTranslation(['deal_detail_popup', 'deal_assignment', 'time_translations', 'category', 'product', 'instruction_option_field', 'responses', 'cancellation_reasons'])
  const { roles } = useAuth0()
  const { showConfirm } = useActionPopup()

  // MUTATIONS
  const syncOrder = useSyncOrder()
  const editOrderReference = useEditOrderReference()
  const cancelOrder = useCancelOrder()

  const initReference = useMemo(() => data.reference || '', [data])

  const [isReferenceEditOpen, setIsReferenceEditOpen] = useState(false)
  const [reference, setReference] = useState(initReference)

  const maxReferenceCharacters = 50
  const isMaxReferenceCharacters = useMemo(() => reference.length === maxReferenceCharacters, [reference])

  const [dynamicNow, setDynamicNow] = useState(moment.utc())

  const [cancellationReason, setCancellationReason] = useState<CancellationReason | undefined>(undefined)
  const cancellationReasonRef = useStateRef(cancellationReason) // useStateRef used because cancellation is async in useCallback
  const [cancellationReasonText, setCancellationReasonText] = useState<string | ''>('')
  const cancellationReasonTextRef = useStateRef(cancellationReasonText)  // useStateRef used because cancellation is async in useCallback

  const earliestAssignment = useMemo(() => data
    && data.missions?.length ? data.missions.sort((a, b) => moment(a.shootingStartDateTime).diff(moment(b.shootingStartDateTime)))?.[0] : null, [data])

  const shootingStart = useMemo(() => earliestAssignment
    && earliestAssignment.timezone
    && earliestAssignment.shootingStartDateTime ? moment(earliestAssignment.shootingStartDateTime).tz(earliestAssignment.timezone).toISOString() : null, [earliestAssignment])

  /**
  * If data is of type deal, dealStart and timezone are set, calculate whether shooting starts within 24 hours.
  * Default to false otherwise.
  */
  const isLateCancellation = useMemo(() => data
    && !!shootingStart
    && !!earliestAssignment?.timezone
    && moment(shootingStart).isSameOrBefore(moment(dynamicNow).add(1, 'day')), [shootingStart, data, dynamicNow, earliestAssignment])

  /**
   * Only show the cancel order button when:
   * user is admin AND
   * order is not cancelled AND
   * order has a non-editing category AND
   * order has no assignments past stage 4
  */
  const showCancelButton = useMemo(() => {
    if (!data) return false
    if (data.state === DealState.CANCELLED) return false
    if (cancelOrder.data?.data.response?.status === DUPLICATE_DEAL_CANCELLATION_ERROR_CODE) return false
    if (cancelOrder.isSuccess) return false
    if (roles.isCreative || roles.isClient) return false
    if (isEditingCategory(data.productCategory)) return false
    if (data.missions.some((assignment) => !stagesUnlockingOrderCancellation.has(assignment.stage))) return false

    return true
  }, [cancelOrder.data?.data.response?.status, cancelOrder.isSuccess, data, roles.isClient, roles.isCreative])

  /**
   * If data is of type deal and shooting starts within 24 hours sum price of all standard products which have CT discounted by cancellation discount and organization discount.
   * Default to 0 otherwise.
   */
  const cancelDealFee = useMemo(() => data
    && isLateCancellation
    ? (data.financials.products.reduce((total, prod) => isProductWithCT(prod) ? total.plus(bigFromFee(prod.unitPrice)) : total, new Big(0))).times(CANCELLATION_DISCOUNT_MULTIPLIER)
    : new Big(0), [data, isLateCancellation])

  const isMaxCancellationReasonTextCharacters = useMemo(() => cancellationReasonText.length === maxCancellationReasonTextCharacters, [cancellationReasonText])

  const cancellationConfirmBody = useMemo(() => (
    <Fragment>
      {isLateCancellation ?
        <Trans
          t={t}
          i18nKey="cancel_deal_starting_within_24_hours"
          values={{ order: data.id, fee: formatPrice(cancelDealFee, data.feePrice.currency), context: data.billingMethod !== BillingMethod.INVOICE ? 'stripe' : '' }}
        />
        :
        <Trans
          t={t}
          i18nKey="cancel_deal_starting_later_than_24_hours"
          values={{ order: data.id }}
        />
      }
      <div className="reasons-wrap">
        <div className="reasons-header">{t('cancellation_reasons:title')}</div>
        {Object.values(CancellationReason).map(reason => {
          return (
            <div className="reason" key={reason}>
              <label className="checkbox" htmlFor={`cancellation-reason-${reason}`}>
                <input
                  type="radio"
                  name="cancellation-reason"
                  id={`cancellation-reason-${reason}`}
                  value={reason}
                  onClick={_ => setCancellationReason(reason)}
                />
                <span className="checkmark"></span>
                <span className="label-after">{t(`cancellation_reasons:${reason}`)}</span>
              </label>
            </div>
          )
        })}
        <TransitionAppear visible={cancellationReason === CancellationReason.OTHER} onEnter={standardEnterEffect}>
          <div className="input-group">
            <label htmlFor="cancellation-reason-text">{t('cancellation_reasons:text')}</label>
            <input
              type="text"
              name="cancellation-reason-text"
              id="cancellation-reason-text"
              value={cancellationReasonText}
              onChange={e => setCancellationReasonText(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxCancellationReasonTextCharacters))}
            />
          </div>
        </TransitionAppear>
        {!!isMaxCancellationReasonTextCharacters &&
          <span className="error-message">{t('cancellation_reason_characters_length_message', { count: maxCancellationReasonTextCharacters })}</span>
        }
      </div>
      <span>{t('cancel_deal_confirm')}</span>
    </Fragment>
  ), [t, cancelDealFee, cancellationReason, cancellationReasonText, data, isMaxCancellationReasonTextCharacters, isLateCancellation])

  const handleCancelClick = useCallback(async () => {
    const confirmPromise = showConfirm(null, { disableGlobalActionPopup: true, confirmDisabledText: t('cancel_deal_select_reason_message') })
    if (await confirmPromise && cancellationReasonRef.current) {
      const reason = cancellationReasonTextRef.current ? cancellationReasonTextRef.current : cancellationReasonRef.current
      cancelOrder.mutate({ orderId: data.id, cancellationReason: reason })
    }
    // Always reset cancellation reason on confirmation popup closure
    setCancellationReason(undefined)
    setCancellationReasonText('')
  }, [showConfirm, t, cancellationReasonRef, cancellationReasonTextRef, cancelOrder, data.id])

  // Updates dynamicNow every 30 seconds
  useEffect(() => {
    let intervalId: number

    if (isOpen) {
      intervalId = window.setInterval(
        () => setDynamicNow(moment.utc()),
        1000 * 30
      )
    }

    return () => {
      if (!!intervalId) clearInterval(intervalId)
    }
  }, [isOpen])

  const editBoxReference = (
    <TransitionAppear visible={isReferenceEditOpen}>
      <div className="edit-box edit-reference margin-bottom">

        <div className="input-row">

          <div className="input reference-group">
            <input
              type="text"
              name={`reference-input-${data.id}`}
              id={`reference-input-${data.id}`}
              className="reference"
              placeholder={t('add_reference_hint')}
              value={reference}
              onChange={e => setReference(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxReferenceCharacters))}
            />
          </div>

          <div className="input">
            <Button
              type="primary"
              onClick={_ => editOrderReference.mutate({ orderId: data.id, reference })}
              disabled={editOrderReference.isPending}
            >
              {t('submit')}
            </Button>
          </div>

        </div>

        {!!isMaxReferenceCharacters &&
          <span className="error-message">{t('reference_characters_length_message', { count: maxReferenceCharacters })}</span>
        }

        <QueryStatus
          query={editOrderReference}
          successMessage={t('success')}
          spaceTopRem={2}
          onPurge={() => editOrderReference.reset()}
        />

      </div>
    </TransitionAppear>
  )

  return (
    <Modal
      className={`deal-detail-popup ${className}`}
      modalContentClassName="deal-detail-popup-modal-content"
      isOpen={isOpen}
      onClickOutside={onClickOutside}
    >
      <div className="inside">

        <div className="heading">

          <h3 className="title">
            {data.isDeleted &&
              <Fragment>
                <strong className="red-text">[{t('deal_assignment:deleted').toUpperCase()}]</strong>
                {' - '}
              </Fragment>
            }
            {data.state === DealState.CANCELLED &&
              <Fragment>
                <strong className="red-text">[{t('deal_assignment:cancelled').toUpperCase()}]</strong>
                {' - '}
              </Fragment>
            }
            <strong>{t(`category:${data.productCategory}`)}</strong>
            {' - '}
            {data.missions.filter(assignment => !assignment.isDeleted).map(assignment => assignment.products.map(product => t(`product:p_${product.id}`)).join(', ')).join(', ')}
          </h3>

          <span className="number">#{data.id}</span>

        </div>

        {!!data.reference ?
          <div className="line margin-bottom">
            <h3 className="reference">{data.reference}</h3>
            {roles.isAdmin &&
              <Button
                type="secondary"
                className="reference-button margin-left"
                onClick={_ => setIsReferenceEditOpen(val => !val)}
              >
                {isReferenceEditOpen ? t('close_edit') : t('edit')}
              </Button>
            }
          </div>
          :
          <Fragment>
            {roles.isAdmin &&
              <div className="line margin-bottom">
                <Button
                  type="secondary"
                  className="reference-button"
                  onClick={_ => setIsReferenceEditOpen(val => !val)}
                >
                  {isReferenceEditOpen ? t('close_add_reference') : t('add_reference')}
                </Button>
              </div>
            }
          </Fragment>
        }

        {roles.isAdmin &&
          editBoxReference
        }

        {!!data.address &&
          <div className="line">
            <TriangleIcon icon={IconType.MAP} type={ColorClass.PRIMARY_BLUE} />
            <span>{data.address || 'N/A'}</span>
          </div>
        }

        {data.missions.map((assignment: AssignmentDTOClient | AssignmentDTOAdministrator) => {
          const mainProduct = chooseProductContainingVisuals(assignment.products)
          if (!assignment.creative) return <Fragment key={assignment.id}></Fragment>
          if (!roles.isAdmin && assignment.isDeleted) return <Fragment key={assignment.id}></Fragment>

          return (
            <Fragment key={assignment.id}>
              <div className="midline"></div>
              <div className="line">
                <strong className="caption">{mainProduct ? t(`product:p_${mainProduct.id}`) : 'N/A'}</strong>
              </div>
              {assignment.creative && assignment.creative.name && (!roles.isClient || !stagesLockingClientSeeCreative.has(assignment.stage)) &&
                <div className="line">
                  <TriangleIcon icon={IconType.CAMERA} type={ColorClass.PRIMARY_BLUE} />
                  <span>{assignment.creative.name || 'N/A'}</span>
                  {assignment.creative.phone &&
                    <a href={`tel:${assignment.creative.phone}`} className="block">
                      <Icon className="small" icon={IconType.PHONE} />
                      <span>{assignment.creative.phone}</span>
                    </a>
                  }
                  {assignment.creative.email &&
                    <a href={`mailto:${assignment.creative.email}`} className="block">
                      <Icon className="small" icon={IconType.ENVELOPE} />
                      <span>{assignment.creative.email}</span>
                    </a>
                  }
                </div>
              }
            </Fragment>
          )
        })}

        {data.contactPerson && data.contactPerson.name &&
          <Fragment>
            <div className="midline"></div>
            <div className="line">
              <strong className="caption">
                {t('deal_assignment:contact_on_site')}
              </strong>
            </div>
            <div className="line">
              <TriangleIcon icon={IconType.PROFILE} type={ColorClass.PRIMARY_BLUE} />
              <span>{data.contactPerson.name || 'N/A'}</span>
              {data.contactPerson.phone &&
                <a href={`tel:${data.contactPerson.phone}`} className="block">
                  <Icon className="small" icon={IconType.PHONE} />
                  <span>{data.contactPerson.phone}</span>
                </a>
              }
              {data.contactPerson.email &&
                <a href={`mailto:${data.contactPerson.email}`} className="block">
                  <Icon className="small" icon={IconType.ENVELOPE} />
                  <span>{data.contactPerson.email}</span>
                </a>
              }
            </div>
          </Fragment>
        }

        <div className="midline"></div>

        <div className="line">
          <strong className="caption">
            {t('deal_assignment:meeting_instructions')}
          </strong>
        </div>

        <BlockInfo color="blue">
          {multilineText(data.meetingInstructions || 'N/A')}
        </BlockInfo>

        <div className="midline"></div>

        <div className="line">
          <strong className="caption">
            {t('deal_assignment:shooting_instructions')}
          </strong>
        </div>

        <BlockInfo color="blue">
          {multilineText(data.shootingInstructions || 'N/A')}
        </BlockInfo>

        {roles.isAdmin &&
          <Fragment>
            <div className="midline"></div>

            <div className="sync-wrap">

              <Button
                type="secondary green"
                disabled={syncOrder.isPending}
                onClick={() => syncOrder.mutate({ orderId: data.id })}
                textAndIcon={true}
              >
                <Icon icon={IconType.REFRESH} />
                <span>{t('deal_assignment:sync_deal')}</span>
              </Button>

              <QueryStatus
                query={syncOrder}
                successMessage={t('deal_assignment:deal_synchronized')}
                spaceTopRem={2}
                onPurge={() => syncOrder.reset()}
              />

            </div>
          </Fragment>
        }

        {showCancelButton &&
          <Fragment>
            <div className="cancel-wrap">
              <div className="button-with-text">
                {showCancelButton &&
                  <Fragment>
                    <Button
                      type="secondary red"
                      textAndIcon={true}
                      onClick={handleCancelClick}
                      disabled={cancelOrder.isPending}
                    >
                      <Icon icon={IconType.CROSS} />
                      {isLateCancellation
                        ? <span>{t('cancel_fee', { fee: formatPrice(cancelDealFee, data.feePrice.currency) })}</span>
                        : <span>{t('cancel')}</span>
                      }
                    </Button>
                    <span className="terms">
                      <Trans t={t} i18nKey="deal_detail_popup:cancel_order_tos">
                        <a href={getTermsLink(i18n.language)} rel="noopener noreferrer" target="_blank">&nbsp;</a>
                      </Trans>
                    </span>
                  </Fragment>
                }

              </div>
            </div>
          </Fragment>
        }

        <QueryStatus
          query={cancelOrder}
          onPurge={() => cancelOrder.reset()}
          successMessage={t('deal_detail_popup:cancel_success')}
          spaceTopRem={2}
          errorMessage={cancelOrder.data?.data.response?.status === DUPLICATE_DEAL_CANCELLATION_ERROR_CODE
            ? t('already_cancelled')
            : undefined
          }
        />

      </div>

      <ActionPopup
        isConfirmDisabled={!cancellationReason || (cancellationReason === CancellationReason.OTHER && !cancellationReasonText)}
      >
        {cancellationConfirmBody}
      </ActionPopup>

    </Modal>
  )
}
