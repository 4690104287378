import React, { ReactNode } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'

/** @interface Props for the UploadWrapper component. */
interface Props {
  /** The content to be wrapped. */
  children: ReactNode
  /** The color of the border. */
  borderColor?: string
  /** The background color of the wrapper. */
  backgroundColor?: string
}

/**
 * @component
 * UploadWrapper component designed for wrapping uploaded content with specific and optional styling.
 *
 * @example
 * <UploadWrapper collapseIn={true} backgroundColor="#FFF" borderColor="#DDD">
 *   <YourContent />
 * </UploadWrapper>
 */
export const UploadWrapper: React.FC<Props> = ({ children, backgroundColor, borderColor }) => {
  return (
    <BorderBoxWrapper
      sx={{
        gap: 1,
        padding: 1,
        marginTop: 1,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
      }}
      elevation='none'
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      {children}
    </BorderBoxWrapper>
  )
}
