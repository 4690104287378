import { QueryClient, useInfiniteQuery, keepPreviousData as useKeepPreviousData, useQuery } from '@tanstack/react-query'
import { OrderAssignmentStatusFilter, OwnershipScope, SortBy, SortDirection } from 'constants/misc'
import { ISODateString, PageableResponse } from 'models/misc'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { AxiosResponse } from 'axios'
import { DealDTO } from 'models/deal'
import { Nullable } from 'models/helpers'
import { StatusResponse } from 'models/redux'
import { useMemo } from 'react'
import { useAPI } from 'utils/API'

enum Endpoints {
  GET = '/deal/{orderId}',
  EDIT_REFERENCE = '/deal/{orderId}/reference',
  CANCEL = '/deal/{orderId}/cancel',
  LIST = '/deal/all',
  SYNC = '/deal/{orderId}/sync',
  UPDATE_START_DATE_TIME = '/deal/{orderId}/startDateTime',
}

// QUERIES

export function useGetOrder(orderId: number, enabled?: boolean, keepPreviousData = false) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId }],
    queryFn: () => api.get<DealDTO>(
      Endpoints.GET,
      { orderId: orderId.toString() },
      true
    ),
    enabled: enabled ?? !!orderId,
    placeholderData: keepPreviousData ? useKeepPreviousData : undefined
  })
}

// LIST QUERIES

export interface OrderListFilters {
  status: OrderAssignmentStatusFilter
  scope?: OwnershipScope
  userIds: string[]
  sortDirection?: SortDirection
  creationDateStart?: Nullable<moment.Moment>,
  creationDateEnd?: Nullable<moment.Moment>,
  shootingDateStart?: Nullable<moment.Moment>,
  shootingDateEnd?: Nullable<moment.Moment>,
  sortBy?: SortBy
}

interface OrderListPayload extends OrderListFilters {
  page: number
  size: number
  key: string
  search: Nullable<string>,
}

const defaultParams: OrderListPayload = {
  page: 0,
  size: 10,
  key: 'KEY',
  sortDirection: SortDirection.DESCENDING,
  status: OrderAssignmentStatusFilter.ALL,
  userIds: [],
  search: null,
  creationDateStart: null,
  creationDateEnd: null,
  shootingDateStart: null,
  shootingDateEnd: null,
  sortBy: SortBy.DEFAULT,
}

const _serializeParams = (params: OrderListFilters & Partial<OrderListPayload>) => {

  const finalParams = {
    ...defaultParams,
    ...params,
  }

  return {
    page: (finalParams.page || 0).toString(),
    size: finalParams.size.toString(),
    sortDirection: finalParams.sortDirection?.toString() ?? SortDirection.DESCENDING,
    filter: finalParams.status.toString(),
    displayScope: finalParams.scope ? finalParams.scope.toString() : undefined,
    userIds: finalParams.scope !== OwnershipScope.INDIVIDUAL && finalParams.userIds.length > 0 ? finalParams.userIds.toString() : undefined,
    query: finalParams.search || undefined,
    creationDateStart: finalParams.creationDateStart?.toISOString(),
    creationDateEnd: finalParams.creationDateEnd?.toISOString(),
    shootingDateStart: finalParams.shootingDateStart?.toISOString(),
    shootingDateEnd: finalParams.shootingDateEnd?.toISOString(),
    sortBy: finalParams.sortBy?.toString() ?? SortBy.DEFAULT,
  }
}

export const useListOrders = (listKey: string, params: OrderListFilters & Partial<OrderListPayload>) => {
  const api = useAPI<Endpoints>()

  const serializedParams = useMemo(() => _serializeParams(params), [params])

  return useQuery({
    queryKey: [EntityKeys.ORDER, QueryType.LIST, listKey, { ...serializedParams }],
    queryFn: () => api.get<PageableResponse<DealDTO[]>>(
      Endpoints.LIST,
      {},
      true,
      {
        params: serializedParams,
      }
    )
  })
}

export const useInfiniteListOrders = (listKey: string, params: OrderListFilters) => {
  const api = useAPI<Endpoints>()

  const serializedParams = useMemo(() => _serializeParams(params), [params])

  const fetchFnc = (page: number) => api.get<PageableResponse<DealDTO[]>>(
    Endpoints.LIST,
    {},
    true,
    {
      params: {
        ...serializedParams,
        page,
      }
    }
  )

  return useInfiniteQuery({
    queryKey: [EntityKeys.ORDER, QueryType.INFINITE_LIST, listKey, { ...serializedParams }],
    queryFn: ({ pageParam }) => fetchFnc(pageParam),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => !lastPage.data.last ? lastPage.data.pageable.pageNumber + 1 : undefined,
  })
}


// MUTATIONS
const _invalidateOrder = (client: QueryClient, orderId?: number) => {
  client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.LIST] })
  client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.INFINITE_LIST] })
  if (orderId) client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId }] })
}

export function useSyncOrder() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<DealDTO>, { orderId: number }>(
    ({ orderId }) => api.post(
      Endpoints.SYNC,
      { orderId: orderId.toString() },
      {},
      true
    ),
    (client, { orderId }) => _invalidateOrder(client, orderId)
  )
}

export function useAdminSyncOrder() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<DealDTO>, { orderId: number }>(
    ({ orderId }) => api.post(
      Endpoints.SYNC,
      { orderId: orderId.toString() },
      {},
      true
    ),
    (client, { orderId }) => _invalidateOrder(client, orderId)
  )
}

interface EditOrderReferencePayload {
  orderId: number
  reference: string
}

export function useEditOrderReference() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, EditOrderReferencePayload>(
    ({ orderId, reference }) => api.put(
      Endpoints.EDIT_REFERENCE,
      { orderId: orderId.toString() },
      { reference },
      true
    ),
    (client, { orderId }) => _invalidateOrder(client, orderId)
  )
}

interface CancelOrderPayload {
  orderId: number
  cancellationReason: string
}

export function useCancelOrder() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, CancelOrderPayload>(
    ({ orderId, cancellationReason }) => api.put(
      Endpoints.CANCEL,
      { orderId: orderId.toString() },
      { cancellationReason },
      true
    ),
    (client, { orderId }) => _invalidateOrder(client, orderId)
  )
}

interface UpdateStartDateTime {
  orderId: number
  startDateTime: ISODateString
}

export const useUpdateStartDateTime = () => {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, UpdateStartDateTime>(
    ({ orderId, startDateTime }) => api.put(
      Endpoints.UPDATE_START_DATE_TIME,
      { orderId: orderId.toString() },
      { value: startDateTime },
      true
    ),
    (client, { orderId }) => _invalidateOrder(client, orderId)
  )
}
