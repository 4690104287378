import { Orientations, Styles } from '../FloorPlanPreview/floorPlan3DConstants'
import { ProductKindsRequiringFloorPlanConfig, ProductTypesRequiringFloorPlanConfig } from 'constants/product'
import { useCallback, useMemo, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { FeatureFlag } from 'utils/featureFlags'
import { FloorPlanColors } from '../FloorPlanPreview/basicFloorPlanConstants'
import { FloorPlanFormat } from '../FloorPlanLayout'
import { GoogleAPIPlace } from 'models/purchaseFlow'
import { Language } from 'translations/Language'
import { Nullable } from 'models/helpers'
import constate from 'constate'
import { isEditingCategory } from 'utils/validators'
import { useFlag } from '@unleash/proxy-client-react'

export enum FloorPlanRenderType {
  RENDER_3D = 'RENDER_3D',
  RENDER_2D = 'RENDER_2D'
}

export enum FloorPlanProductType {
  PRODUCT_EDITING = 'EDITING',
  PRODUCT_BASIC = 'BASIC'
}

export enum FloorPlanSelectionType {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM'
}

export const [FloorPlanConfigProvider, useFloorPlanConfig] = constate(() => {
  const { selectedCategory } = usePurchaseFlowConfig()
  const { targetUser } = useTargetOrderUser()
  const { selectedProducts, hasFloorPlanProduct } = usePurchaseFlowProducts()

  const allowFloorPlanFlow = useFlag(FeatureFlag.ALLOW_FLOOR_PLAN_CONFIG_FLOW)

  const [currentFloorPlanRenderType, setCurrentFloorPlanRenderType] = useState<FloorPlanRenderType>(FloorPlanRenderType.RENDER_2D)

  const currentFloorPlanProductType: FloorPlanProductType = useMemo(() => {
    if (selectedCategory && isEditingCategory(selectedCategory)) return FloorPlanProductType.PRODUCT_EDITING
    return FloorPlanProductType.PRODUCT_BASIC
  }, [selectedCategory])

  const floorPlanProduct = useMemo(() => hasFloorPlanProduct ? Object.values(selectedProducts).find(product => ProductKindsRequiringFloorPlanConfig.has(product.kind) || ProductTypesRequiringFloorPlanConfig.has(product.type)) : undefined, [hasFloorPlanProduct, selectedProducts])

  // DESIGN
  const [selectedColor, setSelectedColor] = useState<FloorPlanColors>(FloorPlanColors.BLACK)
  const [showFixtures, setShowFixtures] = useState<boolean>(false)
  const [showFurniture, setShowFurniture] = useState<boolean>(false)

  const [selectedStyle, setSelectedStyle] = useState<Styles>(Styles.MODERN_GREY)
  const [selectedOrientation, setSelectedOrientation] = useState<Orientations>(Orientations.BIRD_VIEW)

  // LAYOUT
  const [showRoomNames, setShowRoomNames] = useState<boolean>(false)
  const [showScalebar, setShowScalebar] = useState<boolean>(false)
  const [showCompass, setShowCompass] = useState<boolean>(false)
  const [isActiveDimensions, setIsActiveDimensions] = useState<boolean>(false)
  const [showEachRoomDimensions, setShowEachRoomDimensions] = useState<boolean>(true)
  const [showDimensionChain, setShowDimensionChain] = useState<boolean>(true)
  const [showRoomArea, setShowRoomArea] = useState<boolean>(true)
  const [outputLanguage, setOutputLanguage] = useState<Language>(Language.EN)
  const [floorPlanFormat, setFloorPlanFormat] = useState<Record<string, FloorPlanFormat>>({ [FloorPlanFormat.JPG]: FloorPlanFormat.JPG })
  const [isActiveLogo, setIsActiveLogo] = useState<boolean>(false)
  const [companyLogo, setCompanyLogo] = useState<Nullable<string>>(null)
  const [disclaimer, setDisclaimer] = useState<string>('')
  const [compassAddress, setCompassAddress] = useState<GoogleAPIPlace | null>(null)

  // TEMPLATES
  const [selectedTemplateId, setSelectedTemplateId] = useState<Nullable<string>>(null)
  const [floorPlanTemplateType, setFloorPlanTemplateType] = useState<Nullable<FloorPlanSelectionType>>(null)

  // DATA
  const getFloorPlanRequestData = useCallback(() => {
    if (!allowFloorPlanFlow) return undefined

    // TODO: Once we will finalize 3D render type, we should have is3DRenderType as memo, so we can check it everywhere in code same way
    const is3DRenderType = currentFloorPlanProductType === FloorPlanProductType.PRODUCT_EDITING && currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D

    return {
      // Has to be updated when 3D option is available for the new Floor Plan configuration design
      style: is3DRenderType ? selectedStyle : selectedColor,
      orientation: is3DRenderType ? selectedOrientation : undefined,
      fixtures: showFixtures,
      furniture: showFurniture,
      roomNames: showRoomNames,
      scaleBar: showScalebar,
      compass: showCompass,
      companyLogo,
      outputFileFormat: Object.keys(floorPlanFormat),
      outputFileLanguage: outputLanguage,
      dimensions: {
        showEachRoomDimensions,
        showDimensionChain,
        showEachRoomArea: showRoomArea
      },
      disclaimer,
      compassAddress: showCompass && compassAddress ? compassAddress.formatted_address : '',
    }
  }, [allowFloorPlanFlow, companyLogo, currentFloorPlanProductType, currentFloorPlanRenderType,
    floorPlanFormat, outputLanguage, selectedColor, selectedOrientation, selectedStyle, showCompass,
    showDimensionChain, showEachRoomDimensions, showFixtures, compassAddress, disclaimer, showFurniture, showRoomArea, showRoomNames, showScalebar]
  )

  const resetConfiguration = useCallback(() => {
    setSelectedTemplateId(null)
    setSelectedColor(FloorPlanColors.BLACK)
    setShowFixtures(false)
    setShowFurniture(false)
    setSelectedStyle(Styles.MODERN_GREY)
    setSelectedOrientation(Orientations.BIRD_VIEW)
    setShowRoomNames(false)
    setShowScalebar(false)
    setShowCompass(false)
    setIsActiveDimensions(false)
    setShowEachRoomDimensions(false)
    setShowDimensionChain(false)
    setShowRoomArea(false)
    setOutputLanguage(Language[(targetUser.language as Language)] ?? Language.EN)
    setFloorPlanFormat({ [FloorPlanFormat.JPG]: FloorPlanFormat.JPG })
    setIsActiveLogo(false)
    setCompanyLogo(null)
    setDisclaimer('')
  }, [targetUser.language])

  return {
    currentFloorPlanProductType,
    currentFloorPlanRenderType,
    floorPlanProduct,
    selectedColor,
    selectedStyle,
    selectedOrientation,
    showFixtures,
    showFurniture,
    showRoomNames,
    showScalebar,
    showCompass,
    isActiveDimensions,
    isActiveLogo,
    companyLogo,
    showEachRoomDimensions,
    showDimensionChain,
    showRoomArea,
    outputLanguage,
    floorPlanFormat,
    floorPlanTemplateType,
    selectedTemplateId,
    disclaimer,
    compassAddress,
    setSelectedTemplateId,
    setFloorPlanTemplateType,
    setCurrentFloorPlanRenderType,
    setSelectedColor,
    setSelectedStyle,
    setSelectedOrientation,
    setShowFixtures,
    setShowFurniture,
    setShowRoomNames,
    setShowScalebar,
    setShowCompass,
    setOutputLanguage,
    setFloorPlanFormat,
    setIsActiveDimensions,
    setIsActiveLogo,
    setCompanyLogo,
    setShowEachRoomDimensions,
    setShowDimensionChain,
    setShowRoomArea,
    getFloorPlanRequestData,
    resetConfiguration,
    setDisclaimer,
    setCompassAddress
  }
})
