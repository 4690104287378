import { FloorPlanTemplateDetailsDTO, FloorPlanTemplatesDTO } from 'models/purchaseFlow'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { SignedURLDTO } from 'models/visuals'
import { useAPI } from 'utils/API'

enum Endpoints {
  GET_FLOOR_PLAN_TEMPLATES = '/workspace/floorPlanTemplates',
  GET_FLOOR_PLAN_TEMPLATE_DETAILS = '/workspace/floorPlanTemplate/{templateId}',
  GET_FLOOR_PLAN_TEMPLATE_LOGO = '/workspace/{workspaceId}/assets/floorPlanLogo/{logoId}',
}

// QUERIES

export function useGetFloorPlanTemplates() {
  const api = useAPI<Endpoints>()

  return useQuery<FloorPlanTemplatesDTO[], Error>({
    queryKey: [EntityKeys.FLOOR_PLAN_TEMPLATES, QueryType.LIST],
    queryFn: () => api.get<FloorPlanTemplatesDTO[]>(
      Endpoints.GET_FLOOR_PLAN_TEMPLATES,
      {},
      false
    )
  })
}

// MUTATIONS

export function useGetFloorPlanTemplateDetails() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<FloorPlanTemplateDetailsDTO>, { templateId: string }>(
    ({ templateId }) => api.get(
      Endpoints.GET_FLOOR_PLAN_TEMPLATE_DETAILS,
      { templateId },
      true
    )
  )
}

export function useGetFloorPlanTemplateLogo() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<SignedURLDTO>, { logoId: string, workspaceId: string }>(
    ({ logoId, workspaceId }) => api.get(
      Endpoints.GET_FLOOR_PLAN_TEMPLATE_LOGO,
      { logoId, workspaceId },
      true
    )
  )
}
