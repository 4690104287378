import { AddressWithCoordinates, GoogleAPIPlace, Place } from 'models/purchaseFlow'

import { Nullable } from 'models/helpers'

/** creates the redux store place object from google place object */
export function createPlaceFromGooglePlace(googlePlace: GoogleAPIPlace | null): Place | undefined {
  if (!googlePlace) return undefined

  const place: Place = {
    address: googlePlace.formatted_address,
    coordinate: {
      lat: googlePlace.geometry.location.lat,
      lng: googlePlace.geometry.location.lng,
    },
    countryCode: getCountryCodeFromGooglePlace(googlePlace)
  }

  return place
}

/** creates the country code string from google place object */
export function getCountryCodeFromGooglePlace(googlePlace: GoogleAPIPlace | null): string {
  if (!googlePlace) return ''

  for (let component of googlePlace.address_components) {
    for (let type of component.types) {
      if (type === 'country') return component.short_name
    }
  }

  return ''
}

/** Transforms Place object into AddressWithCoordinates */
export function getAddressWithCoordinatesFromPlace(place: Nullable<Place>): AddressWithCoordinates | undefined {
  if (!place) return undefined

  return {
    address: place.address,
    coordinates: place.coordinate,
  }
}
