import { DynamicSummary, SummaryTextBlock } from '../Summary'
import { EditingProductListing, ProductListing } from 'components/pages/PurchaseFlow/common'
import { isEditingCategory, isShootingCategory } from 'utils/validators'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useProductAndInstructionLists, usePurchaseFlowConfig, usePurchaseFlowOrderMeta, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'
import { usePurchaseFlowPaymentStatus, usePurchaseFlowPlaceOrder } from './contexts'

import { EditingCategory } from 'constants/product'
import { KeyboardEventKey } from 'constants/misc'
import { Logistics } from '../Logistics'
import { PurchaseFlowPayment } from '../PurchaseFlowPayment'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { getCityFromGooglePlace } from 'utils/location'
import { logAnalyticsEvent } from 'utils/analytics'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const ValidationController: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['order', 'category', 'product', 'product_kind'])
  const { orderIsBeingPlaced, orderPlacementHasError } = usePurchaseFlowPlaceOrder()
  const { getProductQuantityList, getInstructionQuantityList } = useProductAndInstructionLists()
  const { isStripeProcessing, setIsStripeProcessing } = usePurchaseFlowPaymentStatus()
  const { orderHasBeenPlaced } = usePurchaseFlowPlaceOrder()
  const {
    selectedCategory,
    selectedAssignmentPlace,
    selectedCountryCode,
    externalReportingInstruction,
  } = usePurchaseFlowConfig()
  const {
    selectedKinds,
    selectedFilterTypes,
    selectedSegment,
  } = usePurchaseFlowProducts()
  const {
    VATNumber,
    shootingComments,
    selectedPaymentMethod,
    isStripePaymentMethod,
    externalReportingField,
    vatInputRequired,
  } = usePurchaseFlowOrderMeta()

  const productComments = useMemo(() => {
    if (!selectedCategory) return null
    if (isShootingCategory(selectedCategory) && shootingComments) return shootingComments
    return null
  }, [selectedCategory, shootingComments])

  const cleanupTimeoutRef = useRef<number | undefined>(undefined)
  const logRef = useRef(false)

  const orderOrStripeIsInProgress = useMemo(() =>
    isStripePaymentMethod ? (orderIsBeingPlaced || isStripeProcessing) : orderIsBeingPlaced,
    [isStripeProcessing, isStripePaymentMethod, orderIsBeingPlaced]
  )

  // Stop stripe loading when order placement fails
  useEffect(() => {
    if (orderPlacementHasError && isStripeProcessing) setIsStripeProcessing(false)
  }, [isStripeProcessing, orderPlacementHasError, setIsStripeProcessing])

  // Analytics on entering this page
  useEffect(() => {
    if (logRef.current) return
    logRef.current = true
    logAnalyticsEvent('enters_order_validation_screen', {
      category: selectedCategory,
      countryCode: selectedCountryCode,
      productSegment: selectedSegment,
      productKinds: selectedKinds,
      productTypes: selectedFilterTypes,
      products: [...getProductQuantityList(), ...getInstructionQuantityList()],
      city: getCityFromGooglePlace(selectedAssignmentPlace)
    })
  }, [selectedCategory, selectedAssignmentPlace, selectedKinds, selectedCountryCode, selectedFilterTypes, selectedSegment, getProductQuantityList, getInstructionQuantityList])

  const disableTab = useCallback((e: KeyboardEvent) => {
    if (e.key === KeyboardEventKey.TAB) {
      e.preventDefault()
      e.stopPropagation()
    }
  }, [])

  // Disable TAB key when placing order to prevent user from focusing and changing stripe input fields
  useEffect(() => {
    if (orderOrStripeIsInProgress) {
      window.addEventListener('keydown', disableTab)
    }

    return () => {
      window.removeEventListener('keydown', disableTab)
    }
  }, [disableTab, orderOrStripeIsInProgress])

  // Analytics VAT number field being filled
  useEffect(() => {
    // No VAT number field is displayed
    if (!vatInputRequired) return

    // VAT number field does not exist
    if (typeof VATNumber === 'undefined') return

    if (VATNumber) logAnalyticsEvent('VAT_number_input_is_filled_on_order_validation_screen', {})
    else logAnalyticsEvent('VAT_number_input_is_empty_on_order_validation_screen', {})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatInputRequired])

  // Clear purchase flow store when leaving the component after placing an order
  useEffect(() => {
    if ((orderHasBeenPlaced || orderPlacementHasError) && cleanupTimeoutRef.current) {
      window.clearTimeout(cleanupTimeoutRef.current)
    }
  }, [dispatch, orderHasBeenPlaced, orderPlacementHasError])

  // Clear purchase flow store when leaving the component while placing an order
  useEffect(() => {
    if (orderIsBeingPlaced) {
      return () => {
        cleanupTimeoutRef.current = window.setTimeout(() => {
          if (orderIsBeingPlaced) {
            window.alert(t('step_validation.leave_warning'))
          }
        }, 1000)
      }
    }
  }, [dispatch, t, orderIsBeingPlaced])

  // Warn about order being processed while leaving the browser tab
  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      if (orderIsBeingPlaced) {
        // Cancel the event
        // Calling preventDefault on the event obj is required to trigger the dialog.
        // This way is supported in all major browsers in their newer versions.
        e.preventDefault()

        // In legacy versions or browsers truthy returnValue was required for triggering the popup. Kept for compat. 
        e.returnValue = t('step_validation.leave_warning')
      }
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [orderIsBeingPlaced, t])

  return (
    <TransitionAppear visible>
      <StepWrapper title={t('step_validation.title')} subtitle={t('step_validation.text')}>

        <Stack flexDirection="row" justifyContent="space-between" gap={4}>

          {/** All data related to logistics */}
          <Logistics />

          <Stack width="100%" gap={4}>

            {/** All data related to the products */}
            <SectionedBorderBox title={t(`category:${selectedCategory}`)}>

              {selectedCategory && !isEditingCategory(selectedCategory)
                ? <ProductListing onlySelected={true} disabledSelection={true} displayTypeHeading={false} />
                : <EditingProductListing onlySelected={true} disabledSelection={true} displayTypeHeading={false} />
              }

              <Stack marginTop={2} gap={2}>
                {/** Product comments */}
                {productComments && selectedCategory !== EditingCategory.EDITING &&
                  <DynamicSummary
                    title={t('step_validation.product_comments')}
                    value={
                      <SummaryTextBlock
                        value={productComments}
                      />
                    }
                  />
                }

                {/** External reporting */}
                {!!externalReportingInstruction && externalReportingField &&
                  <DynamicSummary
                    title={t(`product:p_${externalReportingInstruction.id}`)}
                    value={
                      <SummaryTextBlock
                        value={externalReportingField}
                      />
                    }
                  />
                }
              </Stack>

            </SectionedBorderBox>

            {/* All data related to payment method (STRIPE) */}
            {isStripePaymentMethod &&
              <SectionedBorderBox title={t(`product_kind:${selectedPaymentMethod}`)} flex={1} height="100%">
                <PurchaseFlowPayment disabled={orderOrStripeIsInProgress} />
              </SectionedBorderBox>
            }

          </Stack>

        </Stack>

      </StepWrapper>
    </TransitionAppear>
  )
}
