import { AnalyticsEvent } from 'utils/analytics'
import { GalleryImage } from 'components/common/Gallery/GalleryImage'
import { MUIButton } from 'components/common/MUIButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useCallback } from 'react'
import { useClientGallery } from '../_main/contexts/ClientGallery.context'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery staging flow card
 * 
 * @example <ClientGalleryPhotoStagingCard />
 */
export const ClientGalleryPhotoStagingCard = () => {
  const { t } = useTranslation(['gallery'])
  const { openStagingFlow } = useClientGallery()
  const { logGalleryEvent } = useGalleryAssignment()

  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('lg'))

  const handleStagingFlow = useCallback(() => {
    logGalleryEvent(AnalyticsEvent.CLICK_GALLERY_STAGING)
    openStagingFlow()
  }, [openStagingFlow, logGalleryEvent])

  return (
    <GalleryImage>
      <Stack padding={{ xs: 1, md: 2, l: 4 }}>

        <Typography paddingBottom={{ xs: 1, l: 0 }} variant={isMobileView ? 'text-xs' : 'text-md'} fontWeight="bold">{t('client_gallery.staging_todo.single_staging_todo_title')}</Typography>

        <Typography paddingBottom={{ xs: 2, l: 4 }} variant={isMobileView ? 'body1' : 'text-sm'} fontWeight="regular">{t('client_gallery.staging_todo.single_staging_todo_description')}</Typography>

        <Stack marginX='auto'>
          <MUIButton size="xs" onClick={handleStagingFlow}>{t('client_gallery.staging_todo.single_staging_todo_button')}</MUIButton>
        </Stack>

      </Stack>
    </GalleryImage>
  )
}
