import { BLUE_600, DEEP_SPACE_BLACK, GRAY_400, GRAY_600, GRAY_800, WHITE } from 'constants/styling/theme'
import { DateTimePicker, DateTimePickerProps, DateTimePickerSlotsComponentsProps } from '@mui/x-date-pickers'

import { BKBNThemeWrapper } from '../BKBNThemeWrapper'
import { Moment } from 'moment-timezone'
import React from 'react'
import { styled } from '@mui/material'

/**
 * @interface Props
 */
export interface Props<TDate = Moment> extends DateTimePickerProps<TDate> {
  onInputBlur?: () => void
  isError?: boolean
}

const StyledDatePicker = styled((props: Props) => <DateTimePicker {...props} />)({
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    fontSize: '1.3rem',

    '& fieldset': {
      borderRadius: '8px',
      borderColor: GRAY_400,
      transition: 'border 200ms ease',
    },
    '&:hover fieldset': {
      borderColor: GRAY_600,
    },
    '&.Mui-focused fieldset': {
      borderColor: DEEP_SPACE_BLACK,
      borderWidth: '1px',
    },

    '& .MuiInputBase-input': {
      border: 'none',
      boxShadow: 'none',
      padding: '0.8rem .8rem .8rem 1.5rem',

      '&:focused': {
        boxShadow: 'none',
      },
    },
  },

  '& .MuiPickersPopper-root': {
    '& .MuiPickersCalendarHeader-root': {
      backgroundColor: 'yellow',
      '& .MuiPickersCalendarHeader-label': {
        color: 'red',
      },
    },
  },
})

/**
 * @component BKBN styled Datepicker which uses MUI as a base
 * @example
 * <MUIDatePicker />
 */
export const MUIDatePicker: React.FC<Props> = (props) => {

  const slotProps: DateTimePickerSlotsComponentsProps<moment.Moment> = {
    actionBar: {
      sx: {
        fontSize: '1.3rem !important',
        '& > *': {
          fontSize: '1.3rem !important',
        },
      },
    },
    dialog: {
      sx: {
        fontSize: '1.3rem !important',
      },
    },
    layout: {
      sx: {
        '& .MuiPickersCalendarHeader-label': {
          fontSize: '1.5rem',
          fontWeight: '600',
          color: GRAY_800,
        },
      },
    },
    rightArrowIcon: {
      fontSize: 'large',
    },
    leftArrowIcon: {
      fontSize: 'large',
    },
    switchViewIcon: {
      fontSize: 'large',
    },
    digitalClockSectionItem: {
      sx: {
        fontSize: '1.4rem',
      },
    },
    day: {
      sx: {
        fontSize: '1.2rem',
        '&.MuiPickersDay-root.Mui-disabled': {
          '&:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '25%',
            right: 0,
            height: '1px',
            width: '50%',
            backgroundColor: GRAY_600,
            transform: 'rotate(-45deg)',
          },
        },
      },
    },
    textField: {
      error: !!props.isError,
      onBlur: props.onInputBlur,
    },
    ...props.slotProps,
  }

  return (
    <BKBNThemeWrapper themeOverrides={{
      palette: {
        primary: {
          main: BLUE_600,
          contrastText: WHITE,
        },
      },
    }}>
      <StyledDatePicker
        {...props}
        slotProps={slotProps}
      />
    </BKBNThemeWrapper>
  )
}
