import { FloorPlanRenderType, FloorPlanSelectionType, useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createFileFromBlob, downloadLogo } from 'utils/helpers'
import { useGetFloorPlanTemplateDetails, useGetFloorPlanTemplateLogo, useGetFloorPlanTemplates } from 'dataQueries'
import { usePurchaseFlowConfig, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { CONST_PRODUCT_SERIAL } from 'constants/product'
import { FloorPlanColors } from '../FloorPlanPreview/basicFloorPlanConstants'
import { FloorPlanConfigOptions } from '../FloorPlanPreview/floorPlanOptions'
import { FloorPlanFormat } from '../FloorPlanLayout'
import { FloorPlanTemplateDetailsDTO } from 'models/purchaseFlow'
import { Language } from 'translations/Language'
import { SelectableImageBox } from '../SelectableImageBox'
import Stack from '@mui/material/Stack'
import { Styles } from '../FloorPlanPreview/floorPlan3DConstants'
import basic_black from 'assets/img/floorplan/2D/2D_commercial_bw.webp'
import default_template_2D_neutral from 'assets/img/floorplan/default_template_2D_neutral.webp'
import modern_grey_3D from 'assets/img/floorplan/3D/modern_grey_3D.webp'
import { uploadPurchaseSessionVisual } from 'redux/Individual/Visual/LoadPurchaseSessionVisual'
import { useDispatch } from 'react-redux'
import { useFloorPlanLogo } from '../_main/FloorPlanLogo.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

type Template = {
  id: string
  name: string
  renderType: FloorPlanRenderType
  imagePlaceholder: string
  config: FloorPlanConfigOptions
}

export const DEFAULT_TEMPLATE_2D_ID = 'default-template-2d'

const basicFloorPlanSimpleConfig: FloorPlanConfigOptions = {
  style: FloorPlanColors.BLACK,
  showFixtures: true,
  showFurniture: true,
  showRoomNames: true,
  showRoomArea: true,
}

/**
 * @component
 * FloorPlanTemplates displays user available templates
 * 
 * @example
 * <FloorPlanTemplates />
 */
export const FloorPlanTemplates: React.FC = () => {

  const dispatch = useDispatch()
  const { t } = useTranslation(['purchase_flow'])
  const { targetUser, adminSelectedUserEmail } = useTargetOrderUser()
  const { currentUserWorkspace } = useUserData()
  const { sessionId } = usePurchaseFlowConfig()
  const { isTemplateLogoUploading } = useFloorPlanLogo()
  const {
    floorPlanProduct,
    selectedTemplateId,
    selectedColor,
    selectedStyle,
    showCompass,
    showDimensionChain,
    showEachRoomDimensions,
    showFixtures,
    showFurniture,
    showRoomArea,
    showRoomNames,
    showScalebar,
    floorPlanFormat,
    outputLanguage,
    floorPlanTemplateType,
    setOutputLanguage,
    setFloorPlanFormat,
    setCompanyLogo,
    setIsActiveLogo,
    setSelectedTemplateId,
    setSelectedColor,
    setSelectedStyle,
    setCurrentFloorPlanRenderType,
    setShowCompass,
    setShowDimensionChain,
    setShowEachRoomDimensions,
    setShowFixtures,
    setShowFurniture,
    setShowRoomArea,
    setShowRoomNames,
    setShowScalebar,
    setFloorPlanTemplateType,
    setIsActiveDimensions,
  } = useFloorPlanConfig()

  if (!sessionId) throw new Error('Missing sessionId')

  const [templateDetails, setTemplateDetails] = useState<FloorPlanTemplateDetailsDTO[]>([])

  const floorPlanTemplates = useGetFloorPlanTemplates()
  const floorPlanTemplateDetail = useGetFloorPlanTemplateDetails()
  const floorPlanTemplateLogo = useGetFloorPlanTemplateLogo()

  useEffect(() => {
    if (!floorPlanTemplates.data) return

    floorPlanTemplates.data.forEach(async template => {
      const { data } = await floorPlanTemplateDetail.mutateAsync({ templateId: template.templateId })
      let logoFile: File | undefined = undefined

      try {
        if (data.companyLogoId) {
          const logoData = await floorPlanTemplateLogo.mutateAsync({ logoId: data.companyLogoId, workspaceId: currentUserWorkspace?.id ?? '' })
          if (logoData.data) {
            const getLogo = await downloadLogo(logoData.data.signedURL, logoData.data.headers)
            logoFile = createFileFromBlob(getLogo, logoData.data.filename)
          }
        }
      } catch (error) {
        console.error('Failed to fetch logo:', error)
      }
      setTemplateDetails(prev => [...prev, { ...data, templateLogoFile: logoFile }])
    })

    // Omit floorPlanTemplateDetail and floorPlanTemplateLogo dependency to prevent loop 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorPlanTemplates.data])

  const organisationTemplates: Template[] = useMemo(() => {

    return templateDetails.map(template => {
      const floorPlanFormat = template.outputFileFormat.reduce((obj: Record<string, FloorPlanFormat>, format) => {
        if (FloorPlanFormat[format]) {
          obj[FloorPlanFormat[format]] = FloorPlanFormat[format]
        }
        return obj
      }, {})

      const orientation = template.renderType === FloorPlanRenderType.RENDER_3D ? template.orientation3d : undefined
      const defaultPlaceholder = template.renderType === FloorPlanRenderType.RENDER_2D ? basic_black : modern_grey_3D

      return {
        id: template.id,
        name: template.templateName,
        renderType: template.renderType,
        imagePlaceholder: template.previewImageUrl ?? defaultPlaceholder,
        config: {
          style: template.style,
          orientation,
          showFixtures: template.fixtures,
          showFurniture: template.furniture,
          showRoomNames: template.roomNames,
          showScalebar: template.scaleBar,
          showCompass: template.compass,
          companyLogo: template.companyLogoId,
          outputLanguage: template.outputFileLanguage,
          showEachRoomDimensions: template.showEachRoomDimensions,
          showDimensionChain: template.showDimensionChain,
          showEachRoomArea: template.showEachRoomArea,
          floorPlanFormat,
          templateLogoFile: template.templateLogoFile
        }
      }
    })

  }, [templateDetails])

  const availableTemplates = useMemo(() => {
    const templates: Template[] = organisationTemplates

    const defaultSimpleTemplate: Template = {
      id: DEFAULT_TEMPLATE_2D_ID,
      config: basicFloorPlanSimpleConfig,
      name: t('floor_plan_step.templates.default_template_name_2d'),
      imagePlaceholder: default_template_2D_neutral,
      renderType: FloorPlanRenderType.RENDER_2D
    }

    templates.push(defaultSimpleTemplate)

    return templates
  }, [t, organisationTemplates])

  const handleTemplateLogoUpload = useCallback((logoFile: File) => {
    if (!sessionId || !floorPlanProduct) return

    dispatch(uploadPurchaseSessionVisual(sessionId, floorPlanProduct.id, CONST_PRODUCT_SERIAL, logoFile, adminSelectedUserEmail))
  }, [adminSelectedUserEmail, dispatch, floorPlanProduct, sessionId])

  const applyTemplateConfig = useCallback((config: FloorPlanConfigOptions, renderType: FloorPlanRenderType) => {
    if (config.showCompass !== showCompass) setShowCompass(config.showCompass ?? false)
    if (config.showDimensionChain !== showDimensionChain) setShowDimensionChain(config.showDimensionChain ?? false)
    if (config.showEachRoomDimensions !== showEachRoomDimensions) setShowEachRoomDimensions(config.showEachRoomDimensions ?? false)
    if (config.showFixtures !== showFixtures) setShowFixtures(config.showFixtures ?? false)
    if (config.showFurniture !== showFurniture) setShowFurniture(config.showFurniture ?? false)
    if (config.showRoomArea !== showRoomArea) setShowRoomArea(config.showRoomArea ?? false)
    if (config.showRoomNames !== showRoomNames) setShowRoomNames(config.showRoomNames ?? false)
    if (config.showScalebar !== showScalebar) setShowScalebar(config.showScalebar ?? false)
    if (config.outputLanguage !== outputLanguage) setOutputLanguage(config.outputLanguage ?? Language[(targetUser.language as Language)] ?? Language.EN)
    if (config.floorPlanFormat !== floorPlanFormat) setFloorPlanFormat(config.floorPlanFormat ?? { [FloorPlanFormat.JPG]: FloorPlanFormat.JPG })

    if (config.companyLogo && config.templateLogoFile) {
      setIsActiveLogo(true)
      setCompanyLogo(config.templateLogoFile.name)
      handleTemplateLogoUpload(config.templateLogoFile)
    } else {
      setIsActiveLogo(false)
      setCompanyLogo(null)
    }

    if (renderType === FloorPlanRenderType.RENDER_2D) {
      if (config.style !== selectedColor) setSelectedColor(config.style as FloorPlanColors)
    } else {
      if (config.style !== selectedStyle) setSelectedStyle(config.style as Styles)
    }

    if (config.showDimensionChain || config.showRoomArea || config.showEachRoomDimensions) {
      setIsActiveDimensions(true)
    } else {
      setIsActiveDimensions(false)
    }

  }, [floorPlanFormat, handleTemplateLogoUpload, outputLanguage, selectedColor, selectedStyle, setCompanyLogo, setFloorPlanFormat, setIsActiveDimensions, setIsActiveLogo, setOutputLanguage, setSelectedColor, setSelectedStyle, setShowCompass, setShowDimensionChain, setShowEachRoomDimensions, setShowFixtures, setShowFurniture, setShowRoomArea, setShowRoomNames, setShowScalebar, showCompass, showDimensionChain, showEachRoomDimensions, showFixtures, showFurniture, showRoomArea, showRoomNames, showScalebar, targetUser.language])

  const handleSelectTemplate = (templateId: string, config: FloorPlanConfigOptions, renderType: FloorPlanRenderType) => {
    setSelectedTemplateId(templateId)
    setCurrentFloorPlanRenderType(renderType)
    applyTemplateConfig(config, renderType)

    if (floorPlanTemplateType !== FloorPlanSelectionType.TEMPLATE) {
      setFloorPlanTemplateType(FloorPlanSelectionType.TEMPLATE)
    }
  }

  return (
    <Stack marginTop={2} direction="row" justifyContent="center" alignItems="center" gap={2} sx={{ flexFlow: 'row wrap' }} minWidth="40rem">
      {availableTemplates.map(({ id, name, imagePlaceholder, renderType, config }) =>
        <SelectableImageBox
          key={id}
          text={name}
          width={16.8}
          height={14.4}
          disabled={isTemplateLogoUploading}
          elevation='xs'
          image={imagePlaceholder}
          inactiveBorderColor='transparent'
          isSelected={id === selectedTemplateId}
          onSelect={() => !isTemplateLogoUploading && handleSelectTemplate(id, config, renderType)}
        />
      )}
    </Stack>
  )
}
