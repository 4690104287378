import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionRequest } from 'models/redux'
import { UserStatusStore } from '.'
import { createAPIAction } from 'redux/Helpers'

export interface ActionUserStatus extends ActionRequest {
  payload: {
    email?: string
    request: UserStatusStore
  }
}

export function userStatus(email?: string): ActionUserStatus {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_STATUS], {
    email,
  })
}

export function purgeUserStatus(): ActionUserStatus {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.USER_STATUS], {})
}