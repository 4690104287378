// TODO: Tech debt - Refactor of this component
import './GoogleMap.sass'

import { BOX_SHADOW_MD, WHITE } from 'constants/styling/theme'
import { CSSProperties, Component, FormEvent, Fragment, ReactNode } from 'react'
import { GoogleMap as DefaultGoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api'
import Geocode, { ReactGeocodePlace, ReactGeocodePoint } from 'react-geocode'

import Autocomplete from 'react-google-autocomplete'
import { GOOGLE_API_KEY } from 'constants/API'
import { Translation } from 'react-i18next'
import classnames from 'classnames'

Geocode.setApiKey(GOOGLE_API_KEY)
// Geocode.enableDebug()

export interface Props {
  /** The additional classes to append */
  className?: string
  /** Center point of the map and initial marker position if not specified */
  center?: ReactGeocodePoint
  /** Initial marker position */
  initialMarker?: ReactGeocodePoint
  /** initial map zoom */
  zoom?: number
  /** mac container height */
  height: number | string
  /** The initial address input string */
  initialAddress?: string
  /** error text to display beside the input */
  error?: string
  /** Action called when address changes */
  handleChange: (place: ReactGeocodePlace | null, isInitialLookup: boolean) => void
  /** Handle autocomplete input change */
  onInputChange?: ((event: FormEvent<HTMLInputElement>) => void)
  /** Optional hook for autocomplete input blur event */
  onInputBlur?: () => void
  /** Position of the input, either above or below the map container, defaults to below */
  inputPosition?: 'below' | 'above'
  /** Whether the map should try to obtain current location of the user */
  geolocation?: boolean
  /** How long it takes for new props to populate the state */
  timeoutAfterPropsChange?: number
  /** Language used to initialize the google script */
  language?: string
  /** Country code used to specify where to search for addresses */
  countryCode?: string
  /** A string or an array of countries such es ['CH, AT, DE'] to restrict the search to */
  restrictToCountries?: string | string[]
  /** Optional slot for rendering circles/rectangles or other markers */
  mapObject?: ReactNode
  /** Optional styles for address input */
  inputStyles?: CSSProperties
}

interface State {
  [key: string]: any
  /** Address string */
  address: string
  /** Google Maps API place object */
  place_object: ReactGeocodePlace | null
  /** Map center */
  mapCenter: ReactGeocodePoint
  /** Marker position on the map */
  markerPosition: ReactGeocodePoint | null
  /** Map zoom */
  zoom: number
}

/**
 * Component for google maps address selection
 *
 * @class GoogleMap
 * @extends {Component<Props, State>}
 * @example
 * <GoogleMap
 *  center={{lat: 46.1993826, lng: 6.135054}}
 *  zoom={6}
 *  height={'400px'}
 *  handleChange={address => {console.log(address)}}
 * />
 */
class GoogleMap extends Component<Props, State> {

  static initialZoom = 4
  static zoomedIn = 14
  static initialCenter: ReactGeocodePoint = { lat: 46.4601654, lng: 7.5588792 }

  constructor(props: Props) {
    super(props)
    const state: State = {
      address: this.props.initialAddress ?? '',
      place_object: null,
      mapCenter: GoogleMap.initialCenter,
      markerPosition: null,
      zoom: this.props.zoom ?? GoogleMap.initialZoom,
    }
    if (this.props.center) {
      state.mapCenter = {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      }
    }
    if (this.props.initialMarker) {
      state.markerPosition = {
        lat: this.props.initialMarker.lat,
        lng: this.props.initialMarker.lng
      }
    }
    this.state = state
  }

  /** Handle change in autocomplete text input */
  handleAutocompleteChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({
      address: event.currentTarget.value
    })
  }

  /** set initial google place from passed props */
  componentDidMount() {
    if (this.props.language) Geocode.setLanguage(this.props.language)
    if (this.props.countryCode) Geocode.setRegion(this.props.countryCode)
    this.setInitialPlace()
  }

  /** Set initial place either from the the initial address string or geolocation lookup */
  setInitialPlace() {
    if (this.props.initialAddress) {
      const tryLookupAddress = async () => {
        try {
          const place = await this.lookupPlaceFromAddress(this.props.initialAddress ?? '')
          this.setStateFromPlace(place, true)
        } catch (error) {
          console.error(error)
          this.setStateFromPlace(null, true)
        }
      }
      tryLookupAddress()
    }

    if (this.props.geolocation) {
      this.lookupFromCurrentPosition()
    }
  }

  /** Geocode API lokup place from lat, lon geoposition */
  lookupPlaceLatLng = async (lat: number, lng: number) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng)
      if (response.results.length === 0) return null
      return response.results[0]
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /** Geocode API lookup place from string */
  lookupPlaceFromAddress = async (address_string: string) => {
    try {
      const response = await Geocode.fromAddress(address_string)
      if (response.results.length === 0) return null
      return response.results[0]
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /** Set component state from the Geocode API place object */
  setStateFromPlace = (place: ReactGeocodePlace | null, isInitialLookup: boolean = false) => {
    let newState: { [key: string]: any } = {
      address: place?.formatted_address ?? '',
      place_object: place,
    }
    if (place !== null) {
      const { lat, lng } = place.geometry.location
      newState = {
        ...newState,
        mapCenter: { lat, lng },
        markerPosition: { lat, lng },
      }
      newState = {
        ...newState,
        zoom: GoogleMap.zoomedIn,
      }
    }
    this.setState(newState)
    this.propagateChanges(place, isInitialLookup)
  }

  /** Handles the success of the geolocation request and updates the component's state
   *  based on the latitude and longitude information.
   */
  handleGeolocationSuccess = async (GeolocationPosition: GeolocationPosition) => {
    const { latitude, longitude } = GeolocationPosition.coords

    try {
      const response = await this.lookupPlaceLatLng(latitude, longitude)
      this.setStateFromPlace(response, true)
    } catch (error) {
      console.error(error)
      this.setStateFromPlace(null, true)
    }
  }

  /** Lookup current position via browser geolocation */
  lookupFromCurrentPosition = async () => {
    if (!window.navigator.geolocation) return

    window.navigator.geolocation.getCurrentPosition((GeolocationPosition) => {
      (async () => {
        await this.handleGeolocationSuccess(GeolocationPosition)
      })()
    })
  }

  /** compare props and call API for initial address or coordinate search if necessary */
  componentDidUpdate(prevProps: Props, prevState: State) {
    const newState: any = {}
    if (this.props.initialAddress && this.props.initialAddress !== prevProps.initialAddress) {
      newState.address = this.props.initialAddress
    }
    if (this.props.initialMarker && this.props.initialMarker !== prevProps.initialMarker) {
      newState.markerPosition = this.props.initialMarker
      newState.mapCenter = this.props.initialMarker
      newState.zoom = GoogleMap.zoomedIn
    }

    if (Object.keys(newState).length === 0) return

    setTimeout(() => {
      this.setState(newState, () => {
        this.setInitialPlace()
      })
    }, this.props.timeoutAfterPropsChange ?? 0)

    if (this.props.language && this.props.language !== prevProps.language) Geocode.setLanguage(this.props.language)
    if (this.props.countryCode && this.props.countryCode !== prevProps.countryCode) Geocode.setRegion(this.props.countryCode)
  }

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = async (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat() ?? 0
    const lng = event.latLng?.lng() ?? 0

    if (!event.latLng) console.error('latLng is null')

    this.setState({
      markerPosition: { lat, lng },
      mapCenter: { lat, lng },
    })

    try {
      const response = await Geocode.fromLatLng(lat, lng)
      let place_object: ReactGeocodePlace | null = null
      if (response.results.length > 0) {
        place_object = response.results[0]
      }
      this.setStateFromPlace(place_object)
    } catch (error) {
      console.error(error)
      this.setStateFromPlace(null)
    }
  }

  /**
   * When the user selects an address in the search box
   * @param place
   */
  onPlaceSelected = async (place: google.maps.places.PlaceResult) => {
    if (place.name) {
      try {
        const lookupPlace = await this.lookupPlaceFromAddress(place.name)
        return this.setStateFromPlace(lookupPlace)
      } catch (error) {
        console.error(error)
        return this.setStateFromPlace(null)
      }
    }

    if (!place?.geometry || !place.formatted_address) {
      return this.setStateFromPlace(null)
    }

    const lat = place.geometry.location?.lat() ?? 0
    const lng = place.geometry.location?.lng() ?? 0

    if (!place.geometry.location) console.error('place.geometry.location is null')

    const place_object: ReactGeocodePlace = {
      ...place,
      geometry: {
        location: {
          lat,
          lng,
        }
      },
      place_id: place.place_id ?? '',
      formatted_address: place.formatted_address ?? '',
      address_components: place.address_components ?? [],
    }

    this.setStateFromPlace(place_object)
  }

  /** Propagate changes to the outer world */
  propagateChanges = (newPlace: ReactGeocodePlace | null, isInitialLookup: boolean = false) => {
    this.props.handleChange(newPlace, isInitialLookup)
  }

  static libraries: ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[] = ['places']

  render() {
    const map = (
      <DefaultGoogleMap
        mapContainerStyle={{
          height: this.props.height,
          borderRadius: '8px',
          boxSizing: 'border-box',
          boxShadow: BOX_SHADOW_MD,
          border: `2px solid ${WHITE}`,
        }}
        zoom={this.state.zoom}
        center={{ lat: this.state.mapCenter.lat, lng: this.state.mapCenter.lng }}
      >
        {this.state.markerPosition &&
          <Marker
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
          />
        }
        {this.props.mapObject}
      </DefaultGoogleMap>
    )

    const autocomplete = (
      <Translation ns="address_map">
        {
          (t, { i18n }) => (
            <div className="auto-complete">
              {this.props.error && this.props.inputPosition === 'above' &&
                <span className="error-message">{this.props.error}</span>
              }
              <Autocomplete
                inputAutocompleteValue={this.state.address}
                defaultValue={this.state.address}
                onChange={e => {
                  this.handleAutocompleteChange(e)
                  this.props?.onInputChange && this.props.onInputChange(e)
                }}
                onBlur={this.props.onInputBlur}
                style={{ ...(this.props.inputStyles || {}) }}
                placeholder={t('placeholder')}
                onPlaceSelected={this.onPlaceSelected}
                options={{
                  types: ['address'],
                  componentRestrictions: this.props.restrictToCountries ? { country: this.props.restrictToCountries } : undefined,
                }}
                className={
                  classnames({
                    'error-input': this.props.error,
                    'below': this.props.inputPosition === 'below',
                    'above': this.props.inputPosition === 'above',
                  })
                }
              />
              {this.props.error && this.props.inputPosition === 'below' &&
                <span className="error-message">{this.props.error}</span>
              }
            </div>
          )
        }
      </Translation>
    )

    let display = (
      <Fragment>
        {map}
        {autocomplete}
      </Fragment>
    )

    if (this.props.inputPosition === 'above') {
      display = (
        <Fragment>
          {autocomplete}
          {map}
        </Fragment>
      )
    }

    return (
      <div className={`map-adresss ${this.props.className ?? ''}`.trim()}>
        <LoadScriptNext
          googleMapsApiKey={GOOGLE_API_KEY}
          libraries={GoogleMap.libraries}
          language={this.props.language}
          region={this.props.countryCode}
        >
          {display}
        </LoadScriptNext>
      </div>
    )
  }
}

export default GoogleMap
