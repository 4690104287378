import { FC, ReactNode } from 'react'

import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import classNames from 'classnames'
import styles from './PageTab.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Tab */
  tab: string
  /** Status of Active tab */
  activeTab: string
  /** Text of tab */
  tabText: string
  /** OnClick action to switch tab */
  onClick: () => void
  /** Disable onClick action on tab */
  disabled?: boolean
  trailingIcon?: ReactNode
}

/**
 * Page tab component.
 * @example <PageTab activeTab="Gallery" tab="Order" tabText="Order" onClick={() => {}} />
 */
export const PageTab: FC<Props> = ({ activeTab, tab, tabText, onClick, trailingIcon, disabled = false }) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <button
      className={classNames(styles.tab, { [styles.active]: activeTab === tab, [styles.mobile]: isMobileView, [styles.disabled]: disabled })}
      onClick={handleClick}
    >
      {tabText} {trailingIcon}
    </button>
  )
}
