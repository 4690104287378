import { APIRequestErrorType, APIRequestState } from 'constants/API'
import { ArchiveVisualsStoreItem, ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import { Trans, useTranslation } from 'react-i18next'
import { supportEmail, supportEmailHref } from 'constants/contacts'
import { useCallback, useLayoutEffect, useRef } from 'react'

import { APIRequest } from 'models/API'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { Color } from 'constants/assets'
import { DownloadArchiveStoreItem } from 'redux/Individual/Visual/DownloadArchive'
import FetchedContent from 'components/common/FetchedContent/FetchedContent'
import { Nullable } from 'models/helpers'
import ReactLoading from 'react-loading'
import { archiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals/ArchiveVisuals.actions'
import downloadVisualsImg from 'assets/img/download_visuals/download_pending.png'
import { logDownloadRetryAfterError } from '../_main'
import styles from './GalleryDownloadStatus.module.sass'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useGalleryAssignment } from '../_main/contexts/GalleryAssignment.context'
import { useGalleryVisualSelection } from '../_main/contexts/GalleryVisualSelection.context'
import { useGalleryVisualType } from '../_main/contexts/GalleryVisualType.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Padding for download status block */
  nopadding?: boolean
  /** Whether the status block is simple version or not */
  isSimpleVersion?: boolean,
  /** Whether auto download the visuals or not */
  isAutoDownload?: boolean,
  /** The assignment ID */
  assignmentId: string,
  /** The archive visuals response */
  archive: Nullable<ArchiveVisualsStoreItem>
  /** The download visuals archive response */
  downloadVisualsArchive: Nullable<DownloadArchiveStoreItem>
  /** The download archive url */
  downloadArchiveUrl: string | undefined
}

/**
 * @component Download progress status displayed in Gallery for client
 * @example
 * <GalleryDownloadStatus assignmentId={assignmentId} archive={archive} downloadVisualsArchive={downloadVisualsArchive} downloadArchiveUrl={downloadArchiveUrl} />
 */
export const GalleryDownloadStatus: React.FC<Props> = ({
  nopadding = false,
  isSimpleVersion = false,
  isAutoDownload = false,
  assignmentId,
  archive,
  downloadVisualsArchive,
  downloadArchiveUrl
}) => {
  const dispatch = useDispatch()
  const { roles } = useAuth0()
  const { t } = useTranslation(['gallery'])
  const archiveDownloadAnchorRef = useRef<HTMLAnchorElement>(null)

  const {
    isRatingsFinished,
    product
  } = useGalleryAssignment()

  const {
    transformTypes,
  } = useGalleryVisualType()

  const {
    baseUserData,
    organizationData,
    nonPersonalUserWorkspaces,
  } = useUserData()

  const {
    selected
  } = useGalleryVisualSelection()

  const syntheticRequest = useCallback((archive: Nullable<ArchiveVisualsStoreItem>, downloadVisualsArchive: Nullable<DownloadArchiveStoreItem>, downloadArchiveUrl: string | undefined) => {
    let request: APIRequest<unknown> = APIRequest.factoryRunning(null)
    if (downloadVisualsArchive?.state === APIRequestState.OK) {
      if (downloadArchiveUrl) {
        request = APIRequest.factoryOK(null)
      } else {
        request = APIRequest.factoryError(APIRequestErrorType.UNKNOWN_ERROR, 'NO URL')
      }
    } else if (archive?.state === APIRequestState.ERROR) {
      request = APIRequest.factoryError(archive.error_type, archive.error)
    } else if (downloadVisualsArchive?.state === APIRequestState.ERROR) {
      request = APIRequest.factoryError(downloadVisualsArchive.error_type, downloadVisualsArchive.error)
    }
    return request
  }, [])

  const retryArchiveDownload = useCallback(async () => {
    if (!product) return

    // Trigger downloading of selected visuals, user retry flow
    dispatch(archiveVisuals(assignmentId, transformTypes(), Array.from(selected), ArchiveVisualsTag.CLIENT_ARCHIVE, roles.isAdmin))

    // Track archive download retry
    logDownloadRetryAfterError(product, roles, baseUserData?.id, organizationData?.id, nonPersonalUserWorkspaces)
  }, [product, dispatch, assignmentId, transformTypes, selected, roles, baseUserData, organizationData, nonPersonalUserWorkspaces])

  useLayoutEffect(() => {
    if (!downloadArchiveUrl) return
    if (!archiveDownloadAnchorRef?.current) return
    window.setTimeout(() => {
      if (isAutoDownload) archiveDownloadAnchorRef?.current?.click()
    }, 1500)
  }, [downloadArchiveUrl, isAutoDownload])

  return (
    <FetchedContent
      request={syntheticRequest(archive, downloadVisualsArchive, downloadArchiveUrl)}
      loading={
        <div className={`${styles.downloadWrap} ${nopadding ? styles.nopadding : ''}`}>
          {!isSimpleVersion && <img src={downloadVisualsImg} alt='Download visuals' width='100%' height="auto" />}
          <div className={styles.downloadText}>
            <h3 className={styles.downloadTitle}>{t('download_pending')}</h3>

            {roles.isClient && !isRatingsFinished &&
              <p>{t('rating_text')}</p>
            }

            <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          </div>
        </div>
      }
      error={
        <div className={`${styles.downloadWrap} ${nopadding ? styles.nopadding : ''}`}>
          {!isSimpleVersion && <img src={downloadVisualsImg} alt='Download visuals' width='100%' height="auto" />}

          <BlockInfo className={styles.downloadText}>
            <h3 className={styles.downloadTitle}>{t('download_error_title')}</h3>

            <span>{t('download_error_info')}</span>

            <span>
              <Trans t={t} i18nKey="download_error_support" values={{ supportEmail }}>
                <strong>
                  <a href={supportEmailHref()} className="link">{supportEmail}</a>
                </strong>
              </Trans>
            </span>

            <div className={styles.buttonSection}>
              <Button type="primary" onClick={() => retryArchiveDownload()}>{t('download_error_retry')}</Button>
            </div>
          </BlockInfo>
        </div>
      }
    >
      {downloadArchiveUrl && (isSimpleVersion ?
        <div className={`${nopadding ? styles.nopadding : ''}`}>
          <BlockInfo color="green">
            <Trans t={t} i18nKey={'download_hint'}>
              <a href={downloadArchiveUrl} rel="_blank" ref={archiveDownloadAnchorRef}>&nbsp;</a>
            </Trans>
          </BlockInfo>
        </div> :
        <div className={`${styles.downloadWrap} ${nopadding ? styles.nopadding : ''}`}>
          <img src={downloadVisualsImg} alt='Download visuals' width='100%' height="auto" />

          <BlockInfo className={styles.downloadText} color="green">
            <h3 className={styles.downloadTitle}>{t('download_ready')}</h3>

            <p>
              {t('download_ready_text_fancy')}
            </p>

            <div className={styles.buttonSection}>
              <Button type="secondary"><a href={downloadArchiveUrl} rel="_blank" ref={archiveDownloadAnchorRef}>{t('download_ready_button')}</a></Button>
            </div>
          </BlockInfo>
        </div>)
      }
    </FetchedContent >
  )
}
