import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionUserStatus } from '.'

/** Saga which fetches user status */
export function* userStatusSaga(receivedAction: ActionUserStatus) {
  const url = Endpoint.USER_STATUS
  const { email } = receivedAction.payload
  const action: ActionUserStatus = yield generalFetch(ActionTypeAPIData.USER_STATUS, () => API.get(url, {
    headers: {
      Accept: email ? RoleMimetype.ADMIN : RoleMimetype.CLIENT,
    },
    params: {
      email,
    }
  }, {
    endpoint: Endpoint.USER_STATUS,
  }))
  const userStatusAction: ActionUserStatus = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }
  yield put(userStatusAction)
}

/** Watcher of user status actions */
export function* userStatusWatcher() {
  yield all([
    takeEvery((action: ActionUserStatus) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_STATUS]), userStatusSaga),
  ])
}