import { Alert, AlertColor, AlertProps, AlertTitle } from '@mui/material'
import { BLUE_600, CORAL_700, GREEN_600, ORANGE_600 } from 'constants/styling/theme'

import React from 'react'
import { Trans } from 'react-i18next'

const DarkColorVariantMap: Record<AlertColor, string> = {
  error: CORAL_700,
  success: GREEN_600,
  warning: ORANGE_600,
  info: BLUE_600,
}

interface Props {
  /** Text content of the message */
  message: string
  /** Title of the box */
  title?: string
  /** Style variant of the box. */
  variant?: AlertProps['severity'],
}

/**
 * Component for displaying system messages to user.
 * 
 * @example
 * <SystemMessage message="I love snails." variant="info"/> 
 */
export const SystemMessage: React.FC<Props> = ({
  message,
  title,
  variant = 'info',
}) => {

  return (

    <Alert
      severity={variant}
      sx={{
        fontSize: '1.4rem',
        border: '1px solid transparent',
        borderColor: DarkColorVariantMap[variant],
      }}
    >

      {title &&
        <AlertTitle
          sx={{
            fontWeight: '600',
            fontSize: '1.45rem',
          }}
        >
          {title}
        </AlertTitle>
      }

      <Trans parent={undefined}>
        {message}
      </Trans>

    </Alert>
  )
}
