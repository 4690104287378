import React, { useCallback } from 'react'

import CampaignIcon from '@mui/icons-material/Campaign'
import { MUIButton } from 'components/common/MUIButton'
import { logAnalyticsEvent } from 'utils/analytics'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useTranslation } from 'react-i18next'

/**
 * @component Client gallery send request button
 * @example <ClientGallerySendRequest />
 */

export const ClientGallerySendRequest: React.FC = () => {
  const { t } = useTranslation(['gallery'])
  const {
    dealData,
  } = useGalleryDeal()
  const {
    assignmentData,
    product,
  } = useGalleryAssignment()

  /** All the events and links are set up in Intercom platform. FE only need to bind the button id with product kind. */
  const onSendFeedback = useCallback(() => {
    logAnalyticsEvent('visual_feedback_triggered', {
      productCategory: product?.category,
      productType: product?.type,
      productKind: product?.kind,
      assignmentId: assignmentData?.id,
      orderID: dealData?.id
    })
  }, [assignmentData?.id, dealData?.id, product?.category, product?.kind, product?.type])

  return (
    <MUIButton
      id={product?.kind}
      type="secondaryNoBorder"
      size="sm"
      startIcon={<CampaignIcon />}
      onClick={onSendFeedback}
    >
      {t('send_feedback')}
    </MUIButton>
  )
}
