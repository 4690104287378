import { Color } from 'constants/assets'
import React from 'react'
import ReactLoading from 'react-loading'
import classnames from 'classnames'
import styles from './TotalCount.module.sass'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Total count number or string displayed on the box */
  totalCount: string | number
  /** Whether the parent item is active or not */
  isActive?: boolean
}

/**
 * @component TotalCount used on dasboard filters to show number
 * @example
 * <TotalCount
 *  totalCount="26"
 * />
 */
const TotalCount: React.FC<Props> = ({
  totalCount,
  isActive = false
}) => (
  <div className={styles.totalCountWrap}>
    <div className={classnames(styles.totalCount, isActive ? styles.isActive : undefined)}>
      {totalCount ?
        <span>{String(totalCount)}</span>
        :
        <ReactLoading color={Color.GRAY_TEXT} type="cylon" height="2rem" width="2rem" />
      }
    </div>
  </div >
)

export default TotalCount
