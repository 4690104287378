import { EditingCategory } from 'constants/product'
import constate from 'constate'
import { useGetRenovationProducts } from 'dataQueries/purchase.query'
import { useMemo } from 'react'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { useTargetOrderUser } from './TargetOrderUser.context'

export const [PurchaseFlowRenovationProductContextProvider, usePurchaseFlowRenovationProduct] = constate(() => {
  const { sessionId } = usePurchaseFlowConfig()
  const { targetUser } = useTargetOrderUser()

  const getRenovationProduct = useGetRenovationProducts(sessionId, {
    category: EditingCategory.STAGING,
    countryCode: targetUser.country ?? '',
    email: targetUser.email ?? ''
  })

  // Query response data returning the renovation product kind (COMMERCIAL_RENOVATION).
  const renovationProduct = useMemo(() => {
    if (!getRenovationProduct.isSuccess || !getRenovationProduct.data?.data.data) return undefined

    return getRenovationProduct.data.data.data
  }, [getRenovationProduct.data?.data.data, getRenovationProduct.isSuccess])

  return {
    renovationProduct
  }
})
