export enum FileMimetypes {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_ALL = 'image/*',
}

export enum FileTypes {
  JPEG = '.jpeg',
  JPG = '.jpg',
  SVG = '.svg',
  PNG = '.png',
}

export const acceptedProductVisualMimetypes: Record<string, string[]> = {
  [FileMimetypes.IMAGE_JPEG]: [],
}
