import { ClientGalleryActionsPanel } from '../ClientGalleryActionsPanel'
import { ClientGalleryFilters } from '../ClientGalleryFilters'
import { FC } from 'react'
import Stack from '@mui/material/Stack'
import { useGalleryVisuals } from 'components/pages/Gallery/_main/contexts/GalleryVisuals.context'
import { useGalleryVisualsMeta } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'

/**
 * Client gallery photo section Action Panel and Filters
 * 
 * @example <ClientGalleryPhotoSectionActionFilterPanels />
 */
export const ClientGalleryPhotoSectionActionFilterPanels: FC = () => {

  const { favoritedVisuals } = useGalleryVisuals()

  const {
    purchasedVisualsCount,
    unPurchasedVisualsCount,
    purchasedVisualsExist
  } = useGalleryVisualsMeta()

  return (
    <Stack
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent={purchasedVisualsExist ? 'space-between' : 'flex-end'}
      alignItems={{ xs: 'start', md: 'center' }}
      gap="1.5rem"
    >

      <ClientGalleryFilters purchasedCount={purchasedVisualsCount} totalCount={purchasedVisualsCount + unPurchasedVisualsCount} favoritesCount={favoritedVisuals.size ?? 0} />

      <ClientGalleryActionsPanel />

    </Stack>
  )
}
