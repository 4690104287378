import { BEIGE_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, Fragment, useCallback, useMemo } from 'react'
import { RoomItemPopupType, useRenovationProducts, useStyleSelection } from '../main/contexts'
import { Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType } from 'constants/virtualFurniture'

import { AnalyticsEvent } from 'utils/analytics'
import BedRoundedIcon from '@mui/icons-material/BedRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import { CircleIcon } from 'components/common/CircleIcon'
import { Error } from '@mui/icons-material'
import LightRoundedIcon from '@mui/icons-material/LightRounded'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'
import { StagingType } from 'models/virtualStaging'
import { ToggleButton } from 'components/common/ToggleButton'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'

/** Props for the WallAndFloorAddOns component. */
interface WallAndFloorAddOnsProps {
  /** Indicates whether the wall option is selected. */
  isWallSelected: boolean
  /** Indicates whether the floor option is selected. */
  isFloorSelected: boolean
}

/**
 * @component 
 * Displays wall and floor add-ons buttons to trigger the respective room popups.
 * 
 * @example
 * <WallAndFloorAddOns isWallSelected={true} isFloorSelected={false} />
 */
const WallAndFloorAddOns: FC<WallAndFloorAddOnsProps> = ({
  isWallSelected,
  isFloorSelected,
}) => {
  const { t } = useTranslation(['style_selection_order_step'])
  const { updateRoom } = useRoomAPI()
  const {
    activeRoom,
    isBKBNDecide,
    openRoomItemPopup,
    stagingFlowAnalytics
  } = useStyleSelection()

  const handleToggleButton = useCallback((popupType: RoomItemPopupType, renovationType: VirtualFurnitureRoomType) => {
    if (isBKBNDecide) {
      if (activeRoom.renovations.get(renovationType)) {
        return updateRoom(activeRoom.key, { renovations: activeRoom.renovations.delete(renovationType) })
      }

      const analyticPopupType = popupType === RoomItemPopupType.FLOOR ? AnalyticsEvent.FLOOR_TEMPLATES_SAVED : AnalyticsEvent.WALL_TEMPLATES_SAVED
      stagingFlowAnalytics(analyticPopupType, { TemplateIDs: StagingType.BKBN })

      return updateRoom(activeRoom.key, { renovations: activeRoom.renovations.set(renovationType, StagingType.BKBN) })
    }

    return popupType && openRoomItemPopup(popupType)
  }, [activeRoom.key, activeRoom.renovations, isBKBNDecide, openRoomItemPopup, stagingFlowAnalytics, updateRoom])

  return (
    <Fragment>
      <ToggleButton
        isToggled={isWallSelected}
        label={t('room_items.WALL')}
        icon={<CircleIcon
          size="3.2rem"
          circleColor={BEIGE_600}
          icon={<PaletteOutlinedIcon fontSize="large" />}
        />}
        onClick={() => handleToggleButton(RoomItemPopupType.WALL, VirtualFurnitureRoomType.WALL_MATERIALS)}
      />

      <ToggleButton
        isToggled={isFloorSelected}
        label={t('room_items.FLOOR')}
        icon={<CircleIcon
          size="3.2rem"
          circleColor={BEIGE_600}
          icon={<BorderAllRoundedIcon fontSize="large" />}
        />}
        onClick={() => handleToggleButton(RoomItemPopupType.FLOOR, VirtualFurnitureRoomType.FLOORING)}
      />
    </Fragment>
  )
}

/** 
 * @component
 * Displays options for selecting different room items.
 * 
 * @example
 * <RoomItemsButtons />
 */
export const RoomItemsButtons: FC = () => {

  const { t } = useTranslation(['style_selection_order_step'])

  const {
    activeRoom,
    isSomeFurnitureSelected,
    isAllFurnitureSelected,
    isDecorationSelected,
    isWallSelected,
    isFloorSelected,
    isRoomEmptyType,
    isBKBNDecide,
    openRoomItemPopup
  } = useStyleSelection()
  const { renovationProductStringPrice } = useRenovationProducts()

  const hasSelectedRemovalOption = useMemo(() => activeRoom.furnitureRemoval !== VirtualFurnitureRoomRemovableOption.NONE, [activeRoom.furnitureRemoval])

  const shouldChargeRenovation = useMemo(() => {
    return (isRoomEmptyType && hasSelectedRemovalOption) || (!isRoomEmptyType && !hasSelectedRemovalOption)
  }, [hasSelectedRemovalOption, isRoomEmptyType])

  const bkbnDecideTitle = useMemo(() => {
    if (!shouldChargeRenovation) return t('wall_floor_question')

    return (
      <Trans
        t={t}
        i18nKey="wall_floor_price_question"
        values={{ price: renovationProductStringPrice }}
      >
        <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>&nbsp;</Typography>
      </Trans>)
  }, [renovationProductStringPrice, shouldChargeRenovation, t])

  const advancedOptionTitle = useMemo(() => {
    if (!shouldChargeRenovation) return t('add_object_to_room')

    return (
      <Trans
        t={t}
        i18nKey="advanced_staging_options"
        values={{ price: renovationProductStringPrice }}
      >
        <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>&nbsp;</Typography>
      </Trans>)
  }, [renovationProductStringPrice, shouldChargeRenovation, t])

  const mainGroupTitle = useMemo(() => {
    if (!isRoomEmptyType) return t('add_object_to_room')

    return advancedOptionTitle
  }, [advancedOptionTitle, isRoomEmptyType, t])

  const displayAddons = useMemo(() => {
    if (isRoomEmptyType) return true
    if (!isBKBNDecide && hasSelectedRemovalOption) return false
    return !hasSelectedRemovalOption || isBKBNDecide
  }, [hasSelectedRemovalOption, isBKBNDecide, isRoomEmptyType])

  return (
    <Stack gap="2.4rem" justifyContent="center" alignItems="center" paddingTop={1.8} maxWidth="35rem">

      {/** GRID BUTTONS (not displayed if BKBN decide is selected) */}
      {!isRoomEmptyType && !isBKBNDecide && (
        <Stack gap="1.6rem" alignItems="center" width="100%">

          <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>
            {mainGroupTitle}
          </Typography>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '.8rem' }}>

            {/** DECORATIONS & FURNITURE */}
            {!isRoomEmptyType &&
              <>
                <ToggleButton
                  isToggled={isSomeFurnitureSelected}
                  toggleIcon={!isAllFurnitureSelected ? <Error color="warning" /> : undefined}
                  label={t('room_items.FURNITURE')}
                  icon={<CircleIcon
                    size="3.2rem"
                    circleColor={BEIGE_600}
                    icon={<BedRoundedIcon fontSize="large" />}
                  />}
                  onClick={() => openRoomItemPopup(RoomItemPopupType.FURNITURE)}
                />

                <ToggleButton
                  isToggled={isDecorationSelected}
                  label={t('room_items.DECORATION')}
                  icon={<CircleIcon
                    size="3.2rem"
                    circleColor={BEIGE_600}
                    icon={<LightRoundedIcon fontSize="large" />}
                  />}
                  onClick={() => openRoomItemPopup(RoomItemPopupType.DECORATION)}
                />
              </>
            }

            {hasSelectedRemovalOption &&
              <WallAndFloorAddOns
                isWallSelected={isWallSelected}
                isFloorSelected={isFloorSelected}
              />
            }

          </div>

        </Stack>
      )}

      {displayAddons &&
        <Stack gap="1.6rem" alignItems="center" width="100%">

          <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900} marginBottom={.8}>
            {isBKBNDecide ? bkbnDecideTitle : advancedOptionTitle}
          </Typography>

          <Stack flexDirection="row" gap=".8rem" justifyContent="center">
            <WallAndFloorAddOns
              isWallSelected={isWallSelected}
              isFloorSelected={isFloorSelected}
            />
          </Stack>

        </Stack>
      }

    </Stack>
  )
}
