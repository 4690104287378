import { ClientGallery } from 'components/pages/Client/ClientGallery/_main'
import { Gallery } from './Gallery.page'
import { useAuth0 } from 'utils/auth'

/**
 * @component GalleryNoSlugRoleSwitch to resolve context wrapping to all Galleries
 */
export const GalleryNoSlugRoleSwitch: React.FC = () => {
  const { roles } = useAuth0()

  if (roles.isClient) return <ClientGallery />

  return (
    <Gallery />
  )
}
