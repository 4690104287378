import { Box, useMediaQuery } from '@mui/material'
import { CurrentActionType, ToolBarType, useClientGallery } from '../_main/contexts/ClientGallery.context'
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { decimalFromBig, formatPrice } from 'utils/price'

import { AnalyticsEvent } from 'utils/analytics'
import { ClientVisualsEditingExpress } from '../ClientGalleryVisualsEditingExpress'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { GalleryFloatingToolBar } from 'components/common/Gallery/GalleryFloatingToolBar'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { useGalleryAdditionalVisuals } from 'components/pages/Gallery/_main/contexts/GalleryAdditionalVisuals.context'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import { useGalleryVisualSelection } from 'components/pages/Gallery/_main/contexts/GalleryVisualSelection.context'
import { useGalleryVisuals } from 'components/pages/Gallery/_main/contexts/GalleryVisuals.context'
import { useGalleryVisualsDownloadArchive } from 'components/pages/Gallery/_main/contexts/GalleryVisualsDownloadArchive.context'
import { useGalleryVisualsMeta } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'
import { useNavigate } from 'react-router-dom'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditingSection } from 'components/pages/Gallery/VisualsEditingSectionController/VIsualsEditingSection.context'

/**
 * Floating tool bar for Client Gallery.
 * @example <ClientGalleryFloatingToolBar />
 */
export const ClientGalleryFloatingToolBar: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const { listedVisuals } = useGalleryVisuals()
  const { hasUserActiveSubscription } = useUserData()
  const { isVisualsEditingDisabled } = useVisualsEditingSection()
  const {
    initiateArchiveDownload,
    isArchiveDisabled
  } = useGalleryVisualsDownloadArchive()
  const {
    assignmentId,
    toolBarType,
    isToolBarOpen,
    setIsToolBarOpen,
    setToolBarType,
    setCurrentActionType
  } = useClientGallery()
  const {
    selectMaxValue,
    purchasedVisualsExist,
    purchasedVisualsKeys,
  } = useGalleryVisualsMeta()
  const {
    pricePerAdditionalVisual,
    selectedAdditionalVisualsCount,
    totalForAdditionalVisualsAfterDiscount,
    currency
  } = useGalleryAdditionalVisuals()
  const {
    selected,
    selectedPurchasedVisualsCount,
    selectableVisualsCount,
    selectAll,
    selectAllPurchased,
    deselectAll,
  } = useGalleryVisualSelection()
  const { isVideo } = useGalleryProduct()
  const { logGalleryEvent, product } = useGalleryAssignment()
  const { dealData } = useGalleryDeal()

  // For special clients, they can order specific products which have no visuals selection limit. In this case, clients still can select any number of visuals but we will automatically select all the visuals for them when they confirm the selection.
  const selectedMaxVisualsValue = useMemo(() => selectMaxValue !== PIPEDRIVE_INFINITY ? selectMaxValue : listedVisuals.length, [listedVisuals.length, selectMaxValue])
  const totalExtraPrice = formatPrice(decimalFromBig(totalForAdditionalVisualsAfterDiscount))

  const purchasedExistSelectionStatus = useMemo(() => {
    const isExtraPlus = selectedPurchasedVisualsCount !== 0 ? '+' : ''

    // TODO: will need to be refined when implement download/edit tool bar. 
    const selectedPurchasedValue = selectedPurchasedVisualsCount ? selectedPurchasedVisualsCount : ''
    const selectedExtraValue = (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled) ? t('selected_n_extra', { plus: isExtraPlus, count: selectedAdditionalVisualsCount, totalPrice: currency ? `( ${totalExtraPrice}${currency} )` : null }) : ''
    const selectedPurchasedAndExtraValue = selected.size === 0 ? selected.size : `${selectedPurchasedValue} ${selectedExtraValue}`

    return (
      <Fragment>
        {selectedPurchasedAndExtraValue} <Box fontWeight={400} display='inline'> {t('selected')}</Box>
      </Fragment>
    )
  }, [isArchiveDisabled, selected.size, selectedAdditionalVisualsCount, selectedPurchasedVisualsCount, currency, t, totalExtraPrice])

  const noPurchasedExistSelectionStatus = useMemo(() => {
    const selectedValue = Math.min(selected.size, selectedMaxVisualsValue)
    const isShowExtraValue = selectedAdditionalVisualsCount > 0 && !isArchiveDisabled && pricePerAdditionalVisual
    const extraValue = isShowExtraValue ? t('selected_n_extra', { plus: '+', count: selectedAdditionalVisualsCount, totalPrice: currency ? `( ${totalExtraPrice}${currency} )` : null }) : ''
    const selectedValueWithMaxValue = `${selectedValue}/${selectedMaxVisualsValue}`

    return (
      <Fragment>
        {selectedValueWithMaxValue} <Box fontWeight={400} display='inline'>{t('selected')}</Box> {!isMobileView && extraValue}
        {isMobileView && <Box fontWeight={400} fontSize={12} display='flex'>{extraValue}</Box>}
      </Fragment>
    )
  }, [isMobileView, isArchiveDisabled, pricePerAdditionalVisual, totalExtraPrice, selected.size, selectedAdditionalVisualsCount, selectedMaxVisualsValue, currency, t])

  const additionalVisualsPurchase = useCallback((type: CurrentActionType) => {
    setCurrentActionType(type)
    initiateArchiveDownload()
  }, [initiateArchiveDownload, setCurrentActionType])

  const handleConfirmSelection = useCallback(() => {
    logGalleryEvent(AnalyticsEvent.CLICK_CONFIRM_VISUAL_SELECTION, {
      assignmentOwnerId: dealData?.customer.id,
      assignmentOwnerEmail: dealData?.customer.email,
      productType: product?.type,
      productKind: product?.kind,
      numberOfVisuals: selected.size
    })

    additionalVisualsPurchase(CurrentActionType.DOWNLOAD)
  }, [additionalVisualsPurchase, dealData, logGalleryEvent, product, selected.size])

  const handleDownload = useCallback(() => {
    logGalleryEvent(AnalyticsEvent.CLICK_DOWNLOAD_VISUALS)

    additionalVisualsPurchase(CurrentActionType.DOWNLOAD)
  }, [additionalVisualsPurchase, logGalleryEvent])

  const onEditVisuals = useCallback(() => {
    if (!hasUserActiveSubscription) {
      logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_EDIT_VISUALS)

      return externalNavigate(Path.PRICING)
    }

    logGalleryEvent(AnalyticsEvent.CLICK_EDIT_VISUALS)
    if (selectedAdditionalVisualsCount > 0) return additionalVisualsPurchase(CurrentActionType.EDITING)

    navigate(Path.GALLERY_VISUALS_EDIT.replace(':id', assignmentId.toString()))
  }, [additionalVisualsPurchase, assignmentId, hasUserActiveSubscription, logGalleryEvent, navigate, selectedAdditionalVisualsCount])

  const onCancelToolBar = useCallback(() => {
    if (purchasedVisualsExist) setToolBarType(ToolBarType.EXPRESS)
    setIsToolBarOpen(false)
    deselectAll()
  }, [deselectAll, purchasedVisualsExist, setIsToolBarOpen, setToolBarType])

  useEffect(() => {
    if (purchasedVisualsExist) return setToolBarType(ToolBarType.EXPRESS)
    return setToolBarType(ToolBarType.SELECTION)
  }, [purchasedVisualsExist, setToolBarType])

  const outputActions = useCallback(() => {
    switch (toolBarType) {
      case ToolBarType.SELECTION:
        return (
          <MUITooltip
            placement='top'
            arrow={false}
            content={selected.size < selectMaxValue &&
              <Trans
                t={t}
                i18nKey="confirm_selection_disabled_note"
                values={{ maxValue: selectedMaxVisualsValue }}
              />
            }
          >
            <MUIButton
              fullWidth
              type='darkPrimary'
              disabled={selected.size < selectMaxValue}
              onClick={handleConfirmSelection}
            >
              {t('confirm_selection')}
            </MUIButton>
          </MUITooltip>
        )
      case ToolBarType.EXPRESS:
      case ToolBarType.DOWNLOAD:
        return (
          <Fragment>
            {!isVideo && <ClientVisualsEditingExpress />}
            <MUIButton
              type='darkPrimary'
              onClick={handleDownload}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
            >
              {t('download')}
            </MUIButton>
          </Fragment>
        )
      case ToolBarType.EDITING:
        if (isVideo) return
        return (
          <Fragment>
            <ClientVisualsEditingExpress />
            <MUIButton
              type='darkPrimary'
              onClick={onEditVisuals}
              startIcon={<EditOutlinedIcon fontSize='large' />}
              disabled={isVisualsEditingDisabled}
            >
              {t('edit_visuals_button')}
            </MUIButton>
          </Fragment>
        )
      default:
        break
    }
  }, [handleConfirmSelection, handleDownload, isVisualsEditingDisabled, isVideo, onEditVisuals, selectMaxValue, selected.size, selectedMaxVisualsValue, t, toolBarType])

  const isShowSelectAll = useMemo(() => {
    if (purchasedVisualsExist) return selectedPurchasedVisualsCount !== purchasedVisualsKeys.size
    return selected.size !== selectableVisualsCount
  }, [purchasedVisualsExist, purchasedVisualsKeys.size, selectableVisualsCount, selected.size, selectedPurchasedVisualsCount])

  return (
    <GalleryFloatingToolBar
      isWide={!purchasedVisualsExist && !!selectedAdditionalVisualsCount && !!currency}
      isToolBarOpen={isToolBarOpen}
      toolBarLabel={purchasedVisualsExist ? purchasedExistSelectionStatus : noPurchasedExistSelectionStatus}
      openToolBarButtonLabel={t('select_visuals')}
      onOpenToolBar={() => setIsToolBarOpen(true)}
      contentActionContainer={
        <Fragment>
          <MUIButton
            type='darkSecondaryNoborder'
            onClick={onCancelToolBar}
          >
            {t('cancel')}
          </MUIButton>
          {isShowSelectAll ?
            <MUIButton
              type='darkSecondaryNoborder'
              onClick={!purchasedVisualsExist ? selectAll : selectAllPurchased}
            >
              {t('select_all')}
            </MUIButton>
            :
            <MUIButton
              type='darkSecondaryNoborder'
              onClick={deselectAll}
            >
              {t('unselect_all')}
            </MUIButton>
          }
        </Fragment>
      }
      outputActionContainer={
        outputActions()
      }
    />
  )
}
