import { GalleryFilter, useClientGallery } from '../_main/contexts/ClientGallery.context'

import { FC } from 'react'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import Stack from '@mui/material/Stack'
import styles from './ClientGalleryFilters.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** Total count of visuals - purchased/unpurchased */
  totalCount: number
  /** Count of purchased visuals */
  purchasedCount: number
  /** Count of favorites visuals */
  favoritesCount: number
  /** Custom hide filter tab for specific flows */
  hideTab?: GalleryFilter
}

/**
 * Client gallery purchased/all filters.
 * 
 * @example <ClientGalleryFilters />
 */
export const ClientGalleryFilters: FC<Props> = ({ totalCount, purchasedCount, favoritesCount, hideTab = undefined }) => {
  const { filter, setFilter } = useClientGallery()
  const { t } = useTranslation(['gallery'])

  const filterTabRadius = '0.6rem'
  const filterTabHeight = '2.8rem'

  return (
    <Stack className={styles.clientGalleryFilters} direction="row" gap={2} alignItems="center">

      <span className={styles.filterBy}>{t('filter_by')}</span>

      <Stack direction="row" gap="0.8rem">

        {hideTab !== GalleryFilter.ALL &&
          <FilterTab
            isActive={filter === GalleryFilter.ALL}
            totalCount={String(totalCount)}
            label={t('filters.all')}
            onClick={() => setFilter(GalleryFilter.ALL)}
            height={filterTabHeight}
            radius={filterTabRadius}
          />
        }

        {hideTab !== GalleryFilter.PURCHASED &&
          <FilterTab
            isActive={filter === GalleryFilter.PURCHASED}
            totalCount={String(purchasedCount)}
            label={t('filters.purchased')}
            onClick={() => setFilter(GalleryFilter.PURCHASED)}
            height={filterTabHeight}
            radius={filterTabRadius}
          />
        }

        {hideTab !== GalleryFilter.FAVORITES &&
          <FilterTab
            isActive={filter === GalleryFilter.FAVORITES}
            totalCount={String(favoritesCount)}
            label={t('filters.favorites')}
            onClick={() => setFilter(GalleryFilter.FAVORITES)}
            height={filterTabHeight}
            radius={filterTabRadius}
          />
        }

      </Stack>

    </Stack>
  )
}
