import { useCallback, useMemo, useState } from 'react'
import { bigFromFee, formatPrice } from 'utils/price'

import constate from 'constate'
import { useUpdateCTRemuneration } from 'dataQueries'
import { AssignmentDTOIsAdministratorDTO } from 'utils/typeguards'
import { useGalleryAssignment } from '../_main/contexts/GalleryAssignment.context'

export const [ChangeRemunerationDrawerContextProvider, useChangeRemunerationDrawer] = constate(() => {
  const { assignmentData } = useGalleryAssignment()

  const data = useMemo(() => {
    if (!assignmentData) return
    if (!AssignmentDTOIsAdministratorDTO(assignmentData)) return
    return assignmentData
  }, [assignmentData])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [baseRemuneration, setBaseRemuneration] = useState(formatPrice(0))
  const [transportCost, setTransportCost] = useState(formatPrice(0))
  const [bonus, setBonus] = useState(formatPrice(0))

  const currency = useMemo(() => data?.creativeRemuneration?.totalRemuneration?.currency || '-', [data?.creativeRemuneration?.totalRemuneration?.currency])

  const displayTotal = useMemo(
    () => bigFromFee(baseRemuneration).plus(bigFromFee(transportCost || 0)).plus(bigFromFee(bonus || 0)),
    [baseRemuneration, bonus, transportCost]
  )

  const updateAction = useUpdateCTRemuneration()

  const openChangeRemunerationDrawer = () => {

    if (!!data?.creativeRemuneration) {
      setBaseRemuneration(formatPrice(bigFromFee(data.creativeRemuneration?.baseRemuneration || 0)))
      setTransportCost(formatPrice(bigFromFee(data.creativeRemuneration?.transportRemuneration || 0)))
      setBonus(formatPrice(bigFromFee(data.creativeRemuneration?.bonusRemuneration || 0)))
    }

    setIsDrawerOpen(true)

    // logAnalyticsEvent('--FUTURE EVENT--')
  }

  const closeChangeRemunerationDrawer = () => {
    setIsDrawerOpen(false)
    updateAction.reset()
  }

  const updateRemuneration = useCallback(() => {
    if (!data?.id || !data?.creative?.creativeId) return

    updateAction.mutate({
      creativeId: data?.creative?.creativeId,
      assignmentId: data?.id,
      baseRemuneration: +baseRemuneration,
      bonusRemuneration: +bonus,
      transportRemuneration: +transportCost,
    })
  }, [baseRemuneration, bonus, data?.creative?.creativeId, data?.id, transportCost, updateAction])

  return {
    isDrawerOpen,
    baseRemuneration,
    transportCost,
    bonus,
    displayTotal,
    currency,
    updateAction,
    updateRemuneration,
    setTransportCost,
    setBonus,
    setBaseRemuneration,
    openChangeRemunerationDrawer,
    closeChangeRemunerationDrawer,
  }
})
