import { useCallback, useEffect, useMemo, useState } from 'react'
import { AssignmentDTOIsAdministratorDTO, AssignmentDTOIsClientDTO, AssignmentDTOIsCreativeDTO } from 'utils/typeguards'

import { useFlag } from '@unleash/proxy-client-react'
import { AssignmentStage } from 'constants/assignment'
import constate from 'constate'
import { useSaveVirtualVisitLinks } from 'dataQueries'
import { uniqueId } from 'lodash'
import { useAuth0 } from 'utils/auth'
import { FeatureFlag } from 'utils/featureFlags'
import { useGalleryAssignment } from '../_main/contexts/GalleryAssignment.context'

/** Represents the instruction steps. */
export enum InstructionSteps {
  STEP_ID_DESCRIPTION = 'STEP_ID_DESCRIPTION',
  STEP_MATTERPORT_ACCOUNT = 'STEP_MATTERPORT_ACCOUNT',
  STEP_COPIED_LINK = 'STEP_COPIED_LINK'
}

/** Represents the upload status options. */
export enum UploadStatus {
  ADMIN_LINKS_UPLOADED = 'ADMIN_LINKS_UPLOADED',
  CT_LINKS_UPLOADED = 'CT_LINKS_UPLOADED',
  CT_TO_SUBMIT_LINKS = 'CT_TO_SUBMIT_LINKS',
  ADMIN_WAITING_UPLOAD = 'ADMIN_WAITING_UPLOAD'
}

/** Represents a link item object. */
type LinkItem = {
  [key: string]: {
    link: string
    error?: boolean
  }
}

/**
 * Generates a unique ID for a link input.
 *
 * @returns The generated unique ID.
 */
const _generateLinkId = () => uniqueId('link-')

/**
 * Generates a default link item.
 *
 * @returns The generated default link item object.
 */
const _generateDefaultLink = (): LinkItem => ({ [`link-${_generateLinkId()}`]: { link: '' } })

/** A set of stages associated with displaying delivered links. */
const stagesDisplayingDeliveredLinks = new Set([
  AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT
])

/** Regular expressions to match matterport links in different formats. */
const matterportPatterns = [
  /.*my\.matterport\.com\/show\/\?m=(\w+)&.+/im,
  /.*my\.matterport\.com\/show\/\?m=(\w+)'.+/im,
  /.*my\.matterport\.com\/show\/\?m=(\w+)".+/im,
  /.*my\.matterport\.com\/show\/\?m=(\w+)/im,
]

export const [GalleryVirtualVisitInstructionsContextProvider, useGalleryVirtualVisitInstructions] = constate(() => {

  const { roles } = useAuth0()
  const { assignmentData } = useGalleryAssignment()
  const allowVirtualVisitLinksComments = useFlag(FeatureFlag.ALLOW_VIRTUAL_VISIT_LINKS_COMMENTS)

  const [links, setLinks] = useState<LinkItem>()
  const [stepsChecked, setStepsChecked] = useState<Set<InstructionSteps>>(new Set())
  const [comments, setComments] = useState<string>('')

  // QUERY
  const saveVirtualVisitLinks = useSaveVirtualVisitLinks()

  /** Represents the array of links based on the assignment data if it meets the specified conditions. */
  const virtualVisitLinks = useMemo(() => {
    if (!assignmentData) return null
    if (AssignmentDTOIsClientDTO(assignmentData)) return null
    if (!assignmentData.virtualVisitUrls?.length) return null
    return assignmentData.virtualVisitUrls
  }, [assignmentData])

  /** Represents the upload status based on the current assignment data and roles. */
  const status: UploadStatus = useMemo(() => {
    const ctDelivered = !!assignmentData?.stage ? stagesDisplayingDeliveredLinks.has(assignmentData?.stage) : false

    if (roles.isCreative && !!virtualVisitLinks && ctDelivered) return UploadStatus.CT_LINKS_UPLOADED
    if (roles.isAdmin && !!virtualVisitLinks && ctDelivered) return UploadStatus.ADMIN_LINKS_UPLOADED
    if (roles.isCreative || roles.isAdmin && !virtualVisitLinks) return UploadStatus.CT_TO_SUBMIT_LINKS

    return UploadStatus.ADMIN_WAITING_UPLOAD
  }, [assignmentData?.stage, roles.isAdmin, roles.isCreative, virtualVisitLinks])

  /** Adds an empty value for the generated link ID to the existing links object. */
  const onAddLink = useCallback(() => {
    const updatedLinks = { ...links }
    updatedLinks[_generateLinkId()] = {
      link: ''
    }

    setLinks(updatedLinks)
  }, [links])

  /** Removes a link with the specified ID from the existing links object. */
  const onRemoveLink = useCallback((id: string) => {
    if (!!links) {
      const { [id]: _, ...updatedLinks } = links
      setLinks(updatedLinks)
    }
  }, [links])

  /** Callback function for handling link changes and validating the value is a matterport link. */
  const onLinkChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newValue = e.target.value

    setLinks({
      ...links,
      [id]: {
        link: newValue.trim(),
        error: matterportPatterns.every((pattern) => !pattern.test(newValue))
      }
    })
  }, [links, setLinks])

  /** Handles the change event of a checkbox for a specific instruction step. */
  const onChangeCheckbox = useCallback((key: InstructionSteps) => {
    setStepsChecked((prevStepsChecked) => {
      const updatedSteps = new Set(prevStepsChecked)

      if (updatedSteps.has(key)) {
        updatedSteps.delete(key)
      } else {
        updatedSteps.add(key)
      }

      return updatedSteps
    })

  }, [setStepsChecked])

  /** Handles the submission of links. */
  const handleSubmit = useCallback(() => {
    if (!links || Object.values(links).length === 0) return

    const arrLinks = Object.values(links).map((item) => item.link)

    if (!!assignmentData?.id && !!arrLinks.length && allowVirtualVisitLinksComments) {
      saveVirtualVisitLinks.mutate({
        assignmentId: assignmentData.id,
        payload: allowVirtualVisitLinksComments ?
          {
            virtualVisitUrls: arrLinks,
            creativeCommentForBackbone: comments
          }
          : arrLinks
      })
    }
  }, [allowVirtualVisitLinksComments, assignmentData?.id, comments, links, saveVirtualVisitLinks])

  // INITIAL STATE
  useEffect(() => {
    // Set default links
    if (!!virtualVisitLinks) {
      const defaultLinks = virtualVisitLinks.reduce((items: LinkItem, currentLink: string) => {
        items[`link-${_generateLinkId()}`] = { link: currentLink }
        return items
      }, {})
      setLinks(defaultLinks)
    }
    else {
      setLinks(_generateDefaultLink())
    }
    // Set default comments
    if (assignmentData && (AssignmentDTOIsCreativeDTO(assignmentData) || AssignmentDTOIsAdministratorDTO(assignmentData))) {
      setComments(assignmentData?.creativeCommentsForAdmin ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // PURGE ALL ON DESTROY
  useEffect(() => {
    return () => {
      saveVirtualVisitLinks.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    links,
    status,
    comments,
    stepsChecked,
    virtualVisitLinks,
    saveVirtualVisitLinks,
    setComments,
    handleSubmit,
    onAddLink,
    onLinkChange,
    onRemoveLink,
    onChangeCheckbox,
  }
})
