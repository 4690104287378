import { DotDivider } from 'components/common/DotDivider'
import { GalleryInfoCard } from '../GalleryInfoCard/GalleryInfoCard.component'
import { MUIButton } from 'components/common/MUIButton'
import { OrderDetailAssignmentItem } from '../OrderDetailAssignmentItem'
import Stack from '@mui/material/Stack'
import styles from './ClientOrderDetail.module.sass'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useTranslation } from 'react-i18next'
import { OrderCreator } from 'components/pages/Client/ClientGallery/common/OrderCreator/OrderCreator.component'

/**
 * @interface Props Input properties
 */
interface Props {
  /** OnClick action to open the order detail drawer */
  onOpenDrawer: () => void
}

/**
 * Gallery client order detail component
 * @example <ClientOrderDetail></ClientOrderDetail>
 */
export const ClientOrderDetail: React.FC<Props> = ({ onOpenDrawer }) => {
  const { t } = useTranslation(['gallery'])
  const { dealData } = useGalleryDeal()

  return (
    <GalleryInfoCard alignItems='left'>
      <Stack className={styles.orderDetail}>

        {/** GENERAL ORDER INFO */}
        <Stack direction={{ xs: 'column', sm: 'row' }} paddingBottom={2} alignItems={{ xs: 'left', sm: 'center' }} justifyContent="space-between">
          <Stack paddingBottom={{ xs: '1rem', sm: 0 }}>
            {/** ORDER ID */}
            <Stack direction="row">
              <span className={styles.title}>{t('order_informations.order')}</span>
              <DotDivider />
              {dealData && <span className={styles.title}>{`#${dealData.id}`}</span>}
            </Stack>

            {/** ORDER CREATOR */}
            <OrderCreator />
          </Stack>

          <Stack>
            <MUIButton type='secondaryBorder' onClick={onOpenDrawer} variant='outlined'>{t('order_informations.see_order_details')}</MUIButton>
          </Stack>
        </Stack>

        {/** ASSIGNMENTS ITEMS */}
        <Stack gap="1.6rem" direction="column-reverse">
          {dealData?.missions.map((assignment) =>
            <OrderDetailAssignmentItem key={`assignment-${assignment.id}`} assignment={assignment} />
          )}
        </Stack>
      </Stack>
    </GalleryInfoCard>
  )
}
