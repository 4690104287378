import { AssignmentAutomationStates, AutomateOrderAssignmentState, AutomateOrderCardStatus, useAutomateOrderCard } from './AutomateOrderCard.context'
import { AssignmentAutomationStatus, CardColor } from 'constants/assignment'
import { FC, Fragment, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { automateOrder, purgeAutomateOrder } from 'redux/Individual/Order/AutomateOrder/automateOrder.actions'
import { purgeStopAutomatedOrder, stopAutomatedOrder } from 'redux/Individual/Order/StopAutomatedOrder/stopAutomatedOrder.actions'

import Button from 'components/common/Button/Button'
import CloseIcon from '@mui/icons-material/Close'
import { DealDTOIsAdministratorDTO } from 'utils/typeguards'
import { OrderAutomationStatus } from 'models/deal'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { ProductKind } from 'constants/product'
import { RequestStatus } from 'components/common/RequestStatus'
import StopIcon from '@mui/icons-material/Stop'
import classnames from 'classnames'
import { groupBy } from 'lodash'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './AutomateOrderCard.module.sass'
import { useActionPopup } from 'utils/hooks'
import { useDispatch } from 'react-redux'
import { useGalleryDeal } from '../_main/contexts/GalleryDeal.context'
import { useQueryClient } from '@tanstack/react-query'
import { useUserData } from 'components/contexts/UserDataContext'

const hideStartAutomationButtonStages = new Set([
  AutomateOrderCardStatus.CT_DELIVERED_ORDER,
  AutomateOrderCardStatus.AUTOMATION_IN_PROCESS,
  OrderAutomationStatus.AUTOMATED,
  OrderAutomationStatus.MANUAL,
])

export const AutomateOrderCardController: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['gallery', 'order_automation', 'order_automation_prompt'])
  const { showConfirm } = useActionPopup()
  const queryClient = useQueryClient()
  const { dealData, dealId } = useGalleryDeal()
  const { adminData } = useUserData()
  const {
    assignments,
    automateOrderResponse,
    currentAssignmentId,
    orderAutomationState,
    showAutomateOrder,
    stopAutomatedOrderResponse,
  } = useAutomateOrderCard()

  const assignmentsByState = useMemo(() => {
    return groupBy(assignments, 'state')
  }, [assignments])

  const cardColor: CardColor = useMemo(() => {
    switch (orderAutomationState) {
      case AutomateOrderCardStatus.CT_DELIVERED_ORDER:
        return CardColor.GREEN
      case OrderAutomationStatus.AUTOMATED:
      case OrderAutomationStatus.AUTOMATION_READY:
      case AutomateOrderCardStatus.AUTOMATION_IN_PROCESS:
        return CardColor.BLUE
      case AutomateOrderCardStatus.ASSIGNMENT_CONFLICTS:
      case OrderAutomationStatus.AUTOMATION_OUT_OF_SCOPE:
        return CardColor.RED
      case OrderAutomationStatus.MANUAL:
        return CardColor.ORANGE
      default:
        return CardColor.BLUE
    }
  }, [orderAutomationState])

  const getTagColor = useCallback(
    (state: AssignmentAutomationStates): CardColor => {
      switch (state) {
        case AutomateOrderAssignmentState.DONE:
          return CardColor.GREEN
        case AssignmentAutomationStatus.AUTOMATED:
        case AssignmentAutomationStatus.AUTOMATION_AS_PART_OF_ORDER:
        case AssignmentAutomationStatus.AUTOMATION_READY:
        case AutomateOrderAssignmentState.CT_ACCEPTED:
          return CardColor.BLUE
        case AutomateOrderAssignmentState.CONFLICTS:
        case AssignmentAutomationStatus.AUTOMATION_OUT_OF_SCOPE:
          return CardColor.RED
        case AssignmentAutomationStatus.MANUAL:
          return CardColor.ORANGE
        default:
          return CardColor.BLUE
      }
    }, [])

  const handleStartAutomation = useCallback(async () => {
    if (!dealData || !DealDTOIsAdministratorDTO(dealData)) return

    const confirmResponse = await showConfirm(
      <Trans parent="p" t={t} i18nKey={'order_automation_prompt:description'}>&nbsp;</Trans>,
      {
        title: t('order_automation_prompt:title'),
        confirmAcceptText: t('order_automation_prompt:proceed')
      }
    )

    if (!confirmResponse) return

    dispatch(automateOrder(dealId, currentAssignmentId, queryClient))
  }, [currentAssignmentId, dealData, dealId, dispatch, queryClient, showConfirm, t])

  const handleStopAutomation = useCallback(async () => {
    if (!dealData || !DealDTOIsAdministratorDTO(dealData)) return

    const confirmResponse = await showConfirm(
      <Trans parent="p" t={t} i18nKey={'order_automation_prompt:stop_automation_description'}>&nbsp;</Trans>,
      {
        title: t('order_automation_prompt:stop_automation_title'),
        confirmAcceptText: t('order_automation_prompt:proceed')
      }
    )

    if (!confirmResponse) return

    dispatch(stopAutomatedOrder(dealId, currentAssignmentId, queryClient))
  }, [currentAssignmentId, dealData, dealId, dispatch, queryClient, showConfirm, t])

  const handleIgnoreAutomation = useCallback(async () => {
    if (!dealData || !DealDTOIsAdministratorDTO(dealData)) return

    const confirmResponse = await showConfirm(
      <Trans parent="p" t={t} i18nKey={'order_automation_prompt:ignore_automation_description'}>&nbsp;</Trans>,
      {
        title: t('order_automation_prompt:ignore_automation_title'),
        confirmAcceptText: t('order_automation_prompt:proceed')
      }
    )

    if (!confirmResponse) return

    const assignmentIds = assignments?.map(assignment => assignment.id)
    const productKinds: ProductKind[] | undefined = assignments?.reduce(
      (acc: ProductKind[], assignment) => {
        const kinds = assignment.products.map(product => product.kind)
        if (kinds.length) acc.push(...kinds)
        return acc
      }, [])

    logAnalyticsEvent(
      'order_automation_ignored',
      {
        orderId: dealId,
        assignmentIds,
        productKinds,
        adminId: adminData?.id
      }
    )
    // This will change the order automation status to MANUAL
    dispatch(stopAutomatedOrder(dealId, currentAssignmentId, queryClient))
  }, [adminData?.id, assignments, currentAssignmentId, dealData, dealId, dispatch, queryClient, showConfirm, t])

  if (!showAutomateOrder) return <Fragment />

  return (
    <div className={classnames(styles[cardColor], styles.orderAutomationWrapper)}>

      {/** Header & description of the current status to automate entire order */}
      <div className={styles.statusDetails}>
        <div>
          <span className={styles.smallHeader}>{t('order_automation:title')}</span>
          <h3>{t(`order_automation:order_state:${orderAutomationState}`)}</h3>
        </div>
        <div className={styles.verticalDivider} />
      </div>


      {/** State of each assignment */}
      <div className={styles.assignmentsWrapper}>
        {Object.keys(assignmentsByState).map((stateKey) => (
          <div key={stateKey} className={classnames(
            styles.contentWrapper,
            styles[`${getTagColor(stateKey as AssignmentAutomationStates)}Border`]
          )}>
            <span className={styles.header}>{t(`order_automation:assignment_state:${stateKey}`)}</span>

            <div className={styles.grid}>
              {assignmentsByState[stateKey].map((assignment) => (
                <div
                  key={`tag-${assignment.id}`}
                  className={classnames(
                    styles.idTag,
                    styles[`${getTagColor(assignment.state)}Background`]
                  )}
                >
                  {assignment.id}
                </div>
              ))}
            </div>

          </div>
        ))}

      </div>

      {/** Buttons section */}
      <div className={styles.buttonsWrapper}>
        {/** IGNORE BUTTON */}
        {orderAutomationState === OrderAutomationStatus.AUTOMATION_READY &&
          <Button
            textAndIcon={true}
            className={styles.secondary}
            onClick={() => handleIgnoreAutomation()}
          >
            <CloseIcon fontSize='large' />
            <span>{t('order_automation:button_ignore')}</span>
          </Button>
        }

        {/** START AUTOMATION BUTTON */}
        {!hideStartAutomationButtonStages.has(orderAutomationState) &&
          <Button
            textAndIcon={true}
            className={styles.primary}
            disabled={orderAutomationState !== OrderAutomationStatus.AUTOMATION_READY}
            onClick={() => handleStartAutomation()}
          >
            <PlayArrowIcon fontSize='large' />
            <span>{t('order_automation:button_start_automation')}</span>
          </Button>
        }

        {/** STOP AUTOMATION BUTTON */}
        {(orderAutomationState === AutomateOrderCardStatus.AUTOMATION_IN_PROCESS
          && assignments?.every(assignment => assignment.state !== AutomateOrderAssignmentState.CT_ACCEPTED)
        ) &&
          <Button
            textAndIcon={true}
            className={styles.primary}
            onClick={() => handleStopAutomation()}
          >
            <StopIcon fontSize='large' />
            <span>{t('order_automation:button_stop_automation')}</span>
          </Button>
        }
      </div>

      {automateOrderResponse &&
        <RequestStatus
          spaceTopRem={2}
          className={styles.requestStatusGrid}
          successMessage={t('success')}
          request={automateOrderResponse}
          onPurge={() => dispatch(purgeAutomateOrder(dealId))}
        />
      }

      {stopAutomatedOrderResponse &&
        <RequestStatus
          spaceTopRem={2}
          className={styles.requestStatusGrid}
          successMessage={t('success')}
          request={stopAutomatedOrderResponse}
          onPurge={() => dispatch(purgeStopAutomatedOrder(dealId))}
        />
      }

    </div >
  )
}
