import { Fade, Typography } from '@mui/material'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { GalleryStagingStep, useGalleryStagingFlow, useGalleryStagingVisualSelection } from './context'
import React, { useMemo } from 'react'

import { APIRequestState } from 'constants/API'
import { MUIButton } from 'components/common/MUIButton'
import Stack from '@mui/material/Stack'
import { useRenovationProducts } from 'components/common/StagingFlow/main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @component Footer component for Staging Flow containing navigation buttons.
 * @example <GalleryStagingFlowFooter />
 */
export const GalleryStagingFlowFooter: React.FC = () => {

  const { t } = useTranslation(['actions', 'gallery_staging_flow'])

  const {
    isLastStep,
    activeStep,
    stepValidityMap,
    currentStepIndex,
    roomsWithRenovationProducts,
    requiredSelectionCount,
    stagingRequestState,
    setIsAdvanceStagingPurchaseModalOpen,
    goBack,
    goNext,
    onStageVisuals,
  } = useGalleryStagingFlow()
  const { renovationProductStringPrice } = useRenovationProducts()
  const { selectedVisualCount } = useGalleryStagingVisualSelection()

  const hasRenovations = useMemo(() => roomsWithRenovationProducts?.size > 0, [roomsWithRenovationProducts])
  const isStagingPlaced = useMemo(() => stagingRequestState !== APIRequestState.BEFORE_START, [stagingRequestState])

  const handleFinishStagingFlow = () => {
    if (hasRenovations) {
      setIsAdvanceStagingPurchaseModalOpen(true)
    } else {
      onStageVisuals()
    }
  }

  return (
    <Stack
      gap={2}
      height="100%"
      marginRight={4}
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-end"
    >

      <Fade in={hasRenovations && !isStagingPlaced}>
        <Typography variant='text-sm' color={GRAY_700}>

          {t('gallery_staging_flow:advanced_staging')}

          <Typography variant='text-sm' fontWeight={600} color={GRAY_900}>
            {` + ${renovationProductStringPrice}`}

            {/* Show multiplier if more than one renovations where added */}
            {hasRenovations && roomsWithRenovationProducts.size > 1 &&
              ` (x${roomsWithRenovationProducts.size})`
            }
          </Typography>

        </Typography>
      </Fade>

      <Fade in={activeStep === GalleryStagingStep.VISUAL_SELECTION}>
        <Typography variant='text-sm' color={GRAY_900}>

          {selectedVisualCount}

          <Typography variant='text-sm' fontWeight={600}>
            {` / ${requiredSelectionCount}`}
          </Typography>

        </Typography>
      </Fade>

      <MUIButton
        type='secondaryBorder'
        variant='outlined'
        disabled={currentStepIndex === 0 || isStagingPlaced}
        onClick={goBack}
      >
        {t('Previous')}
      </MUIButton>

      <MUIButton
        onClick={!isLastStep ? goNext : handleFinishStagingFlow}
        disabled={!stepValidityMap[activeStep] || isStagingPlaced}
        isLoading={stagingRequestState === APIRequestState.RUNNING}
      >
        {!isLastStep ? t('Next') : t('Submit')}
      </MUIButton>

    </Stack>
  )
}
