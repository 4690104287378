import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'

import API from 'utils/API/API'
import { ActionGetVirtualVisitExtensionPrice } from '.'
import { put } from 'redux-saga/effects'

/** Saga which gets virtual visit extenstion price from API */
export function* getVirtualVisitExtensionPriceSaga(receivedAction: ActionGetVirtualVisitExtensionPrice) {
  const { missionId } = receivedAction.payload

  const url = Endpoint.VISUAL_GET_VIRTUAL_VISIT_EXTENSION_PRICE.replace('{assignmentId}', missionId.toString())
  const action: ActionGetVirtualVisitExtensionPrice = yield generalFetch(ActionTypeAPIData.GET_VIRTUAL_VISIT_EXTENSION_PRICE, () => API.get(url, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    }
  }, {
    endpoint: Endpoint.VISUAL_GET_VIRTUAL_VISIT_EXTENSION_PRICE
  }))

  const getVirtualVisitExtensionPriceAction: ActionGetVirtualVisitExtensionPrice = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(getVirtualVisitExtensionPriceAction)
}

/** Watcher of get virtual visit extension price */
export function* getVirtualVisitExtensionPriceWatcher() {
  yield all([
    takeEvery((action: ActionGetVirtualVisitExtensionPrice) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_VIRTUAL_VISIT_EXTENSION_PRICE]), getVirtualVisitExtensionPriceSaga)
  ])
}
