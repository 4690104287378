// Not accurate, just for purpose of ts-check
interface BeckaService {
  settings: {
    language: string
  }
  init: (token: string, name: string, url: string, options: object) => void
}

declare global {
  interface Window {
    embeddedservice_bootstrap: BeckaService
  }
}

const BECKA_ID = process.env.REACT_APP_BECKA_ID
const BECKA_NAME = process.env.REACT_APP_BECKA_NAME
const BECKA_URL = process.env.REACT_APP_BECKA_URL
const BECKA_SCRT_URL = process.env.REACT_APP_BECKA_SCRT_URL

export function initBecka(isoLanguage: string = 'en_US') {
  if (!BECKA_ID) throw new Error('BECKA_ID is undefined')
  if (!BECKA_NAME) throw new Error('BECKA_NAME is undefined')
  if (!BECKA_URL) throw new Error('BECKA_URL is undefined')
  if (!BECKA_SCRT_URL) throw new Error('BECKA_SCRT_URL is undefined')

  try {
    window.embeddedservice_bootstrap.settings.language = isoLanguage // For example, enter 'en' or 'en-US'
    window.embeddedservice_bootstrap.init(
      BECKA_ID,
      BECKA_NAME,
      BECKA_URL,
      {
        scrt2URL: BECKA_SCRT_URL,
      }
    )
    return true
  } catch (err) {
    console.error('Error loading Becka: ', err)
    return false
  }
}
