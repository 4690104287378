import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Link, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { MUIButton } from 'components/common/MUIButton'
import { OrderAssignmentStatusFilter } from 'constants/misc'
import { Path } from 'constants/router'
import Stack from '@mui/material/Stack'
import { createSearchParams } from 'react-router-dom'
import { getTermsLink } from 'utils/localization'
import i18n from 'translations/i18n'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { usePurchaseFlowOrderMeta } from './contexts'
import { usePurchaseFlowPlaceOrder } from '../steps/Validation/_main/contexts'
import { usePurchaseFlowUtilities } from './contexts/PurchaseFlowUtilities.context'

/**
 * @component Footer component for Purchase Flow containing navigation buttons
 * @example <PurchaseFlowFooter />
 */
export const PurchaseFlowFooter: React.FC = () => {
  const { t } = useTranslation(['actions', 'order'])
  const { exitPurchaseFlow } = usePurchaseFlowUtilities()
  const { isOrderOrStripeInProgress, onPlaceOrder, orderHasBeenPlaced } = usePurchaseFlowPlaceOrder()
  const { isStripePaymentMethod } = usePurchaseFlowOrderMeta()

  const flowCtrl = useDynamicFlowController()

  const isLastStep = useMemo(() => !flowCtrl.currentNode?.next, [flowCtrl.currentNode?.next])

  const termsLink = useMemo(() => getTermsLink(i18n.language), [])
  const isLoading = useMemo(() => isLastStep ? isOrderOrStripeInProgress : false, [isLastStep, isOrderOrStripeInProgress])

  const onNextClick = useCallback(() => {
    if (isLastStep) {
      onPlaceOrder()
      return
    }

    const currentStep = flowCtrl.currentNode
    if (!currentStep?.next) return

    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_CLICK_NEXT_STEP, {
      CurrentStep: currentStep.key,
      NewStep: currentStep.next
    })

    flowCtrl.setHead(currentStep.next)

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCtrl.currentNode?.next, isLastStep, onPlaceOrder])

  const onBackClick = useCallback(() => {
    const currentStep = flowCtrl.currentNode

    if (!currentStep?.prev) return

    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_CLICK_PREVIOUS_STEP, {
      CurrentStep: currentStep.key,
      NewStep: currentStep.prev
    })

    flowCtrl.setHead(currentStep.prev)

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCtrl.currentNode?.prev, flowCtrl.head])

  useEffect(() => {
    // Go back to Dashboard after getting the order status message
    if (orderHasBeenPlaced && !isStripePaymentMethod) {
      exitPurchaseFlow(
        false,
        {
          pathname: Path.INDEX,
          search: (createSearchParams({ status: OrderAssignmentStatusFilter.ONGOING }).toString()),
        }
      )
    }
  }, [exitPurchaseFlow, isStripePaymentMethod, orderHasBeenPlaced])

  return (
    <Stack
      gap={2}
      height="100%"
      marginRight={4}
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-end"
    >

      {/* Terms & Conditions for the last step */}
      {isLastStep &&
        <Stack>
          <Typography variant='text-sm' color={GRAY_700}>
            <Trans t={t} i18nKey="order:step_validation:agree_tos">
              <Link
                href={termsLink}
                rel="noopener noreferrer"
                target="_blank"
                sx={{ color: GRAY_900, textDecorationColor: GRAY_900 }}
              />
            </Trans>
          </Typography>
        </Stack>
      }

      <MUIButton type='secondaryBorder' variant='outlined' onClick={onBackClick} disabled={isLoading}>
        {t('Previous')}
      </MUIButton>

      <MUIButton
        onClick={onNextClick}
        disabled={flowCtrl.head ? !flowCtrl.validityMap.get(flowCtrl.head) : false}
        isLoading={isLoading}
      >
        {!isLastStep ? t('Next') : t('order:step_validation:agree_and_order')}
      </MUIButton>

    </Stack>
  )
}
