import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Trans, useTranslation } from 'react-i18next'

import { CatalogueProduct } from 'dataQueries/purchase.query'
import { CounterInput } from 'components/common/CounterInput'
import { Currency } from 'constants/misc'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import { ProductCategory } from 'models/product'
import { ProductKind } from 'constants/product'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { formatPrice } from 'utils/price'
import { getProductDetailsPage } from 'utils/localization/productDetails'
import { isEditingCategory } from 'utils/validators'
import { useMemo } from 'react'

/** @interface Props for the ProductCardHeader component. */
interface Props {
  /** Product whose info is to be displayed */
  product: CatalogueProduct
  /** Product category */
  category: ProductCategory
  /** Currency for price formatting */
  currency: Currency
  /** Whether the header should appear disabled */
  disabled: boolean
  /** Type key of product (for non-editing products) */
  typeKey?: string
  /** Whether to show the price of the product or not. */
  displayPrice?: boolean
  /** Quantity of the selected product. */
  quantity?: number
}

/** 
 * @component Shows styled header with product name, description and price. Made to be used in a card.
 * @example
 * <EditingProductCardHeader
 *  product={productObject}
 *  category={productCategory}
 *  disabled={false}
 * />
*/
export const ProductCardHeader: React.FC<Props> = ({
  product,
  category,
  typeKey,
  currency,
  disabled = false,
  displayPrice = true,
  quantity,
}) => {
  const { t, i18n } = useTranslation(['order', 'product', 'product_description'])

  // Whether the product has discount, is kind BUNDLE and is different from original price
  const showDiscountedPrice = useMemo(() => {
    if (product.kind !== ProductKind.BUNDLE) return false
    if (!product.originalPrice) return false // edge case - not striclty mandatory to display bundle products with discounts (for special products only)
    const discountedPrice = formatPrice(product.feePrice, currency)
    const originalPrice = formatPrice(product.originalPrice, currency)
    return discountedPrice !== originalPrice
  }, [currency, product.feePrice, product.kind, product.originalPrice])

  const getDescription = (key: string) => i18n.exists(key) ? <Trans t={t} i18nKey={key} /> : null

  const getComparedDescription = (keys: string[]) => {
    const descriptionKey = keys.find(key => i18n.exists(key))
    return descriptionKey && <Trans t={t} i18nKey={descriptionKey} />
  }

  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ opacity: disabled ? 0.5 : 1 }}
    >

      <Stack>

        <Stack gap={2} direction="row" alignItems="center">

          {/** PRODUCT QUANTITY */}
          {quantity && quantity > 0 && (
            <CounterInput
              value={quantity}
              hideControls
              disabled
            />
          )}

          <Stack>

            <Stack flexDirection="row" alignItems="center" gap=".5rem">
              {/** PRODUCT NAME */}
              <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
                {t(`product:p_${product.id}`)}
              </Typography>

              {getProductDetailsPage(i18n.language, product.kind, product.type) &&
                <a
                  href={getProductDetailsPage(i18n.language, product.kind, product.type) ?? ''}
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  onClick={(e) => {
                    logAnalyticsEvent(AnalyticsEvent.PROFOLIO_OPENED, { ProductKind: product.kind })
                    e.stopPropagation()
                  }}
                >
                  <HelpOutlineRoundedIcon sx={{ color: GRAY_900, paddingTop: '.3rem' }} />
                </a>
              }
            </Stack>

            {/** PRODUCT DESCRIPTION (Editing category) */}
            {isEditingCategory(category) &&
              <Typography variant='text-sm'>
                {getComparedDescription([
                  `product_description:pd_${product.id}`,
                  `product_kind_description:${product.kind}`
                ])}
                {getDescription(`step_product.category_${category}_type_${product.kind}`)}
              </Typography>
            }

          </Stack>

        </Stack>

        {/** PRODUCT DESCRIPTION (Shooting category) */}
        {!isEditingCategory(category) && !!typeKey &&
          <Typography variant='text-sm'>
            {getComparedDescription([
              `product_description:pd_${product.id}`,
              `product_kind_description:${product.kind}`
            ])}
            {getDescription(`product_type_description:${typeKey}`)}
            {getDescription(`step_product.category_${category}_type_${typeKey}`)}
          </Typography>
        }
      </Stack>

      {/** PRODUCT PRICE */}
      {displayPrice &&
        <Stack justifyContent="center" textAlign="end">

          {/** DISCOUNTED PRICE (only bundle products) */}
          {showDiscountedPrice &&
            <Typography variant='text-sm' fontWeight={600} color={GRAY_900}>
              {formatPrice(product.feePrice, currency)}
            </Typography>
          }

          {/** ORIGINAL OR DISCOUNTED PRICE */}
          <Typography
            variant='text-sm'
            fontWeight={600}
            color={showDiscountedPrice ? GRAY_700 : GRAY_900}
            sx={{ textDecoration: showDiscountedPrice ? 'line-through' : 'inherit' }}
          >
            {product.originalPrice
              ? formatPrice(product.originalPrice, currency)
              : formatPrice(product.feePrice, currency)
            }
          </Typography>

        </Stack>
      }

    </Stack>
  )
}
