// Links to product details page based on product kind
export enum GroundLinks {
  EN = 'https://bkbn.com/en/real-estate-ground-photo',
  FR = 'https://bkbn.com/fr/photo-immobiliere-prise-au-sol',
  DE = 'https://bkbn.com/de/immobilien-bodenfoto',
}

export enum DroneLinks {
  EN = 'https://bkbn.com/en/real-estate-drone-photo',
  FR = 'https://bkbn.com/fr/photo-immobiliere-drone',
  DE = 'https://bkbn.com/de/immobilien-drohnenfoto',
}

export enum PrestigeLinks {
  EN = 'https://bkbn.com/en/real-estate-prestige-photo',
  FR = 'https://bkbn.com/fr/photo-immobiliere-prestige',
  DE = 'https://bkbn.com/de/immobilien-prestigefoto',
}

export enum GroundVideoLinks {
  EN = 'https://bkbn.com/en/real-estate-ground-video',
  FR = 'https://bkbn.com/fr/video-immobiliere-au-sol',
  DE = 'https://bkbn.com/de/immobilien-boden-video',
}

export enum GroundDroneVideoLinks {
  EN = 'https://bkbn.com/en/real-estate-ground-and-drone-video',
  FR = 'https://bkbn.com/fr/video-immobiliere-sol-et-drone',
  DE = 'https://bkbn.com/de/immobilien-video-boden-und-drohne',
}

export enum DroneVideoLinks {
  EN = 'https://bkbn.com/en/real-estate-drone-video',
  FR = 'https://bkbn.com/fr/video-immobiliere-drone',
  DE = 'https://bkbn.com/de/immobilien-drohnen-video',
}

export enum TeaserVideoLinks {
  EN = 'https://bkbn.com/en/real-estate-teaser-video',
  FR = 'https://bkbn.com/fr/video-immobiliere-teaser',
  DE = 'https://bkbn.com/de/immobilien-teaser-video',
}

export enum PrestigeVideoLinks {
  EN = 'https://bkbn.com/en/real-estate-prestige-video',
  FR = 'https://bkbn.com/fr/video-immobiliere-prestige',
  DE = 'https://bkbn.com/de/immobilien-prestige-video',
}

export enum PhotoExtractionLinks {
  EN = 'https://bkbn.com/en/real-estate-photo-extraction',
  FR = 'https://bkbn.com/fr/extraction-photo-immobiliere',
  DE = 'https://bkbn.com/de/immobilienfoto-extraktion',
}

export enum HightDefinitionTourLinks {
  EN = 'https://bkbn.com/en/real-estate-high-definition-tour',
  FR = 'https://bkbn.com/fr/visite-virtuelle-haute-definition-immobiliere',
  DE = 'https://bkbn.com/de/immobilien-high-definition-tour',
}

export enum StandardStagingLinks {
  EN = 'https://bkbn.com/en/real-estate-standard-staging',
  FR = 'https://bkbn.com/fr/staging-virtuel-standard-immobilier',
  DE = 'https://bkbn.com/de/immobilien-standard-staging',
}

export enum Staging360Links {
  EN = 'https://bkbn.com/en/real-estate-360-staging',
  FR = 'https://bkbn.com/fr/staging-360-immobilier',
  DE = 'https://bkbn.com/de/immobilien-360-staging',
}

export enum ResidentialRenderingLinks {
  EN = 'https://bkbn.com/en/real-estate-residential-rendering',
  FR = 'https://bkbn.com/fr/rendering-residentiel-immobilier',
  DE = 'https://bkbn.com/de/immobilien-wohnungsrendering',
}

export enum CommercialRenderingLinks {
  EN = 'https://bkbn.com/en/real-estate-commercial-rendering',
  FR = 'https://bkbn.com/fr/rendering-commercial-immobilier',
  DE = 'https://bkbn.com/de/immobilien-gewerberaum-rendering',
}

export enum Rendering360Links {
  EN = 'https://bkbn.com/en/real-estate-360-rendering',
  FR = 'https://bkbn.com/fr/rendering-360-immobilier',
  DE = 'https://bkbn.com/de/immobilien-360-rendering',
}

export enum SlideshowLinks {
  EN = 'https://bkbn.com/en/real-estate-slideshow',
}

export enum BasicFloorPlanLinks {
  EN = 'https://bkbn.com/en/real-estate-basic-floor-plan',
  FR = 'https://bkbn.com/fr/plan-de-sol-basique-immobilier',
  DE = 'https://bkbn.com/de/immobilien-basis-grundriss',
}

export enum MatterportFloorPlanLinks {
  EN = 'https://bkbn.com/en/real-estate-matterport-floor',
  FR = 'https://bkbn.com/fr/plan-de-sol-matterport-immobilier',
  DE = 'https://bkbn.com/de/immobilien-matterport-grundrisse',
}

export enum FloorPlan3DLinks {
  EN = 'https://bkbn.com/en/real-estate-3d-floor-plan',
  FR = 'https://bkbn.com/fr/plan-de-sol-3d-immobilier',
  DE = 'https://bkbn.com/de/immobilien-3d-grundriss',
}
