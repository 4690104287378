/** Categories of shooting orders */
export enum ShootingCategory {
  REAL_ESTATE = 'REAL_ESTATE',
  EVENT = 'EVENT',
  CORPORATE = 'CORPORATE',
  FOOD = 'FOOD',
  PRODUCT = 'PRODUCT',
}

/** Categories of editing orders */
export enum EditingCategory {
  EDITING = 'EDITING',
  STAGING = 'STAGING',
}

/** Categories of document procurement products */
export enum DocumentCategory {
  AUTHORITIES_DOCUMENTS = 'AUTHORITIES_DOCUMENTS',
}
