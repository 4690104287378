import { UserAsMemberUsingEmailDTO, WorkspaceAddMembersResultDTO } from 'models/workspaces'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { WorkspaceRole } from 'models/user'
import { useAPI } from 'utils/API/useApi'

enum Endpoints {
  CHANGE_WORKSPACE_MEMBER_ROLE = '/workspace/{workspaceId}/changeMemberRole',
  REMOVE_WORKSPACE_MEMBER = '/workspace/{workspaceId}/member',
  RESEND_WORKSPACE_INVITATION = '/workspace/{workspaceId}/resend-invitation/{invitationId}', // under FF allowPendingInvitations  
  WORKSPACE_INVITATION = '/workspace/{workspaceId}/invitation/{invitationId}', // under FF allowPendingInvitations
  ADD_WORKSPACE_MEMBERS = '/workspace/{workspaceId}/members'
}

interface WorkspaceMemberPayload {
  workspaceId: string
  email: string
}

interface InvitationPayload {
  workspaceId: string
  invitationId: string
}

interface ChangeWorkspaceMemberRolePayload {
  auth0Id: string,
  role: WorkspaceRole,
  isRestricted: boolean,
  workspaceId: string
}

// QUERIES


// MUTATIONS

export function useAddWorkspaceMembers() {
  const api = useAPI<Endpoints>()

  return getMutation<WorkspaceAddMembersResultDTO, { workspaceId: string, members: UserAsMemberUsingEmailDTO[] }>(
    ({ workspaceId, members }) => api.post(
      Endpoints.ADD_WORKSPACE_MEMBERS,
      { workspaceId },
      [...members],
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

export function useRemoveWorkspaceMember() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, WorkspaceMemberPayload & { inSubscriptionFlow?: boolean }>(
    ({ workspaceId, email, inSubscriptionFlow = false }) => api.delete(
      Endpoints.REMOVE_WORKSPACE_MEMBER,
      { workspaceId },
      false,
      {
        data: {
          email,
          inSubscriptionFlow,
        }
      }
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

/**
 * under FF allowPendingInvitations
 */
export function useRevokeWorkspaceInvitation() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, InvitationPayload>(
    ({ workspaceId, invitationId }) => api.delete(
      Endpoints.WORKSPACE_INVITATION,
      { workspaceId, invitationId },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

export function useChangeWorkspaceMemberRole() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, ChangeWorkspaceMemberRolePayload>(
    ({ workspaceId, auth0Id, role, isRestricted }) => api.put(
      Endpoints.CHANGE_WORKSPACE_MEMBER_ROLE,
      { workspaceId },
      {
        auth0Id,
        role,
        isRestricted
      },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

/**
 * under FF allowPendingInvitations
 */
export function useResendWorkspaceInvitation() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, InvitationPayload>(
    ({ workspaceId, invitationId }) => api.post(
      Endpoints.RESEND_WORKSPACE_INVITATION,
      { workspaceId, invitationId },
      {},
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

/**
 * under FF allowPendingInvitations
 */
export function useAcceptWorkspaceInvitation() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, InvitationPayload>(
    ({ workspaceId, invitationId }) => api.post(
      Endpoints.WORKSPACE_INVITATION,
      { workspaceId, invitationId },
      {},
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}
