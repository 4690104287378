import { useCallback, useMemo, useState } from 'react'

import { useGalleryConstants } from 'components/pages/Gallery/_main/contexts/GalleryConstants.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import constate from 'constate'
import { RootStore } from 'models/redux'
import { useSelector } from 'react-redux'
import { useGalleryStagingFlow } from './GalleryStagingFlow.context'

export const [GalleryStagingFlowListContextProvider, useGalleryStagingFlowList] = constate(() => {

  const { stagingAssignments } = useGalleryDeal()
  const { stagingAssignmentId, assignmentsToStage, setAssignmentsToStage } = useGalleryStagingFlow()
  const { stagesUnlockingStagingFlow } = useGalleryConstants()

  const stagingRequest = useSelector((state: RootStore) => stagingAssignmentId
    ? state.APIData[ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING][stagingAssignmentId]
    : undefined
  )

  const stagingRequestState = useMemo(() => {
    if (stagingRequest) {
      const stagingState = stagingRequest.state
      if (stagingState) return stagingState
      return APIRequestState.BEFORE_START
    }
    else return APIRequestState.BEFORE_START
  }, [stagingRequest])

  const [finishedStagings, setFinishedStagings] = useState<Set<string>>(new Set([]))
  const [skipStaging, setSkipStaging] = useState(false)

  const hasStaging = useMemo(() => assignmentsToStage.length > 0, [assignmentsToStage.length])
  const isStagingFinished = useMemo(() => skipStaging || assignmentsToStage.length === finishedStagings.size, [assignmentsToStage.length, finishedStagings.size, skipStaging])
  const remainingStagingsCount = useMemo(() => assignmentsToStage.length - finishedStagings.size, [assignmentsToStage.length, finishedStagings.size])

  /** Initialize context with filtered assignments ready for staging */
  const initializeStagingListing = useCallback(() => {
    const assignmentsToSet = stagingAssignments.filter((assignment) => stagesUnlockingStagingFlow.has(assignment.stage))
    setAssignmentsToStage(assignmentsToSet)
  }, [setAssignmentsToStage, stagesUnlockingStagingFlow, stagingAssignments])

  /** Resets staging data */
  const resetStaging = () => {
    setAssignmentsToStage([])
    setFinishedStagings(new Set([]))
    setSkipStaging(false)
  }

  return {
    hasStaging,
    isStagingFinished,
    stagingRequest,
    stagingRequestState,
    finishedStagings,
    assignmentsToStage,
    remainingStagingsCount,
    skipStaging,
    setFinishedStagings,
    initializeStagingListing,
    resetStaging,
    setSkipStaging,
  }

})
