import React, { useState } from 'react'

import { AnalyticsEvent } from 'utils/analytics'
import { ClientGalleryOrderDetailDrawer } from '../ClientGalleryOrderDetailDrawer'
import { ClientGalleryPriceSummaryCard } from '../ClientGalleryPriceSummaryCard'
import { ClientOrderDetail } from 'components/common/Gallery/ClientOrderDetail'
import Grid from '@mui/material/Grid'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'

/**
 * @component Client Gallery Order Information tab content
 * @example <ClientGalleryOrderInformation />
 */
export const ClientGalleryOrderInformation: React.FC = () => {
  const { logGalleryEvent } = useGalleryAssignment()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true)

    logGalleryEvent(AnalyticsEvent.CLICK_ORDER_DETAILS)
  }

  return (
    <Grid container spacing={2.5} alignItems="start" direction={{ xs: 'column', sm: 'row' }} paddingTop="2.3rem">

      <Grid item xs={12} md={4} width="100%">
        <ClientGalleryPriceSummaryCard />
      </Grid>

      <Grid item xs={12} md={8} width="100%">
        <ClientOrderDetail onOpenDrawer={handleOpenDrawer} />
      </Grid>

      <ClientGalleryOrderDetailDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />

    </Grid>
  )
}
