import { Country } from 'constants/country'
import { Language } from 'translations/Language'
import { Nullable } from 'models/helpers'
import { OrganizationLight } from 'models/organization'
import { PreferredPaymentMethodType } from './preferredPaymentMethod'
import { WorkspaceUserProfile } from './userWorkspaceDTOs'

/** Types of clients */
export enum ClientType {
  B2B = 'B2B',
  B2C = 'B2C',
}

/** Platform user interface */
export type PlatformUser = PlatformUserBase | PlatformUserClient | PlatformUserCreative

/** Platform user interface for client and admin */
export interface PlatformUserClient extends PlatformUserBase, PlatformUserCreative {
  preferredPaymentMethod: PreferredPaymentMethodType,
  stripeCustomerId?: string
  billingEmail?: string
  pipedriveId?: number
  clientType: ClientType
  workspaces: WorkspaceUserProfile[]
  // B2B client fields
  organizationId?: string
  personalOrderReference?: string
  userGroupIdentifier?: string
  // B2C client fields
  vatNumber?: string
  address?: string
  /** First time the user logged in */
  firstLoginDateTime: string
}

/** Interface describing creative user of the platform  */
export interface PlatformUserCreative extends PlatformUserBase {
  enabled: boolean
}

/** Platform user base interface */
export interface PlatformUserBase {
  id?: string
  name?: string
  email?: string
  phone?: string
  country?: string
  defaultTimezone?: string
  language?: string
}

/** Platform user meta interface */
export interface PlatformUserMeta {
  name: string
  phone: string
  mobilePhone: string
  country: string
  locale: {
    language: string
    timezone: string
  } | null
  hasOrganization: boolean
  organization: Nullable<OrganizationLight>
  organizationCorrect: null | boolean
  vatNumber?: string
}

/** Create platform user API call body interface */
export interface CreatePlatformUserBody {
  name: string
  firstName: string
  lastName: string
  phone: string
  mobilePhone: string
  country: Country
  locale: {
    language: Language
    timezone: string
  }
  hasOrganization: boolean
  isOrganizationCorrect: boolean
  organization: Nullable<OrganizationLight>
  vatNumber?: string
  onboardingAnswer?: string
}

/** Platform user status interface */
export interface PlatformUserStatus {
  hasAuth0Account: boolean
  hasPipedriveContactPerson: boolean
  hasDatabaseProfile: boolean
  isEmailVerified: boolean
}

/** Update platform user body API call body interface */
export type UpdatePlatformUserBody = UpdatePlatformUserBaseBody | UpdatePlatformUserClientBody

/** Update platform user client API call body interface (extends platform user base) */
export interface UpdatePlatformUserClientBody extends UpdatePlatformUserBaseBody {
  preferredPaymentMethod: PreferredPaymentMethodType
  // B2B client fields
  personalOrderReference: string | null
  team: string | null
  // B2C client fields
  address: string | null
  vatNumber: string | null
}

/** Update platform user base API call body interface */
export interface UpdatePlatformUserBaseBody {
  name: string
  phone: string
  defaultTimezone: string
  language: Language
}
