import { Fragment, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { applyFeeDiscount, formatPrice } from 'utils/price'
import { extendVirtualVisitHosting, purgeExtendVirtualVisitHosting } from 'redux/Individual/Visual/ExtendVirtualVisitHosting'
import { getDateString, getUserTimezone } from 'utils/time'
import { useDispatch, useSelector } from 'react-redux'

import { ActionTypeAPIData } from 'constants/redux'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { DecimalDTO } from 'models/misc'
import { ProductType } from 'constants/product'
import { RootStore } from 'models/redux'
import { UpsellPayment } from '../UpsellPayment'
import { ZERO_DECIMAL } from 'constants/misc'
import styles from './VirtualVisitHostingInfo.module.sass'

/**
 * @interface Props Input properties of VirtualVisitHostingInfo component
 */
interface Props {
  /** Date string of virtual visit hosting expiration date */
  hostingExpirationDate: string | null
  /** Is virtual visit hosting expired? */
  isExpired: boolean,
  /** ID of assignment */
  assignmentId: string
  /** Organization discount */
  discount?: DecimalDTO
}

/**
 * @component Shows info about virtual visit hosting expiration and allows client to extend/reactivate hosting
 * @example <VirtualVisitHostingInfo hostingExpirationDateString={dateString} isExpired={false} assignmentId={77678}>
 * */
export const VirtualVisitHostingInfo: React.FC<Props> = ({
  hostingExpirationDate,
  isExpired,
  assignmentId,
  discount = ZERO_DECIMAL
}) => {
  const { t } = useTranslation(['virtual_visit_info', 'gallery'])
  const dispatch = useDispatch()

  const user = useSelector((state: RootStore) => state.APIData.USER_ME.data)

  const virtualExtensionPriceData = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.GET_VIRTUAL_VISIT_EXTENSION_PRICE][assignmentId]?.data)
  const extendVirtualVisitRequestState = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING][assignmentId]?.request.state)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const extensionPrice = useMemo(() => virtualExtensionPriceData?.fee, [virtualExtensionPriceData])
  const extensionProductId = useMemo(() => virtualExtensionPriceData?.productId, [virtualExtensionPriceData])
  const currency = useMemo(() => extensionPrice?.currency, [extensionPrice])
  const extensionTotal = useMemo(() => !!extensionPrice ? applyFeeDiscount({ ...extensionPrice, discountPercentage: discount }) : undefined, [extensionPrice, discount])
  const expirationDateString = useMemo(() => !!hostingExpirationDate ? getDateString(hostingExpirationDate, { timezone: getUserTimezone(user) }) : '', [user, hostingExpirationDate])

  return (
    <Fragment>

      <BlockInfo className={styles.hostingInfo}>

        {isExpired
          ? <Fragment>
            <h4 className={styles.title}>
              {t('reactivate_info_title')}
            </h4>
            <p>
              {/* Using Trans to render tags like <strong> and <br /> */}
              <Trans t={t} i18nKey="reactivate_info_body" values={{ price: formatPrice(extensionPrice, currency) }}>
                <span></span>
              </Trans>
            </p>
          </Fragment>
          : <Fragment>
            <h4 className={styles.title}>
              {t('extend_info_title', { expirationDate: expirationDateString })}
            </h4>
            <p>
              {/* Using Trans to render tags like <strong> and <br /> */}
              <Trans t={t} i18nKey="extend_info_body" values={{ price: formatPrice(extensionPrice, currency) }}>
                <span></span>
              </Trans>
            </p>
          </Fragment>
        }

        <div className={styles.actionWrap}>
          <Button onClick={() => setIsModalOpen(true)}>
            {t(isExpired ? 'reactivate_cta' : 'extend_cta')}
          </Button>
        </div>

      </BlockInfo>

      <UpsellPayment
        assignmentId={assignmentId}
        productId={extensionProductId}
        productQuantity={1}
        total={extensionTotal}
        isOpen={isModalOpen}
        beforeConfirmAction={(paymentIntentId, billingProductId) => dispatch(extendVirtualVisitHosting(assignmentId, isExpired, billingProductId, paymentIntentId))}
        beforeConfirmActionState={extendVirtualVisitRequestState}
        onClose={() => setIsModalOpen(false)}
        afterClosed={() => dispatch(purgeExtendVirtualVisitHosting(assignmentId, isExpired))}
        successTitle={t(isExpired ? 'reactivate_success' : 'extend_success')}
        productType={ProductType.VIRTUAL_VISIT}
      >
        {/* Using Trans to render tags like <strong> and <br /> */}
        <Trans t={t} i18nKey={isExpired ? 'reactivate_confirm_text' : 'extend_confirm_text'} values={{ price: formatPrice(extensionTotal, currency) }}>
          <span></span>
        </Trans>
      </UpsellPayment>

    </Fragment>
  )
}
