import { FC } from 'react'
import { Stack } from '@mui/material'

/**
 * Client gallery documents component
 * 
 * @example
 * <ClientGalleryDocuments />
 */
export const ClientGalleryDocuments: FC = () => {

  return (
    <Stack width="100%" marginTop="3rem">DOCUMENTS DELIVERED CONTENT PLACEHOLDER</Stack>
  )
}
