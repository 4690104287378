import { ThemeOptions, createTheme } from '@mui/material/styles'

const fontFamilyBase = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
]

const SailecFontFamily = ['Sailec', ...fontFamilyBase].join(',')
export const HKGroteskFamily = ['HK Grotesk', ...fontFamilyBase].join(',')
export const SangBleuFontFamily = ['SangBleu OG Sans', ...fontFamilyBase].join(',')

export const BACKBONE_SAND = '#F2F4EB'
export const DARK_BACKBONE_SAND = '#DADFCA'
export const PAPER_WHITE = '#FEFDFA'
export const WHITE = '#FFFFFF'
export const DEEP_SPACE_BLACK = '#22292E'
export const MEDITERRANEAN_BLUE = '#4BAABB'
export const CONTRAST_BLUE = '#3F9CAD'
export const DARK_ORANGE = '#E06E49'
export const CONTRAST_ORANGE = '#B84B28'
export const EVERYDAY_PEN_PURPLE = '#7B61FF'
export const CONTRAST_BACKBONE_SAND = '#EAEDDF'
export const CONTRAST_PAPER_WHITE = '#DBDBDB'
export const DARK_CONTRAST_BACKBONE_SAND = '#E6E6DD'
export const CAUTION_RED = '#FDF4F1'
export const ACTIVATED_GREEN = '#F5F9F4'
export const OCEAN_BLUE = '#148BD1'

export const YES_GREEN = '#6AC58A'
export const NO_RED = '#E85E5E'

export const TRANSPARENT = 'transparent'

// Redesigned colors
export const CORAL_100 = '#FCF0ED'
export const CORAL_200 = '#FAE4DE'
export const CORAL_300 = '#F4CABD'
export const CORAL_400 = '#F1A08A'
export const CORAL_500 = '#E88668'
export const CORAL_600 = '#E26D49'
export const CORAL_700 = '#CE6342'
export const CORAL_1000 = '#52281B'
export const GREEN_100 = '#E6FBF2'
export const GREEN_200 = '#D1F7E8'
export const GREEN_500 = '#46DFA3'
export const GREEN_600 = '#23CC89'
export const GREEN_700 = '#1CA56F'
export const GREEN_1000 = '#0B422C'
export const ORANGE_100 = '#FEF3E7'
export const ORANGE_200 = '#FDE9D3'
export const ORANGE_400 = '#F1A08A'
export const ORANGE_600 = '#F39023'
export const ORANGE_700 = '#DC790C'
export const ORANGE_1000 = '#583005'
export const GRAY_100 = '#FAFAFA'
export const GRAY_200 = '#F5F5F5'
export const GRAY_300 = '#EFF0F0'
export const GRAY_400 = '#DCDDDE'
export const GRAY_500 = '#C1C2C3'
export const GRAY_600 = '#9D9FA1'
export const GRAY_700 = '#727578'
export const GRAY_800 = '#404448'
export const GRAY_900 = '#252A2E'
export const GRAY_1000 = '#13181D'
export const GRAY_1100 = '#07080A'
export const GRAY_TEXT = '#6E6E6E'
export const GRAY_BORDER = '#EDEDED'
export const BEIGE_100 = '#FEFEFD'
export const BEIGE_300 = '#FBFBF7'
export const BEIGE_400 = '#F8F8F4'
export const BEIGE_500 = '#F6F6F0'
export const BEIGE_600 = '#F3F3EA'
export const BEIGE_700 = '#DDDDD5'
export const BEIGE_800 = '#B1B1AA'
export const BEIGE_900 = '#858580'
export const BEIGE_1000 = '#585855'
export const BLUE_100 = '#EDF7F8'
export const BLUE_200 = '#DEF0F3'
export const BLUE_300 = '#BEE0E6'
export const BLUE_400 = '#A7DBE2'
export const BLUE_500 = '#7DC1CD'
export const BLUE_600 = '#4CAABB'
export const BLUE_700 = '#459BAA'
export const BLUE_1000 = '#1C3E44'
export const BLACK_TEXT = '#000000'

// Redesigned box shadow
export const BOX_SHADOW_BOTTOM = '0 .2rem .2rem rgba(19, 24, 29, 0.04)'
export const BOX_SHADOW_XS = '0px 1px 4px 0px rgba(19, 24, 29, 0.06)'
export const BOX_SHADOW_SM = '0px 1px 4px 0px rgba(19, 24, 29, 0.10)'
export const BOX_SHADOW_MD = '0px 2px 8px 0px rgba(19, 24, 29, 0.10)'
export const BOX_SHADOW_LG = '0px 4px 16px 0px rgba(19, 24, 29, 0.10)'
export const BOX_SHADOW_XL = '0px 8px 24px 0px rgba(19, 24, 29, 0.10)'
export const BOX_SHADOW_2XL = '0px 24px 48px 0px rgba(19, 24, 29, 0.10)'
export const BOX_SHADOW_3XL = '0px 32px 64px 0px rgba(19, 24, 29, 0.10)'

// Media queries
export const MOBILE_VIEW_QUERY = '(max-width:800px)'
export const TABLET_VIEW_QUERY = '(max-width:900px)'

export const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: DEEP_SPACE_BLACK,
    },
    secondary: {
      main: BLUE_600,
      contrastText: WHITE,
    },
    error: {
      main: NO_RED,
    },
    success: {
      main: YES_GREEN,
    },
    divider: 'rgba(160,160,160,0.12)',
    background: {
      default: BEIGE_400,
      paper: WHITE,
    },
    // custom
    hint: {
      main: 'rgba(34, 41, 46, 0.5)' // custom for input hint (remove when reworking inputs??)
    }
  },
  shape: {
    borderRadius: 8,
  },
  spacing: (factor: number) => `${factor}rem`,
  typography: {
    fontSize: 10,
    htmlFontSize: 10,
    fontFamily: HKGroteskFamily,
    h1: {
      fontSize: '10rem',
      fontFamily: SangBleuFontFamily,
    },
    h2: {
      fontSize: '8rem',
      fontFamily: SangBleuFontFamily,
    },
    h3: {
      fontSize: '4rem',
      fontFamily: SangBleuFontFamily,
    },
    h4: {
      fontSize: '3.2rem',
    },
    h5: {
      fontSize: '2.4rem',
    },
    h6: {
      fontSize: '1.6rem',
    },
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.4rem',
    },

    // custom
    bodyLarge: {
      fontSize: '4rem',
      fontFamily: SailecFontFamily,
    },
    bodyMedium: {
      fontSize: '2rem',
      fontFamily: SailecFontFamily,
    },
    bodySmall: {
      fontSize: '1.6rem',
      fontFamily: SailecFontFamily,
    },
    bodySmaller: {
      fontSize: '1.4rem',
      fontFamily: SailecFontFamily,
    },
    bodyNormal: {
      fontSize: '1.2rem',
      fontFamily: SailecFontFamily,
    },
    bodyTiny: {
      fontSize: '1rem',
      fontFamily: SailecFontFamily,
    },

    headlineMain: {
      fontSize: '4rem',
      fontFamily: SangBleuFontFamily,
    },
    headlineSecondary: {
      fontSize: '2rem',
      fontFamily: SangBleuFontFamily,
    },
    headlineSmall: {
      fontSize: '1.6rem',
      fontFamily: SangBleuFontFamily,
    },
    button: {
      textTransform: 'none'
    },
    sangbleu: {
      fontFamily: SangBleuFontFamily,
    },
    sailec: {
      fontFamily: SailecFontFamily,
    },
  },
}

export const BKBNMUITheme = createTheme(lightTheme)
