import { FC, Fragment, useCallback, useMemo } from 'react'
import { GalleryStagingVisual, useGalleryStagingFlow, useGalleryStagingVisualSelection } from '../main/context'
import { Trans, useTranslation } from 'react-i18next'

import { APIRequestState } from 'constants/API'
import { Color } from 'constants/assets'
import { GRAY_900 } from 'constants/styling/theme'
import { GalleryImage } from 'components/common/Gallery/GalleryImage'
import Grid from '@mui/material/Grid'
import ReactLoading from 'react-loading'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useGalleryVisualType } from 'components/pages/Gallery/_main/contexts/GalleryVisualType.context'
import { useGalleryVisuals } from 'components/pages/Gallery/_main/contexts/GalleryVisuals.context'
import { useGalleryVisualsMeta } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'

/**
 * Displays header and purchased visuals of current assignment in grid for selection.
 * 
 * @example <GalleryStagingFlowSelectionStep />
 */
export const GalleryStagingFlowSelectionStep: FC = () => {

  const { t } = useTranslation(['gallery_staging_flow'])
  const { thumbnailType } = useGalleryVisualType()
  const { purchasedVisualsKeys, allVisuals } = useGalleryVisualsMeta()
  const { downloadVisualsEntries } = useGalleryVisuals()

  const { selectedVisuals, selectVisual, unselectVisual } = useGalleryStagingVisualSelection()
  const { requiredSelectionCount } = useGalleryStagingFlow()

  /** Selects/unselects visual in GalleryStagingVisualSelection context */
  const handleToggleSelection = useCallback((visual: GalleryStagingVisual) => {
    if (selectedVisuals[visual.id]) {
      unselectVisual(visual.id)
    } else {
      selectVisual(visual)
    }

    return true
  }, [selectVisual, selectedVisuals, unselectVisual])

  /** Checks whether all visuals have been loaded by comparing received image count in meta and actually count of loaded images */
  const allVisualsLoaded = useMemo(() => {
    if (!allVisuals?.data || allVisuals.state !== APIRequestState.OK) return false

    const visualsMetaCount = allVisuals.data.visuals.length
    const loadedVisualsLength = downloadVisualsEntries.length

    return visualsMetaCount === loadedVisualsLength
  }, [allVisuals, downloadVisualsEntries])

  /** Filters out empty and un-purchased visuals */
  const visualsForSelection: GalleryStagingVisual[] = useMemo(() => {
    if (!allVisualsLoaded) return []

    const visuals: GalleryStagingVisual[] = []

    downloadVisualsEntries.forEach(([key, visual]) => {
      if (!visual || !Object.values(visual).length) return
      if (!purchasedVisualsKeys.has(key)) return

      visuals.push({
        id: key,
        url: visual[thumbnailType]?.signedUrl
      })
    })

    return visuals

  }, [allVisualsLoaded, downloadVisualsEntries, purchasedVisualsKeys, thumbnailType])

  return (
    <div>

      {!allVisualsLoaded
        ? (
          <Stack
            top="50%"
            left="50%"
            position="absolute"
            sx={{ transform: 'translate(-50%, -50%)' }}
          >
            <ReactLoading
              color={Color.GRAY_TEXT}
              type="cylon"
            />
          </Stack>
        )
        : (
          <Fragment>

            <Stack spacing={.5} marginBottom={4}>

              <Typography color={GRAY_900} variant='text-xl' fontWeight={600}>
                {t('selection_step.title')}
              </Typography>

              <Typography variant='text-sm'>
                <Trans t={t} i18nKey="selection_step.description" values={{ count: requiredSelectionCount }}>
                  &nbsp;
                </Trans>
              </Typography>

            </Stack>

            <Grid container spacing={2}>
              {visualsForSelection.map(visual =>
                <Grid key={`grid-${visual.id}`} item xs={12} sm={4} lg={3}>
                  <GalleryImage
                    key={visual.id}
                    imageUrl={visual.url}
                    isSelectable={true}
                    isSelected={!!selectedVisuals[visual.id]}
                    onClick={() => handleToggleSelection(visual)}
                    onSelect={() => handleToggleSelection(visual)}
                  />
                </Grid>
              )}
            </Grid>

          </Fragment>
        )
      }

    </div>
  )
}
