import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { PurchaseFlowStepKey } from '../../_main/contexts'
import { StepWrapper } from '../../common/StepWrapper'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Page in PurchaseFlow step for configuring Power of Attorney for documents.
 * 
 * @example 
 * <PowerOfAttorneyStep />
 */
export const PowerOfAttorneyStep: FC = () => {
  const { t } = useTranslation(['purchase_flow'])

  const isStepValid = true

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.POWER_OF_ATTORNEY}
      isValid={isStepValid}
    >
      <StepWrapper
        title={t('power_of_attorney_step.section_title')}
        subtitle={t('power_of_attorney_step.section_description')}
      >
        Here goes content for Power of Attorney step
      </StepWrapper>
    </DynamicFlowStep>
  )
}
