import { BEIGE_500, MOBILE_VIEW_QUERY } from 'constants/styling/theme'

import Box from '@mui/material/Box'
import Button from 'components/common/Button/Button'
import { EmptyContent } from 'components/common/EmptyContent'
import { FC } from 'react'
import styles from 'components/pages/Client/ClientGallery/ClientGalleryEmptyContent/ClientGalleryEmptyContent.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** Button click callback */
  onButtonClick?: () => void
}

/**
 * Client gallery empty content info box with custom props.
 * 
 * @example <ClientGalleryEmptyContent />
 */
export const ClientGalleryEmptyContent: FC<Props> = ({ onButtonClick }) => {
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <Box maxWidth="60rem" width="100%" margin="auto" marginTop="8rem">

      <EmptyContent
        title={t('client_gallery.empty')}
        padding={isMobileView ? '' : '1rem 8rem'}
        backgroundColor={BEIGE_500}
        button={<Button
          className={styles.button}
          type='primary'
          onClick={onButtonClick}
        >
          {t('client_gallery.empty_action')}
        </Button>}
      />

    </Box>
  )
}
