import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { FloorPlanConfigStepController } from './FloorPlanConfigStep.controller'

/**
 * @component
 * Page in PurchaseFlow step for configuring floor plans.
 * 
 * @example 
 * <FloorPlanConfigStep />
 */
export const FloorPlanConfigStep: FC = () => {

  const { isFloorPlanConfigStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.FLOOR_PLAN_CONFIG}
      isValid={isFloorPlanConfigStepValid}
    >
      <FloorPlanConfigStepController />
    </DynamicFlowStep>
  )
}
