import './UnverifiedUser.sass'

import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useResendChangeEmail, useVerifyUser } from 'dataQueries'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { EmailVerifyMode } from '../EmailVerified/EmailVerified'
import { PageTransition } from 'utils/animations'
import { RootStore } from 'models/redux'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { userStatus } from 'redux/Individual/User/UserStatus'

const UnverifiedUser: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['unverified_user'])
  const { refreshUser, user } = useAuth0()

  const params = useMemo(() => new URLSearchParams(window.location.search), [])
  const isChangeEmail = useMemo(() => params.get('mode') === EmailVerifyMode.CHANGE_EMAIL, [params])

  const { spawnSuccessToast } = useSnackbar()
  const resendChangeEmail = useResendChangeEmail()
  const verifyUser = useVerifyUser()
  const userStatusRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_STATUS])

  // log entering this page
  useEffect(() => {
    logAnalyticsEvent('enters_user_unverified_screen', {})
  }, [])

  // start long polling for user status
  useEffect(() => {
    window.setTimeout(() => {
      dispatch(userStatus())
    }, 2000)
  }, [dispatch])

  // handle user status
  useLayoutEffect(() => {
    if (
      userStatusRequest.state === APIRequestState.OK &&
      userStatusRequest.data?.isEmailVerified &&
      !user?.email_verified
    ) {
      logAnalyticsEvent('email_verified', {})
      refreshUser()
    } else if (
      (
        userStatusRequest.state === APIRequestState.OK ||
        userStatusRequest.state === APIRequestState.ERROR
      ) &&
      !userStatusRequest.data?.isEmailVerified
    ) {
      window.setTimeout(() => {
        dispatch(userStatus())
      }, 2000)
    }
  }, [dispatch, userStatusRequest, user, refreshUser])

  const handleResendEmail = useCallback(() => {
    if (isChangeEmail) return resendChangeEmail.mutate({}, {
      onSuccess: () => {
        spawnSuccessToast(t('resend_success'))
      },
    })

    return verifyUser.mutate({}, {
      onSuccess: () => {
        spawnSuccessToast(t('resend_success'))
      },
    })
    // skip deps t, spawnSuccessToast, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeEmail, resendChangeEmail, verifyUser])

  return (
    <PageTransition>
      <div className="page triangle-stripe-bg unverified-user">
        <div className="page-content">
          <div className="wrap">
            <div className="content">
              <div className="card">
                <section>
                  <h1>{t('heading')}</h1>
                  <p>
                    <Trans t={t} i18nKey="unverified_user:please_confirm">
                      <strong></strong>
                    </Trans>
                  </p>
                </section>
                <section>
                  <h2>{t('didnt_receive')}</h2>
                  <p>
                    <Trans t={t} i18nKey="unverified_user:check_spam">
                      <strong></strong>
                    </Trans>
                  </p>
                  <p>{t('delayed_delivery')}</p>
                </section>
                <section>
                  <div className="input-group">

                    <input
                      className="button secondary"
                      type="button"
                      name="verification"
                      id="verification"
                      value={t('resend_button').toString()}
                      onClick={handleResendEmail}
                      disabled={verifyUser.isPending}
                    />

                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default UnverifiedUser
