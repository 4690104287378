import { CORAL_100, CORAL_400, CORAL_600, GRAY_900 } from 'constants/styling/theme'

import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UploadWrapper } from './UploadWrapper.component'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { useTranslation } from 'react-i18next'

/** 
 * @component 
 * UploadLimitError displays an styled block when the user exceeds the amout of uploaded visuals.
 * 
 * @example
 * <UploadLimitError />
 */
export const UploadLimitError: React.FC = () => {
  const { t } = useTranslation(['order'])

  return (
    <UploadWrapper borderColor={CORAL_400} backgroundColor={CORAL_100}>
      <Stack direction="row" gap={1}>

        <WarningAmberRoundedIcon sx={{ fontSize: 20, color: CORAL_600 }} />

        <Stack>

          <Typography variant='text-sm' color={GRAY_900} fontWeight={600}>
            {t('step_product.upload_visuals_limit.title')}
          </Typography>

          <Typography variant='text-sm' color={GRAY_900}>
            {t('step_product.upload_visuals_limit.description')}
          </Typography>

        </Stack>

      </Stack>
    </UploadWrapper>
  )
}
