import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import { GRAY_200, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { MemberAccessItem, MemberRoleItem, accessDropdownItems, memberRoleItems } from 'constants/workspaces'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIButton } from 'components/common/MUIButton'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'
import { WorkspaceMember, WorkspaceRole } from 'models/user'
import { ChangeMemberType } from '../_main/WorkspacesProfile.controller'
import { Nullable } from 'models/helpers'

/**
 * @component Member setting drawer content
 * @example <MemberSettingDrawerContent />
 */
export const MemberSettingDrawerContent: React.FC = () => {
  const { t } = useTranslation(['workspace_profile'])

  const {
    setRemoveMember,
    setChangeMemberRole,
    memberSetting,
    setIsSettingDrawerOpen
  } = useWorkspacesProfileContext()

  const onChangeMemberRole = useCallback((member: Nullable<WorkspaceMember>, item: MemberRoleItem) => {
    if (!member || member.role === item.role) return
    setChangeMemberRole({
      type: ChangeMemberType.ROLE,
      memberId: member.userId,
      name: member.name,
      email: member.email,
      newRole: WorkspaceRole[item.role],
      isRestricted: member.isRestricted
    })
    setIsSettingDrawerOpen(false)
  }, [setChangeMemberRole, setIsSettingDrawerOpen])

  const onChangeMemberAccess = useCallback((member: Nullable<WorkspaceMember>, item: MemberAccessItem) => {
    if (!member || member.isRestricted === item.isRestricted) return
    setChangeMemberRole({
      type: ChangeMemberType.ACCESS,
      memberId: member.userId,
      name: member.name,
      email: member.email,
      newRole: member.role,
      isRestricted: item.isRestricted
    })
    setIsSettingDrawerOpen(false)
  }, [setChangeMemberRole, setIsSettingDrawerOpen])

  return (
    <Stack gap="1.6rem">
      <Stack gap=".4rem">
        <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>{t('role_title')}</Typography>
        {memberRoleItems.map(item => (
          <MUIDropdownItem
            key={item.role}
            isSelected={memberSetting?.role === item.role}
            onClick={() => onChangeMemberRole(memberSetting, item)}
            sx={{
              borderRadius: '.8rem',
              border: `.1rem solid ${GRAY_200}`
            }}
          >
            <Stack gap=".2rem">
              <Typography variant="text-md" fontWeight="medium" color={GRAY_900}>{t(item.title)}</Typography>
              <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>{t(item.subTitle)}</Typography>
            </Stack>
          </MUIDropdownItem>
        ))}
      </Stack>

      <Stack gap=".4rem">
        <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>{t('access')}</Typography>
        {accessDropdownItems.map(item => (
          <MUIDropdownItem
            key={item.subTitle}
            isSelected={memberSetting?.isRestricted === item.isRestricted}
            onClick={() => onChangeMemberAccess(memberSetting, item)}
            sx={{
              borderRadius: '.8rem',
              border: `.1rem solid ${GRAY_200}`
            }}
          >
            <Stack gap=".2rem">
              <Typography variant="text-md" fontWeight="medium" color={GRAY_900}>{t(item.title)}</Typography>
              <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>{t(item.subTitle)}</Typography>
            </Stack>
          </MUIDropdownItem>
        ))}
      </Stack>

      <Box width="100%">
        <MUIButton fullWidth size="lg" type="orangeSecondaryBorder" variant="outlined" onClick={() => {
          setRemoveMember(memberSetting?.email)
          setIsSettingDrawerOpen(false)
        }}>
          {t('remove_user')}
        </MUIButton>
      </Box>
    </Stack>
  )
}
