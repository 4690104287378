import { BEIGE_400, CORAL_100, CORAL_600, GRAY_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Box, Chip, Stack } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'

import { useDynamicFlowController } from './DynamicFlow.context'

const palette = {
  active: {
    bg: CORAL_100,
    fg: CORAL_600,
    text: GRAY_900,
  },
  default: {
    bg: BEIGE_400,
    fg: GRAY_700,
    text: GRAY_600,
  },
}

interface BaseStepperNodeProps {
  isHighlighted: boolean
}

interface BaseStepProps extends BaseStepperNodeProps {
  label: string
}

interface MainStepProps extends BaseStepProps {
  number: number
}

/**
 * Main step component for stepper
 * @example <MainStep number={1} label="Step 1" isHighlighted={true} />
*/
const Step: FC<MainStepProps> = ({
  number,
  isHighlighted,
  label,
}) => {
  return (
    <Stack direction="row" alignItems="center" fontWeight="600" gap={1}>

      <Stack
        width="30px"
        height="30px"
        borderRadius="30px"
        bgcolor={isHighlighted ? palette.active.bg : palette.default.bg}
        color={isHighlighted ? palette.active.fg : palette.default.fg}
        flex="0 0 auto"
        alignItems="center"
        justifyContent="center"
      >
        {number}
      </Stack>

      <span style={{
        color: isHighlighted ? palette.active.text : palette.default.text
      }}>
        {label}
      </span>

    </Stack>
  )
}

/**
 * Secondary step component for stepper
 * @example <SecondaryStep label="Step 1" isHighlighted={true} />
*/
const SecondaryStep: FC<BaseStepProps> = ({
  label,
  isHighlighted,
}) => {
  return (
    <Box flex="0 0 auto">
      <Chip
        variant="filled"
        label={label}
        style={{
          fontSize: '1.2rem',
          fontWeight: 'bolder',
          backgroundColor: isHighlighted ? palette.active.bg : palette.default.bg,
          color: isHighlighted ? palette.active.fg : palette.default.fg,
        }}
      />
    </Box>
  )
}

/**
 * Divider component for stepper
 * @example <Divider isHighlighted={true} />
*/
const Divider: FC<BaseStepperNodeProps> = ({
  isHighlighted,
}) => {
  return (
    <Box flex="1 0 2rem">
      <hr style={{
        width: '100%',
        border: 'unset',
        borderTop: `1px solid ${isHighlighted ? palette.active.fg : palette.default.fg}`,
      }} />
    </Box>
  )
}

interface StepperProps {
  stepNameTranslatorFnc: (stepKey: string) => string
}

/**
 * Stepper component for the dynamic flow system  
 * Steps are automatically sourced from the closest parent dynamic flow context  
 * @example <DynamicFlowStepper stepNameTranslatorFnc={(key) => t(`steps.${key}`)} />
*/
export const DynamicFlowStepper: FC<StepperProps> = ({
  stepNameTranslatorFnc
}) => {

  const flowCtrl = useDynamicFlowController()

  /** Transform nodes to main and secondary steps with dividers */
  const assembledNodes = useMemo(() => {
    const componentList: Array<ReactNode> = []

    const nodeList = flowCtrl.getOrderedNodeList()

    const currentStep = flowCtrl.currentNode?.key
    const currentStepIndex = currentStep ? nodeList.findIndex((node) => node.key === currentStep) : 0

    let mainIndex = 1

    // Iterate over the ordered nodes
    for (const [index, node] of nodeList.entries()) {

      // If the node is a main step, render it with a step number
      if (node.type === 'main') {
        componentList.push(
          <Step
            key={node.key}
            label={stepNameTranslatorFnc(node.key)}
            // Main step number
            number={mainIndex}
            // Highlight the step if it's the current step or before
            isHighlighted={index <= currentStepIndex}
          />
        )
        // Increment the main step number
        mainIndex++
      } else {
        // If the node is a secondary step, render it without a step number
        componentList.push(
          <SecondaryStep
            key={node.key}
            label={stepNameTranslatorFnc(node.key)}
            // Highlight the step if it's the current step or before
            isHighlighted={index <= currentStepIndex}
          />
        )
      }

      // If the node is not the last one, add a divider after
      if (index < nodeList.length - 1) componentList.push(
        <Divider
          key={`${node.key}-divider`}
          // Highlight the divider if it's the current step or before
          isHighlighted={index < currentStepIndex}
        />
      )
    }

    return componentList

    // Omit flowCtrl to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCtrl.getOrderedNodeList, flowCtrl.currentNode, stepNameTranslatorFnc])

  return (
    <div>
      <Stack direction="row" alignItems="center" gap="2rem" fontSize="1.3rem">
        {assembledNodes}
      </Stack>
    </div>
  )
}
