import { Box, Typography } from '@mui/material'
import { GRAY_800, GRAY_900 } from 'constants/styling/theme'
import React, { MouseEvent, ReactNode } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Stack from '@mui/material/Stack'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Whether the button is toggled or not */
  isToggled?: boolean
  /** Element that should be used as toggle icon (defaults to checkmark icon) */
  toggleIcon?: ReactNode
  /** The button label displayed beside the icon  */
  label: string | ReactNode
  /** The icon displayed on the left side */
  icon?: ReactNode
  /** Onclick action to be called */
  onClick?: (e: MouseEvent) => unknown
}

/**
 * @component ToggleButton with custom icon, label and click action. Display toggled status with checked icon on the button top-right corner.
 * @example
 * <ToggleButton
 *  isToggled={true}
 *  label="Ongoing"
 *  icon=<Icon />
 *  onClick={e => console.log(e)}
 * />
 */
export const ToggleButton: React.FC<Props> = ({
  isToggled = false,
  toggleIcon = <CheckCircleIcon sx={{ color: GRAY_800 }} />,
  icon,
  label,
  onClick,
}) => {

  return (
    <BorderBoxWrapper
      elevation="xs"
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: '1.2rem',
        padding: '.8rem 1.6rem',
        cursor: 'pointer',
        height: '4.8rem',
        width: '17rem'
      }}
      onClick={e => onClick?.(e)}
    >

      {icon &&
        <Stack
          fontSize={2}
          marginRight={.8}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          color={GRAY_800}
        >
          {icon}
        </Stack>
      }

      {isToggled &&
        <Box
          position="absolute"
          top={-6}
          right={-6}
        >
          {toggleIcon}
        </Box>
      }

      <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>
        {label}
      </Typography>

    </BorderBoxWrapper>
  )
}
