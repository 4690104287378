import { CatalogueOptionProduct, CatalogueProduct, OptionProductType } from 'dataQueries/purchase.query'
import { GRAY_300, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { memo, useCallback, useMemo } from 'react'
import { bigFromFee, formatPrice, valueAfterDiscount } from 'utils/price'

import Big from 'big.js'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { CounterInput } from 'components/common/CounterInput'
import { Currency } from 'constants/misc'
import { FormControlLabel } from '@mui/material'
import { MUICheckbox } from 'components/common/MUICheckBox'
import Stack from '@mui/material/Stack'
import { TFunction } from 'i18next'
import Typography from '@mui/material/Typography'
import { getTimeDurationStringFromMinutesAndHours } from 'utils/time'
import { usePurchaseFlowProducts } from '../../_main/contexts/PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

/** @interface Props Input properties */
interface Props {
  /** Product whose options are to be displayed. */
  product: CatalogueProduct
  /** Whether to display only selected types of options. */
  onlySelected: boolean
  /** Segment key. */
  segmentKey: string
  /** Product type or kind key. */
  typeOrKindKey: string
  /** Discount for price. */
  discount: Big
  /** Currency for price formatting. */
  currency?: Currency
  /** Whether the selection should be disabled. */
  disabledSelection?: boolean
  /** Function to be run when value of input (quantity) changes. */
  onNumberInputChange?: (value: number, option: CatalogueOptionProduct) => void
  /** Function to be run when checkbox state changes. */
  onCheckboxChange?: (checked: boolean, option: CatalogueOptionProduct) => void
}

interface OptionTypographyProps {
  /** The translation function. */
  t: TFunction
  /** The product option details. */
  option: CatalogueOptionProduct
}

/** OptionTypography is a memoized component for rendering the product option name and duration. */
const OptionTypography = memo<OptionTypographyProps>(({ t, option }) => (
  <Stack width="100%">
    <Typography color={GRAY_900} fontWeight={600} fontSize={14}>
      {t(`product:p_${option.id}`)}
    </Typography>

    {!!option.duration && (
      <Typography color={GRAY_700} fontSize={12}>
        {t('step_product.max_duration', {
          duration: getTimeDurationStringFromMinutesAndHours((option.value || 1) * option.duration),
        })}
      </Typography>
    )}
  </Stack>
))

/**
 * @component 
 * ProductCardContent displays the options of a product.
 * 
 * <ProductCardContent
 *   product={selectedProduct}
 *   onlySelected={true}
 *   segmentKey="dunderMifflin"
 *   typeOrKindKey="paper"
 *   discount={0.05}
 *   currency="USD"
 *   disabledSelection={false}
 *   onNumberInputChange={(value, option) => console.log(`Changed quantity to ${value} for option ${option.id}`)}
 *   onCheckboxChange={(isChecked, option) => console.log(`Checkbox status: ${isChecked} for option ${option.id}`)}
 * />
*/
export const ProductCardContent: React.FC<Props> = ({
  product,
  onlySelected,
  segmentKey,
  typeOrKindKey,
  discount,
  currency,
  disabledSelection = false,
  onNumberInputChange,
  onCheckboxChange,
}) => {
  const { t } = useTranslation(['order', 'product'])
  const { selectedProductOptions } = usePurchaseFlowProducts()

  const productOptions = useMemo(() => {
    let options = product.options

    if (onlySelected) {
      const onlySelectedOptions = product.options.filter((option) => {
        const selectedOptions = selectedProductOptions[product.id]

        if (selectedOptions) {
          const options = selectedOptions[option.id]
          return options && options.id === option.id
        }

        return false
      })
      options = onlySelectedOptions
    }

    return options.sort(
      (optionA, optionB) => bigFromFee(optionA.feePrice).minus(bigFromFee(optionB.feePrice)).toNumber()
    )
  }, [onlySelected, product.id, product.options, selectedProductOptions])

  const handleIntegerChange = useCallback((value: number, option: CatalogueOptionProduct) => {
    if (!onNumberInputChange) return
    onNumberInputChange(value, option)
  }, [onNumberInputChange])

  if (!productOptions.length) return null

  return (
    <Stack gap={1}>
      {productOptions.map((option) => {
        const optionComposedKey = `${segmentKey}_${typeOrKindKey}_${product.id}_${option.id}`
        const optionPriceAfterDiscount = valueAfterDiscount(option.feePrice, discount)
        const selectedQuantity = selectedProductOptions[product.id]?.[option.id]?.quantity

        return (
          <BorderBoxWrapper key={option.id} borderColor={GRAY_300} padding="2rem" elevation='none'>
            <Stack gap={2} direction="row" alignItems="center">
              {onlySelected && disabledSelection &&
                <CounterInput
                  value={selectedQuantity}
                  hideControls
                  disabled
                />
              }

              <Stack width="100%" gap={2} direction="row" justifyContent="space-between" alignItems="center">

                {/** INPUT & PRODUCT NAME */}
                {(option.type === OptionProductType.BOOLEAN || option.type === OptionProductType.INTEGER) && (!onlySelected && !disabledSelection)
                  ? (
                    <FormControlLabel
                      label={
                        <OptionTypography t={t} option={option} />
                      }
                      componentsProps={{
                        typography: {
                          marginLeft: '1rem'
                        }
                      }}
                      sx={{ margin: 0 }}
                      onChange={(e, checked) => {
                        if (option.type === OptionProductType.BOOLEAN && onCheckboxChange) onCheckboxChange(checked, option)
                        else handleIntegerChange(1, option)
                      }}
                      control={
                        option.type === OptionProductType.BOOLEAN
                          ? (
                            <MUICheckbox
                              name={optionComposedKey}
                              id={optionComposedKey}
                              checked={!!(selectedQuantity ?? 0)}
                              onChange={onCheckboxChange ? e => onCheckboxChange(e.target.checked, option) : undefined}
                              disabled={disabledSelection}
                            />
                          )
                          : (
                            <CounterInput
                              value={selectedQuantity ?? 0}
                              onChange={(value) => handleIntegerChange(value, option)}
                              disabled={disabledSelection}
                            />
                          )
                      }
                    />
                  )
                  : <OptionTypography t={t} option={option} />
                }

                {/** PRICE */}
                {!onlySelected && !disabledSelection &&
                  <Typography color={GRAY_900} fontWeight={600} fontSize={14} textAlign="end" flex="1 0 auto">
                    {`+ ${formatPrice(optionPriceAfterDiscount, currency)}`}
                    {option.type === OptionProductType.INTEGER && ` / ${t('unit')}`}
                  </Typography>
                }

              </Stack>

            </Stack>
          </BorderBoxWrapper>
        )
      })}
    </Stack>
  )
}
