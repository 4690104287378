import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { GetVirtualVisitExtensionPriceItem } from './GetVirtualVisitExtensionPrice.interfaces'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action for getting virtual visit extension price */
export interface ActionGetVirtualVisitExtensionPrice extends ActionRequest {
  payload: {
    missionId: string,
    request: GetVirtualVisitExtensionPriceItem
  }
}

/** Creates general action for getting virtual visit extension price */
export function getVirtualVisitExtensionPrice(missionId: string): ActionGetVirtualVisitExtensionPrice {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_VIRTUAL_VISIT_EXTENSION_PRICE], {
    missionId,
    request: new APIRequest()
  })
}

/** Creates general action for purging get virtual visit extension price state */
export function purgeGetVirtualVisitExtensionPrice(missionId: string): ActionGetVirtualVisitExtensionPrice {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.GET_VIRTUAL_VISIT_EXTENSION_PRICE], {
    missionId,
    request: new APIRequest()
  })
}
