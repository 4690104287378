import { BEIGE_500, BEIGE_600, GRAY_600, GRAY_900 } from 'constants/styling/theme'
import { Box, Stack, Typography } from '@mui/material'
import { FloorPlanRenderType, useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { FC } from 'react'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { SelectableImageBox } from '../SelectableImageBox'
import { SimpleImage } from 'components/common/SimpleImage'
import basic_furniture_black from 'assets/img/floorplan/2D/2D_commercial_bw_furniture.webp'
import neutral_stock_3D_no_background from 'assets/img/floorplan/3D/neutral_stock_3D_no_background.webp'
import { useTranslation } from 'react-i18next'

interface FloorPlanOptionProps {
  /** The URL of the image to be displayed in the box. */
  imageUrl: string
  /** A boolean indicating if the box is currently active. */
  isActive: boolean
  /** The function to be called when the box is selected. */
  onSelect: () => void
  /** The text displayed at the bottom of the box. */
  bottomText: string
}

const FloorPlanOption: FC<FloorPlanOptionProps> = ({ imageUrl, isActive, onSelect, bottomText }) => (
  <SelectableImageBox
    text={bottomText}
    height={14.4}
    width={16.8}
    elevation='xs'
    inactiveBorderColor='transparent'
    isSelected={isActive}
    onSelect={onSelect}
    image={imageUrl}
  />
)

/**
 * @component
 * FloorPlanRenderTypeController displays the 2D/3D option to select, as default is no option.
 * 
 * @example 
 * <FloorPlanRenderTypeController />
 */
export const FloorPlanRenderTypeController: FC = () => {
  const { t } = useTranslation(['purchase_flow', 'actions'])
  const {
    currentFloorPlanRenderType,
    setCurrentFloorPlanRenderType,
    setFloorPlanTemplateType,
    setSelectedTemplateId,
  } = useFloorPlanConfig()

  const handleFloorTypeClick = (floorType: FloorPlanRenderType) => {
    setCurrentFloorPlanRenderType(floorType)
  }

  const goToStepSelection = () => {
    setFloorPlanTemplateType(null)
    setSelectedTemplateId(null)
  }

  return (
    <BorderBoxWrapper backgroundColor={BEIGE_500} padding="2.4rem">

      <Stack alignItems="center" position="relative">
        {/** Go back button */}
        <MUIButton
          isIconButton
          type="secondaryNoBorder"
          size="xs"
          startIcon={<ArrowBackRoundedIcon />}
          onClick={goToStepSelection}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          {t('actions:Back')}
        </MUIButton>

        <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
          {t('floor_plan_step.render_type.title')}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="center" alignItems="center" gap={1.6} marginTop={2}>

        <FloorPlanOption
          imageUrl={basic_furniture_black}
          isActive={currentFloorPlanRenderType === FloorPlanRenderType.RENDER_2D}
          onSelect={() => handleFloorTypeClick(FloorPlanRenderType.RENDER_2D)}
          bottomText={t('floor_plan_step.render_type.2D')}
        />
        {/* This option will be enabled when 3D floor plans are redesigned
            <FloorPlanOption
              imageUrl={neutral_stock_3D}
              isActive={currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D}
              onSelect={() => handleFloorTypeClick(FloorPlanRenderType.RENDER_3D)}
              bottomText={t('floor_plan_step.render_type.3D')}
            />
            */}

        {/* This disabled state 3D item with Coming soon badge will be 
            completely removed when 3D floor plans redesign is released */}
        <BorderBoxWrapper
          backgroundColor={BEIGE_600}
          elevation='xs'
          padding={1.6}
          sx={{
            gap: '1rem',
            display: 'flex',
            cursor: 'not-allowed',
            alignItems: 'center',
            flexDirection: 'column',
            height: '14.4rem',
            width: '16.8rem',
          }}
        >

          <Box position="relative">

            <SimpleImage
              key={neutral_stock_3D_no_background}
              url={neutral_stock_3D_no_background}
              sx={{ width: '9.6rem', height: '9.6rem' }}
              borderRadius='none'
            />

            <Box position="absolute" left="4rem" top="-.6rem">
              <MUIBadge label={t('floor_plan_step.coming_soon_badge')} size="sm" color="blue" />
            </Box>

          </Box>


          <Typography variant='text-sm' fontWeight={500} color={GRAY_600}>
            {t('floor_plan_step.render_type.3D')}
          </Typography>

        </BorderBoxWrapper>

      </Stack>

    </BorderBoxWrapper>
  )
}
