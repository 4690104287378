import { BorderBoxWrapper, BorderBoxWrapperProps } from 'components/common/BorderBoxWrapper'
import { FC, ReactNode } from 'react'

import { GRAY_900 } from 'constants/styling/theme'
import { MUIDivider } from 'components/common/MUIDivider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/** Props for the SectionedBorderBox component. */
interface Props extends BorderBoxWrapperProps {
  /** The main title displayed in the sectioned border box. */
  title: string
  /** An optional description displayed below the main title. */
  description?: string
  /** The content to be wrapped by the SectionedBorderBox component. */
  children: ReactNode
}

/**
 * @component
 * SectionedBorderBox component provides a styled border box with a title, optional description,
 * and content section separated by a divider.
 *
 * @example
 * <SectionedBorderBox
 *   title="Dunder Mifflin Infinity"
 *   description="The best paper company in Scranton."
 *   boxProps={{ borderColor: BLUE_600 }}
 * >
 *   <SectionContent />
 * </SectionedBorderBox>
 */
export const SectionedBorderBox: FC<Props> = ({ title, description, children, ...boxProps }) => {

  return (
    <BorderBoxWrapper padding={2} {...boxProps}>

      {/** HEADER */}
      <Stack gap={0.5}>
        <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>{title}</Typography>
        {description && <Typography variant="text-sm">{description}</Typography>}
      </Stack>

      {/** DEFAULT DIVIDER */}
      <MUIDivider margin={12} />

      {/** CONTENT */}
      {children}

    </BorderBoxWrapper>
  )
}
