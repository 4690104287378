import { AnalyticsEvent } from 'utils/analytics'
import { Box } from '@mui/material'
import { CopyButton } from 'components/common/CopyButton'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component Copy Gallery Link component shows confirmation text onClick and after 1500ms resets the state to default text,
 * for non subscribed users it is in disabled state with lock icon and tooltip encouraging user to subscribe
 * @example
 * <CopyLink />
 */
export const CopyGalleryLink: React.FC = () => {
  const {
    hasUserActiveSubscription,
    clientData,
  } = useUserData()
  const { logGalleryEvent } = useGalleryAssignment()

  const { t } = useTranslation(['gallery'])


  return (
    <div>

      {/** CONTROLLED TOOLTIP FOR USERS WITH SUBSCRIPTION, CONFIRMING COPY ACTION */}
      {hasUserActiveSubscription &&
        <CopyButton
          type='secondary'
          copyText={window.location.href}
          textContent={t('link_copy')}
          copySuccessText={t('link_copy_success')}
          onCopy={() => {
            logGalleryEvent(AnalyticsEvent.USE_COPY_LINK, {
              workspaces: clientData?.workspaces,
            })
          }}
        />
      }

      {/** UNCONTROLLED TOOLTIP FOR SAAS UPGRADE */}
      {!hasUserActiveSubscription &&
        <MUITooltip
          content={t('link_copy_sass_upgrade')}
        >
          <Box onClick={() => externalNavigate(Path.PRICING)}>
            <MUIButton
              type="secondaryBorder"
              endIcon={<LockOutlinedIcon fontSize='large' />}
              disabled
            >
              {t('link_copy')}
            </MUIButton>
          </Box>
        </MUITooltip>
      }
    </div>
  )
}
