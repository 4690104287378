import { useEffect, useMemo, useState } from 'react'
import { DealDTOIsClientDTO, DealDTOOrAssignmentDTOIsDealDTO } from 'utils/typeguards'

import { ProductFlavor } from 'constants/product'
import { AssignmentDTO } from 'models/assignment'
import { DealDTO } from 'models/deal'
import { ProductPriceDTO } from 'models/product'
import { bigFromDecimal } from 'utils/price'

export function useOrderProducts(data?: DealDTO | AssignmentDTO | null) {
  const [products, setProducts] = useState<ProductPriceDTO[]>([])

  const baseProducts = useMemo(() => {
    return products.filter(product =>
      product.quantity > 0 &&
      bigFromDecimal(product.unitPrice.value).gt(0)
    )
  }, [products])

  // Organization products
  const organizationProducts = useMemo(() => {
    return baseProducts.filter(product => product.flavor === ProductFlavor.ORGANIZATION)
  }, [baseProducts])

  // Billing products
  const billingProducts = useMemo(() => {
    return baseProducts.filter(product => product.flavor === ProductFlavor.BILLING)
  }, [baseProducts])

  // Admin products
  const adminProducts = useMemo(() => {
    return baseProducts.filter(product => product.flavor === ProductFlavor.ADMIN)
  }, [baseProducts])

  // Standard products
  const standardProducts = useMemo(() => {
    return products
      .filter(product => product.flavor === ProductFlavor.STANDARD)
      .filter(product => product.quantity > 0)
  }, [products])

  const purchasedProducts = useMemo(() => {
    return [
      ...standardProducts,
      ...adminProducts,
      ...billingProducts,
    ]
  }, [standardProducts, adminProducts, billingProducts])

  useEffect(() => {
    if (data && DealDTOOrAssignmentDTOIsDealDTO(data) && DealDTOIsClientDTO(data)) {
      setProducts(data.financials.products)
    }
  }, [data])

  return { organizationProducts, billingProducts, adminProducts, standardProducts, purchasedProducts }
}
