import './EmailVerified.sass'

import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import i18n, { transformLanguageToAuth0Value } from 'translations/i18n'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { Path } from 'constants/router'
import { RootStore } from 'models/redux'
import { useAuth0 } from 'utils/auth'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { userStatus } from 'redux/Individual/User/UserStatus'

const SignupMode: React.FC = () => {
  const dispatch = useDispatch()
  const { refreshUser, user, loginWithRedirect } = useAuth0()
  const navigate = useNavigate()
  const { t } = useTranslation(['email_verified'])

  const params = useMemo(() => new URLSearchParams(window.location.search), [])

  const userStatusRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_STATUS])

  useEffect(() => {
    // we can redirect to index immediately if user email is verified
    if (user?.email_verified) return navigate(Path.INDEX)

    // After successful email verification wait 5s showing success info and redirect to login page in case Anonymous window or if user logout before entering to verification page. After that Onboarding form will be visible
    window.setTimeout(() => {
      return loginWithRedirect({
        mode: 'login',
        language: transformLanguageToAuth0Value(i18n.language),
        login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
        appState: { targetUrl: Path.INDEX },
      })
    }, 5000)
  }, [user, navigate, loginWithRedirect, params])

  // handle user status
  useLayoutEffect(() => {
    if (
      userStatusRequest.state === APIRequestState.OK &&
      userStatusRequest.data?.isEmailVerified &&
      !user?.email_verified
    ) {
      refreshUser()
    } else if (
      (
        userStatusRequest.state === APIRequestState.OK ||
        userStatusRequest.state === APIRequestState.ERROR
      ) &&
      !userStatusRequest.data?.isEmailVerified
    ) {
      window.setTimeout(() => {
        dispatch(userStatus())
      }, 500)
    }
  }, [dispatch, userStatusRequest, user, refreshUser])

  return (
    <section>
      <h1>{t('title')}</h1>
      <p>{t('text')}</p>
    </section>
  )
}

export default SignupMode
