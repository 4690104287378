import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { all, put, takeEvery } from 'redux-saga/effects'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'

import API from 'utils/API/API'
import { ActionGetSubscriptionsStatus } from './getSubscriptionStatus.actions'

/** Migration to RQ blocker BKBN-84 */
/** Saga which gets subscription status from API */
export function* getSubscriptionStatusSaga(receivedAction: ActionGetSubscriptionsStatus) {

  const { workspaceId } = receivedAction.payload

  const url = Endpoint.SUBSCRIPTION_STATUS.replace('{workspaceId}', encodeURI(workspaceId))

  const action: ActionGetSubscriptionsStatus = yield generalFetch(ActionTypeAPIData.GET_SUBSCRIPTION_STATUS, () => API.get(
    url,
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      },
    },
    {
      endpoint: Endpoint.SUBSCRIPTION_STATUS
    }
  ))

  const getsubscriptionStatusAction: ActionGetSubscriptionsStatus = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(getsubscriptionStatusAction)

  if (getsubscriptionStatusAction.payload.request.state !== APIRequestState.OK) return
  if (!getsubscriptionStatusAction.payload.request.data) return
}

/** Watcher of get subscription status */
export function* getSubscriptionStatusWatcher() {
  yield all([
    takeEvery((action: ActionGetSubscriptionsStatus) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_SUBSCRIPTION_STATUS]), getSubscriptionStatusSaga),
  ])
}
