import { FC, Fragment, useCallback, useLayoutEffect } from 'react'
import { GalleryStagingStep, useGalleryStagingFlow } from './context'
import { ProductType, STAGING_PRODUCT_KINDS } from 'constants/product'

import { APIRequestState } from 'constants/API'
import { ActionPage } from 'components/common/ActionPage'
import Box from '@mui/material/Box'
import { GRAY_900 } from 'constants/styling/theme'
import { GalleryStagingFlowFooter } from './GalleryStagingFlowFooter.component'
import { GalleryStagingFlowList } from '../GalleryStagingFlowList'
import { GalleryStagingFlowSelectionStep } from '../GalleryStagingFlowSelectionStep'
import { GalleryStagingFlowStatus } from '../GalleryStagingFlowStatus'
import { GalleryStagingFlowUpsell } from '../GalleryStagingFlowUpsell'
import { Path } from 'constants/router'
import { ProductDTO } from 'models/product'
import Stack from '@mui/material/Stack'
import { StagingFlow } from 'components/common/StagingFlow/main'
import Typography from '@mui/material/Typography'
import { useClientGallery } from 'components/pages/Client/ClientGallery/_main/contexts/ClientGallery.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryStagingFlowList } from './context/GalleryStagingFlowList.context'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

/**
 * Gallery staging flow controller
 * @example <GalleryStagingFlowController />
 */
export const GalleryStagingFlowController: FC = () => {

  const navigate = useNavigate()

  const { t } = useTranslation(['gallery_staging_flow'])

  const {
    currentStepIndex,
    stagingSteps,
    stagingRequest,
    stagingRequestState,
    isAdvanceStagingPurchaseModalOpen,
    setActiveStep,
    cleanupStaging,
    initializeStaging,
  } = useGalleryStagingFlow()
  const {
    stagingAssignments,
    isOneStagingSelection
  } = useGalleryDeal()
  const { initializeStagingListing } = useGalleryStagingFlowList()

  const { assignmentId } = useClientGallery()

  // Initialize staging list context
  useLayoutEffect(() => {

    if (isOneStagingSelection) {
      const stagingProduct = stagingAssignments[0].products.find(
        ({ kind, type }) => STAGING_PRODUCT_KINDS.has(kind) && type !== ProductType.STAGING_360
      ) as ProductDTO

      initializeStaging(stagingAssignments[0].id, stagingProduct)
    } else {
      initializeStagingListing()
      setActiveStep(GalleryStagingStep.STAGING_LIST)
    }

    // initializeStaging and initializeStagingListing shouldn't be included in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOneStagingSelection, stagingAssignments])

  /** Clears state and returns to the gallery. */
  const onActionPageClose = useCallback(async () => {
    navigate(Path.GALLERY.replace(':id', assignmentId))
    cleanupStaging()
  }, [assignmentId, cleanupStaging, navigate])

  return (
    <ActionPage
      id='gallery-staging-flow'
      title={t('title')}
      flowStepperSteps={stagingSteps.map((stepKey) => t(`steps.${stepKey}`))}
      flowStepperActiveStep={currentStepIndex}
      footerChildren={<GalleryStagingFlowFooter />}
      onClose={onActionPageClose}
    >
      <Stack
        width="100%"
        justifyContent="stretch"
        alignItems="center"
        height="100%"
        padding="6rem 8rem"
        boxSizing="border-box"
        sx={{ overflowY: 'scroll' }}
      >
        <Box width="100%" maxWidth="1200px">

          {stagingSteps[currentStepIndex] === GalleryStagingStep.STAGING_LIST &&
            <GalleryStagingFlowList />
          }

          {stagingSteps[currentStepIndex] === GalleryStagingStep.VISUAL_SELECTION &&
            <GalleryStagingFlowSelectionStep />
          }

          {stagingSteps[currentStepIndex] === GalleryStagingStep.VISUAL_STAGING &&

            <Fragment>

              {/** STEP HEADER */}
              {stagingRequestState === APIRequestState.BEFORE_START &&
                <Stack spacing={.5} marginBottom={4}>

                  <Typography color={GRAY_900} variant='text-xl' fontWeight={600}>
                    {t('staging_step.title')}
                  </Typography>

                  <Typography variant='text-sm'>
                    {t('staging_step.description')}
                  </Typography>

                </Stack>
              }

              {/** STEP STAGING FLOW CONTENT OR STATUS */}
              {stagingRequestState !== APIRequestState.BEFORE_START && !isAdvanceStagingPurchaseModalOpen
                ? <GalleryStagingFlowStatus status={stagingRequestState} errorMessage={stagingRequest?.error} />
                : <StagingFlow />
              }

            </Fragment>
          }

          {/** UPSELL PAYMENT - ADVANCE STAGING */}
          <GalleryStagingFlowUpsell />

        </Box>
      </Stack>
    </ActionPage>
  )
}
