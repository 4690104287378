import { BEIGE_600, GRAY_900 } from 'constants/styling/theme'
import { FC, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import { AnalyticsEvent } from 'utils/analytics'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper/BorderBoxWrapper.component'
import { CustomerRating } from 'components/pages/Gallery/VisualDownloadPopup/CustomerRating'
import { MUIButton } from 'components/common/MUIButton'
import Modal from 'components/common/Modals/Modal/Modal'
import styles from './ClientGalleryVirtualVisitRating.module.sass'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery virtual visit Rating component
 * 
 * @example
 * <ClientGalleryVirtualVisitRating />
 */

export const ClientGalleryVirtualVisitRating: FC = () => {
  const { t } = useTranslation(['matterport_rating'])

  const [isRatingOpen, setIsRatingOpen] = useState(false)

  const {
    logGalleryEvent,
    product,
  } = useGalleryAssignment()

  const onHandlePopup = (event: AnalyticsEvent) => {
    setIsRatingOpen(!isRatingOpen)
    logGalleryEvent(event, { productKind: product?.kind })
  }

  return (
    <>

      <BorderBoxWrapper
        id="csat-animation"
        backgroundColor={BEIGE_600}
        paddingY={4}
        paddingX={6}
        className={styles.boxShadowPulse}
      >
        <Stack direction="column" alignItems="center">
          <Typography variant="text-md" color={GRAY_900} fontWeight={600} marginBottom={0.4}>{t('rating_cta_title')}</Typography>

          <Typography variant="text-sm" fontWeight="regular" marginBottom={1.6} textAlign='center'>{t('rating_cta_subtitle')}</Typography>

          <MUIButton
            type='secondaryBorder'
            onClick={() => onHandlePopup(AnalyticsEvent.GALLERY_VIRTUAL_VISIT_OPEN_CSAT)}
          >
            {t('rating_cta_button')}
          </MUIButton>
        </Stack>
      </BorderBoxWrapper>

      <Modal
        isOpen={isRatingOpen}
        onClose={() => onHandlePopup(AnalyticsEvent.GALLERY_VIRTUAL_VISIT_CLOSE_CSAT)}
        onClickOutside={() => onHandlePopup(AnalyticsEvent.GALLERY_VIRTUAL_VISIT_CLOSE_CSAT)}
        className={styles.modalWrapper}
        modalContentClassName={styles.modalContent}
        scrollableContentClassName={styles.scrollableContent}
      >
        <div className={styles.csat}>
          <h3>{t('csat_subtitle')}</h3>
          <h1>{t('csat_title')}</h1>
          <CustomerRating />
        </div>
      </Modal>

    </>
  )
}
