import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { GalleryFilter, useClientGallery } from '../_main/contexts/ClientGallery.context'
import { Grid, Stack } from '@mui/material'

import { APIRequestErrorType } from 'constants/API'
import { AnalyticsEvent } from 'utils/analytics'
import { ClientGalleryPhotoSectionActionFilterPanels } from './ClientGalleryPhotoSectionActionFilterPanels'
import { ClientGalleryPhotoSectionFilteredFavourites } from './ClientGalleryPhotoSectionFilteredFavourites.module'
import { ClientGalleryPhotoSectionFilteredUnpurchased } from './ClientGalleryPhotoSectionFilteredUnpurchased'
import { ClientGalleryPhotoSectionNotPurchasedAny } from './ClientGalleryPhotoSectionNotPurchasedAny.module'
import { ClientGalleryPhotoStagingCard } from '../ClientGalleryPhoto/ClientGalleryPhotoStagingCard.module'
import { Color } from 'constants/assets'
import { GalleryImage } from 'components/common/Gallery/GalleryImage'
import { GallerySection } from 'components/common/Gallery/GallerySection'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import ReactLoading from 'react-loading'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryConstants } from 'components/pages/Gallery/_main/contexts/GalleryConstants.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryOrder } from 'components/pages/Gallery/_main/contexts/GalleryOrder.context'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import { useGalleryVisualSelection } from 'components/pages/Gallery/_main/contexts/GalleryVisualSelection.context'
import { useGalleryVisualType } from 'components/pages/Gallery/_main/contexts/GalleryVisualType.context'
import { useGalleryVisuals } from 'components/pages/Gallery/_main/contexts/GalleryVisuals.context'
import { useGalleryVisualsMeta } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'
import { useImageRegex } from 'utils/hooks'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

enum ListingType {
  PURCHASED = 'PURCHASED',
  UNPURCHASED = 'UNPURCHASED',
  FAVOURITES_PURCHASED = 'FAVOURITES_PURCHASED',
  FAVOURITES_UNPURCHASED = 'FAVOURITES_UNPURCHASED',
}

enum GridColumnSize {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

/**
 * @interface Props
 */
interface Props {
  /** The list of favorite visuals */
  favorited: Set<string>
  /** setState action to open expanded visual popup */
  setIsExpandedVisualOpen: Dispatch<SetStateAction<boolean>>
  /** setState action to set expanded visual name */
  setExpandedVisualName: Dispatch<SetStateAction<string | undefined>>
  /** setState action to toggle favorite on image */
  toggleFavoriteOnImage: (filename?: string) => void
  /** Whether the favorite button has disabled clicking */
  isFavoriteDisabled: boolean
}

/**
 * Client gallery photo section.
 * 
 * @example <ClientGalleryPhotoSection />
 */
export const ClientGalleryPhotoSection: FC<Props> = ({
  favorited,
  setIsExpandedVisualOpen,
  setExpandedVisualName,
  toggleFavoriteOnImage,
  isFavoriteDisabled = false
}) => {
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const { stagingAssignments } = useGalleryDeal()
  const { sortEntriesFunction } = useGalleryOrder()

  const {
    purchasedVisualsKeys,
    purchasedVisualsExist,
    unPurchasedVisualsExist
  } = useGalleryVisualsMeta()

  const {
    downloadVisualsEntries,
    listedVisuals
  } = useGalleryVisuals()

  const {
    thumbnailType,
    webType,
  } = useGalleryVisualType()

  const {
    selected,
    toggleSelectOnImage,
  } = useGalleryVisualSelection()

  const {
    assignmentId,
    filter,
    isToolBarOpen,
    setIsToolBarOpen,
    enableStagingFlow
  } = useClientGallery()

  const {
    isVideo,
    isFloorPlan
  } = useGalleryProduct()

  const {
    product,
    logGalleryEvent,
  } = useGalleryAssignment()

  const { stagesUnlockingStagingFlow } = useGalleryConstants()

  const imageNameReplacePattern = useImageRegex(assignmentId)

  const onOpenExpandedVisualPopup = useCallback((name?: string) => {
    if (!name) return
    setIsExpandedVisualOpen(true)
    setExpandedVisualName(name)
    logGalleryEvent(AnalyticsEvent.GALLERY_PHOTO_FULL_PREVIEW, {
      productType: product?.type
    })
  }, [logGalleryEvent, product?.type, setExpandedVisualName, setIsExpandedVisualOpen])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisuals.map(visual => {
      const reactKey = visual.name
      return [visual.name, reactKey, (
        <GalleryImage key={reactKey}>
          <div className="text">
            <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          </div>
        </GalleryImage>
      )]
    })

    return tupleArray
  }, [listedVisuals])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes], index) => {
      const img = allTypes?.[thumbnailType]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${thumbnailType}`)
      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const filename = img.file?.name ?? ''
      const label = filename.replace(imageNameReplacePattern, '')
      const reactKey = key
      return [key, reactKey, (
        <GalleryImage
          key={reactKey}
          label={label}
          imageUrl={img.signedUrl}
          errorMessage={t('error_not_found_and_retry')}
          isError={isError}
          isSelectable={!isError}
          isSelected={selected.has(img.file?.name ?? '')}
          isHoverSelectable={!!purchasedVisualsExist && !isToolBarOpen && !selected.size}
          isFavoriteSelectable={!isError}
          isFavorite={favorited.has(img.file?.name ?? '')}
          isFavoriteHoverActive={(!isMobileView || !isToolBarOpen)}
          isFavoriteDisabled={isFavoriteDisabled}
          onSelect={() => {
            toggleSelectOnImage(img.file?.name)
            setIsToolBarOpen(true)
          }}
          onFavSelect={() => {
            toggleFavoriteOnImage(img.file?.name)
          }}
          onClick={() => {
            if (isMobileView && isToolBarOpen) return toggleSelectOnImage(img.file?.name)
            if (!(webType && allTypes?.[webType]?.signedUrl)) return
            onOpenExpandedVisualPopup(img.file?.name)
          }}
        />
      )]
    })

    return tupleArray
  }, [downloadVisualsEntries, favorited, imageNameReplacePattern, isMobileView, isToolBarOpen, onOpenExpandedVisualPopup, purchasedVisualsExist, selected, setIsToolBarOpen, t, thumbnailType, toggleFavoriteOnImage, toggleSelectOnImage, webType, isFavoriteDisabled])

  const createVisualListing = (filteredEntries: [string, [string, JSX.Element]][], type: ListingType) => {
    const entriesCount = filteredEntries.length
    const isTwoColumnViewProduct = isVideo || (isFloorPlan && entriesCount > 1)
    const isOneColumnViewProduct = isFloorPlan && entriesCount === 1

    const getGridColumns = (size: GridColumnSize) => {
      if (isOneColumnViewProduct) return 12
      if (isTwoColumnViewProduct) return 6

      return size === GridColumnSize.DEFAULT ? 3 : 4
    }

    const addStagingTodoInfoCardIfNeeded = () => {
      const assignmentsToSet = stagingAssignments.filter((assignment) => stagesUnlockingStagingFlow.has(assignment.stage))
      if (!enableStagingFlow || type !== ListingType.PURCHASED || !assignmentsToSet.length) return

      if (filteredEntries.length + 1 <= 3) {
        filteredEntries.push(['stagingTodoInfoCard', ['stagingTodoInfoCard', <ClientGalleryPhotoStagingCard key='staging-card' />]])
      } else {
        filteredEntries.splice(3, 0, ['stagingTodoInfoCard', ['stagingTodoInfoCard', <ClientGalleryPhotoStagingCard key='staging-card' />]])
      }
    }

    addStagingTodoInfoCardIfNeeded()

    return (
      <Grid container spacing={2}>
        {filteredEntries.map(([key, [reactKey, node]]) => (
          <Grid key={reactKey} item xs={isOneColumnViewProduct ? 12 : 6} sm={getGridColumns(GridColumnSize.SMALL)} md={getGridColumns(GridColumnSize.DEFAULT)} lg={getGridColumns(GridColumnSize.DEFAULT)}>
            {node}
          </Grid>
        ))}
      </Grid>
    )
  }

  const visualsListing = (type: ListingType) => {
    const map: Map<string, [string, JSX.Element]> = new Map()
    let sortedEntries: [string, [string, JSX.Element]][]

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())

    switch (type) {
      case ListingType.FAVOURITES_PURCHASED:
        sortedEntries = entries.filter(([visualName, visualProps]) => purchasedVisualsKeys.has(visualName) && visualProps[1].props.isFavorite).sort(sortEntriesFunction)
        break

      case ListingType.FAVOURITES_UNPURCHASED:
        sortedEntries = entries.filter(([visualName, visualProps]) => !purchasedVisualsKeys.has(visualName) && visualProps[1].props.isFavorite).sort(sortEntriesFunction)
        break

      case ListingType.PURCHASED:
        sortedEntries = entries.filter(([visualName]) => purchasedVisualsKeys.has(visualName)).sort(sortEntriesFunction)
        break

      case ListingType.UNPURCHASED:
        sortedEntries = entries.filter(([visualName]) => !purchasedVisualsKeys.has(visualName)).sort(sortEntriesFunction)
        break

      default:
        sortedEntries = []
        break
    }

    return createVisualListing(sortedEntries, type)
  }

  const purchasedVisuals = visualsListing(ListingType.PURCHASED)
  const unPurchasedVisuals = visualsListing(ListingType.UNPURCHASED)
  const favouritesPurchasedVisuals = visualsListing(ListingType.FAVOURITES_PURCHASED)
  const favouritesUnPurchasedVisuals = visualsListing(ListingType.FAVOURITES_UNPURCHASED)

  const showUnpurchasedSection = purchasedVisualsExist && unPurchasedVisualsExist && filter === GalleryFilter.ALL
  const showPurchasedSection = purchasedVisualsExist && filter !== GalleryFilter.FAVORITES
  const showFavouritesSection = filter === GalleryFilter.FAVORITES

  return (
    <Stack marginTop={{ xs: '2.4rem', md: '3.2rem' }}>

      {/** FLOW A - NOT PURCHASED ANY VISUALS YET - SHOWS INFO MESSAGE ABOUT REQUIRED SELECTION & FILTERS ALL + FAVOURITES */}
      {!purchasedVisualsExist &&
        <ClientGalleryPhotoSectionNotPurchasedAny unPurchasedVisuals={unPurchasedVisuals} />
      }

      {/** FLOW B - FILTER ALL - PURCHASED VISUALS - SHOWN THE FILTERS & ACTIONS PANEL WHEN VISUALS WERE SELECTED ALREADY */}
      {purchasedVisualsExist &&
        <ClientGalleryPhotoSectionActionFilterPanels />
      }

      {/** FLOW B - FILTERES PURCHASED ONLY */}
      {showPurchasedSection &&
        <GallerySection>{purchasedVisuals}</GallerySection>
      }

      {/** FLOW B - FILTERES ALL - ADDS UNPURCHASED VISUALS SECTION */}
      {showUnpurchasedSection &&
        <ClientGalleryPhotoSectionFilteredUnpurchased unPurchasedVisuals={unPurchasedVisuals} />
      }

      {/** FLOW B - FILTERES FAVOURITES ONLY */}
      {showFavouritesSection &&
        <ClientGalleryPhotoSectionFilteredFavourites favouritesPurchasedVisuals={favouritesPurchasedVisuals} favouritesUnPurchasedVisuals={favouritesUnPurchasedVisuals} />
      }

    </Stack>
  )
}
