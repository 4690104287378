import { CatalogueOptionProduct, CatalogueProduct } from 'dataQueries/purchase.query'
import { EditingCategory, ProductKind, STAGING_PRODUCT_KINDS } from 'constants/product'
import { Fragment, useCallback, useMemo } from 'react'

import Collapse from '@mui/material/Collapse'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { EditingProductComments } from './EditingProductComments.component'
import { EditingProductUpload } from '../EditingProductUpload'
import { MUICheckCard } from 'components/common/MUICards/MUICheckCard'
import { MUIIntegerCard } from 'components/common/MUICards'
import { ProductCardContent } from '../ProductCardContent/ProductCardContent'
import { ProductCardHeader } from '../ProductCardHeader/ProductCardHeader.component'
import { ProductListHeader } from '../ProductListHeader'
import { Stack } from '@mui/material'
import { StagingProductCardContent } from '../StagingProductCardContent'
import { TransitionGroup } from 'react-transition-group'
import { bigFromFee } from 'utils/price'
import { isIntegerProduct } from 'utils/product'
import { standardDebouncedLogAnalyticsEvent } from 'utils/analytics'
import { standardTimeoutConfig } from 'utils/animations'
import { useDraggingFile } from 'utils/hooks'
import { usePurchaseFlowConfig } from '../../_main/contexts/PurchaseFlowConfig.context'
import { usePurchaseFlowProducts } from '../../_main/contexts/PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

const productComparison = (prodA: CatalogueProduct, prodB: CatalogueProduct) => bigFromFee(prodA.feePrice).minus(bigFromFee(prodB.feePrice)).toNumber()
const renderingProductKinds = new Set([ProductKind.RESIDENTIAL_RENDERING, ProductKind.COMMERCIAL_RENDERING])

/**
 * @interface Props for the EditingProductListing component.
 */
interface Props {
  /** Whether to display type heading element */
  displayTypeHeading?: boolean
  /** Whether to display only selected types, products and options */
  onlySelected?: boolean
  /** Whether to disabled selecting/deselecting */
  disabledSelection?: boolean
  /** Whether the product listing is disabled and grayed out */
  disabled?: boolean
}

/**
 * @component Component which lists all editing products in purchase flow
 * @example
 * <EditingProductListing />
 */
export const EditingProductListing: React.FC<Props> = ({
  displayTypeHeading = true,
  onlySelected = false,
  disabledSelection = false,
  disabled = false,
}) => {
  const { getProductCatalogue, availableProductKindsPerSegment, selectedCategory, catalogueCurrency, catalogueDiscount } = usePurchaseFlowConfig()
  const {
    selectedFilterKinds,
    selectedSegment,
    selectedProducts,
    selectedProductOptions,
    selectProduct,
    selectOptionProduct,
    unselectOptionProduct,
    unselectProduct
  } = usePurchaseFlowProducts()

  const { t } = useTranslation(['order', 'segment', 'product_kind', 'product_kind_description', 'gallery'])

  const isDraggingFile = useDraggingFile()

  const handleLogOptionSelection = useCallback((quantity: number, productKind: ProductKind, parentProductKind: ProductKind) => {
    standardDebouncedLogAnalyticsEvent('option_kind_selected', { quantity, productKind, parentProductKind })
  }, [])

  // Editing products do not have ProductType => using 'EDITING_PRODUCT' for easy filtering
  const handleLogProductSelection = useCallback((quantity: number, productKind: ProductKind) => {
    standardDebouncedLogAnalyticsEvent('product_selected', { quantity, productKind, productType: 'EDITING_PRODUCT' })
  }, [])

  const kindsToRender = useMemo(() => {
    if (!selectedSegment) return []

    const toRender = (availableProductKindsPerSegment[selectedSegment] || []).filter(({ productKind }) => selectedFilterKinds.has(productKind))
    return toRender
  }, [availableProductKindsPerSegment, selectedFilterKinds, selectedSegment])

  if (!selectedSegment) return null

  return (
    <DynamicQueryContent
      query={getProductCatalogue}
    >
      <Fragment>
        {kindsToRender
          .map(({ productKind, products }) => {
            return (
              <Fragment key={productKind}>

                {/* PRODUCT TITLE */}
                {displayTypeHeading &&
                  <ProductListHeader
                    title={t(`product_kind:${productKind}`)}
                  />
                }

                {/** PRODUCT LIST */}
                <Stack gap={2} marginTop={2}>
                  {products
                    .filter(product => (onlySelected && !!selectedProducts[product.id]) || !onlySelected)
                    .sort(productComparison)
                    .map((product) => {
                      const isInteger = !!selectedCategory && isIntegerProduct(selectedCategory, product.kind)
                      const selectedQuantity = selectedProducts[product.id]?.quantity ?? 0
                      const isProductPreview = disabledSelection && onlySelected
                      const selectedProductComments = selectedProducts[product.id]?.comment

                      const handleNumberInputChange = (value: number, option: CatalogueOptionProduct) => {
                        const normalizedValue = Math.min(100, Math.max(0, value || 0))

                        if (normalizedValue === 0) unselectOptionProduct(product.id, option.id)
                        else selectOptionProduct(product.id, option, normalizedValue)

                        handleLogOptionSelection(normalizedValue, option.kind, product.kind)
                      }

                      const handleCheckboxChange = (checked: boolean, option: CatalogueOptionProduct) => {
                        if (checked) selectOptionProduct(product.id, option, 1)
                        else unselectOptionProduct(product.id, option.id)

                        handleLogOptionSelection(checked ? 1 : 0, option.kind, product.kind)
                      }

                      const handleCheckCardChange = () => {
                        if (disabledSelection) return

                        const willBeUnchecked = selectedQuantity && selectedQuantity > 0

                        if (willBeUnchecked) unselectProduct(product.id)
                        else selectProduct(product, 1)

                        handleLogProductSelection(willBeUnchecked ? 0 : 1, product.kind)
                      }

                      const handleQuantityChange = (quantity?: number) => {
                        if (disabledSelection) return
                        if (!quantity || quantity <= 0) unselectProduct(product.id)
                        else selectProduct(product, quantity)

                        handleLogProductSelection(quantity ?? 0, product.kind)
                      }

                      const hasOptionsOrIsKindRendering = (Object.values(product.options).length > 0 || !renderingProductKinds.has(product.kind))

                      const expandableContent = (
                        <Fragment>
                          {/* OPTIONS */}
                          {(selectedCategory === EditingCategory.STAGING && onlySelected)
                            ? <StagingProductCardContent productId={product.id} />
                            : (
                              <ProductCardContent
                                product={product}
                                onlySelected={onlySelected}
                                segmentKey={selectedSegment}
                                typeOrKindKey={productKind}
                                discount={catalogueDiscount}
                                currency={catalogueCurrency}
                                disabledSelection={disabledSelection}
                                onNumberInputChange={handleNumberInputChange}
                                onCheckboxChange={handleCheckboxChange}
                              />
                            )
                          }

                          {/* PRODUCT UPLOAD */}
                          {!renderingProductKinds.has(product.kind) &&
                            <Fragment>

                              {!isProductPreview &&
                                <EditingProductUpload
                                  product={product}
                                  isDraggingFile={isDraggingFile}
                                  disabledSelection={disabledSelection}
                                />
                              }

                              {/** EDITING COMMENTS */}
                              {selectedCategory === EditingCategory.EDITING &&
                                <EditingProductComments
                                  productId={product.id}
                                  disabledSelection={disabledSelection}
                                />
                              }
                            </Fragment>
                          }

                        </Fragment>
                      )

                      const getExpandableContent = () => {
                        if (isProductPreview) {
                          if (!!selectedProductComments || !!selectedProductOptions[product.id] || STAGING_PRODUCT_KINDS.has(product.kind)) {
                            return expandableContent
                          }
                          return undefined
                        }
                        if (hasOptionsOrIsKindRendering) return expandableContent
                        return undefined
                      }

                      return (
                        <TransitionGroup component={null} key={`${selectedSegment}_${productKind}_${product.id}`}>
                          <Collapse
                            in={true}
                            timeout={standardTimeoutConfig}
                          >
                            <Stack>

                              {!isInteger &&
                                <MUICheckCard
                                  disabled={disabled}
                                  onCheck={handleCheckCardChange}
                                  showSelectElement={!isProductPreview}
                                  checked={selectedQuantity > 0}
                                  isHeaderClickable={!disabledSelection}
                                  disabledSelection={disabledSelection}
                                  expandableContent={getExpandableContent()}
                                >
                                  {!!catalogueCurrency && !!selectedCategory &&
                                    <ProductCardHeader
                                      product={product}
                                      disabled={disabled}
                                      category={selectedCategory}
                                      currency={catalogueCurrency}
                                      displayPrice={!isProductPreview}
                                      quantity={isProductPreview ? selectedQuantity : undefined}
                                    />
                                  }
                                </MUICheckCard>
                              }

                              {isInteger &&
                                <MUIIntegerCard
                                  disabled={disabled}
                                  hideCounterInput={isProductPreview}
                                  onQuantityChange={handleQuantityChange}
                                  defaultQuantity={selectedQuantity}
                                  expandableContent={getExpandableContent()}
                                >
                                  {!!catalogueCurrency && !!selectedCategory &&
                                    <ProductCardHeader
                                      product={product}
                                      disabled={disabled}
                                      category={selectedCategory}
                                      currency={catalogueCurrency}
                                      displayPrice={!isProductPreview}
                                      quantity={isProductPreview ? selectedQuantity : undefined}
                                    />
                                  }
                                </MUIIntegerCard>
                              }

                            </Stack>
                          </Collapse>
                        </TransitionGroup>
                      )

                    })

                  }
                </Stack>

              </Fragment>
            )
          })
        }
      </Fragment>
    </DynamicQueryContent>
  )
}
