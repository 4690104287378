import { useVisualEditingSubmitExpress, useVisualsEditingTemplateOptions } from 'dataQueries'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { archiveEditingVisuals, purgeArchiveEditingVisuals } from 'redux/Individual/VisualsEditing/ArchivedEditingVisuals'

import { useUserData } from 'components/contexts/UserDataContext'
import { useWorkspacesProfileContext } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'
import { useGalleryVisualSelection } from 'components/pages/Gallery/_main/contexts/GalleryVisualSelection.context'
import { useVisualsEditingDownload } from 'components/pages/VisualsEditing/VisualsEditingDownload.context'
import { exportTypeToEditedVisualsType } from 'constants/visualEditing/visualEditingFiles'
import constate from 'constate'
import { Nullable } from 'models/helpers'
import { useDispatch } from 'react-redux'
import { ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import { logAnalyticsEvent } from 'utils/analytics'
import { useGalleryVisualsMeta } from '../_main/contexts/GalleryVisualsMeta.context'

export const [VisualsEditingSectionContextProvider, useVisualsEditingSection] = constate(({
  assignmentId,
}: {
  assignmentId: Nullable<string>
}) => {
  const dispatch = useDispatch()
  const { selected } = useGalleryVisualSelection()
  const { purchasedVisualsExist } = useGalleryVisualsMeta()
  const {
    currentUserWorkspace,
    baseUserData,
    organizationData,
    hasUserActiveSubscription
  } = useUserData()
  const {
    allUserWorkspacesData,
    allUserWorkspacesResponse,
    workspaceDataOfUser
  } = useWorkspacesProfileContext()
  const {
    downloadUrl,
    hasDownloadFailed,
  } = useVisualsEditingDownload()

  const currentWorkspaceData = allUserWorkspacesData?.find((workspace) => workspace.workspaceId === currentUserWorkspace?.id)
  const visualEditingFilenames = Array.from(selected).filter(filename => filename.startsWith(String(assignmentId)))

  const templateId = useMemo(() => !!currentWorkspaceData?.visualEditingTemplates.length ? currentWorkspaceData?.visualEditingTemplates[0].id : null, [currentWorkspaceData?.visualEditingTemplates])
  const isTemplateLoading = useMemo(() => allUserWorkspacesResponse.isPending, [allUserWorkspacesResponse.isPending])

  const visualsEditingExpressTriggered = useRef(false)
  const visualEditingSubmitExpress = useVisualEditingSubmitExpress()
  const visualEditingSubmitExpressData = visualEditingSubmitExpress?.data
  const isSubmitFailed = visualEditingSubmitExpress.isError

  /** Disabled visuals editing for subscription clients with no purchased visuals exist and no selected visuals */
  const isVisualsEditingDisabled = useMemo(() => hasUserActiveSubscription && (!purchasedVisualsExist || !selected.size), [hasUserActiveSubscription, purchasedVisualsExist, selected.size])
  /** Disabled submit express for clients with no template, no purchased visuals exist and no selected visuals */
  const isSubmitExpressDisabled = useMemo(() => !(templateId && purchasedVisualsExist && selected.size), [purchasedVisualsExist, selected.size, templateId])

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useState(false)
  const [isEditProcessingPopupOpen, setIsEditProcessingPopupOpen] = useState(false)

  const savedTemplateOptionsResponse = useVisualsEditingTemplateOptions(currentUserWorkspace?.id, templateId, !!templateId)
  const savedTemplateOptionsData = savedTemplateOptionsResponse?.data

  const templateVisualsType = useMemo(() => savedTemplateOptionsData ? exportTypeToEditedVisualsType[savedTemplateOptionsData.export] : '', [savedTemplateOptionsData])


  const logEditEvent = useCallback((eventName: string, params?: {}) => {
    logAnalyticsEvent(eventName, {
      userEmail: baseUserData?.email,
      organizationId: organizationData?.id,
      userRole: workspaceDataOfUser?.role,
      assignmentId,
      ...params
    })
  }, [assignmentId, baseUserData?.email, organizationData?.id, workspaceDataOfUser?.role])

  const onSubmitExpress = useCallback(() => {
    if (!visualEditingFilenames || !templateId || !assignmentId) return
    visualEditingSubmitExpress.mutate({
      assignmentId,
      templateId,
      filenames: visualEditingFilenames,
      types: [templateVisualsType]
    })
    setIsEditProcessingPopupOpen(true)
    visualsEditingExpressTriggered.current = true
    logEditEvent('Apply Template - Express')
  }, [assignmentId, logEditEvent, templateId, templateVisualsType, visualEditingFilenames, visualEditingSubmitExpress])

  const retryExpressDownload = useCallback(() => {
    const editingJobId = visualEditingSubmitExpressData?.data.id ?? ''
    if (hasDownloadFailed) dispatch(archiveEditingVisuals(assignmentId, editingJobId, [templateVisualsType], visualEditingFilenames, ArchiveVisualsTag.CLIENT_ARCHIVE, false))
    if (!!downloadUrl) window.location.replace(downloadUrl)
  }, [visualEditingSubmitExpressData?.data.id, hasDownloadFailed, dispatch, assignmentId, templateVisualsType, visualEditingFilenames, downloadUrl])

  const closeProccessingPopup = () => {
    if (!assignmentId) return
    visualEditingSubmitExpress.reset()
    dispatch(purgeArchiveEditingVisuals(assignmentId))
    setIsEditProcessingPopupOpen(false)
    visualsEditingExpressTriggered.current = false
    logEditEvent('Apply Template - Express - Exit Processing')
  }

  const closeDownloadPopup = () => {
    setIsDownloadPopupOpen(false)
    visualsEditingExpressTriggered.current = false
  }

  useEffect(() => {
    if (!!downloadUrl && visualsEditingExpressTriggered.current) {
      setIsDownloadPopupOpen(true)
      setIsEditProcessingPopupOpen(false)
      !!downloadUrl && window.location.replace(downloadUrl)
      logEditEvent('Apply Template - Express - Download Bulk Editing')
    }
  }, [assignmentId, downloadUrl, isSubmitFailed, logEditEvent])

  return {
    assignmentId,
    visualEditingFilenames,
    retryExpressDownload,
    currentWorkspaceData,
    templateId,
    isTemplateLoading,
    savedTemplateOptionsData,
    templateVisualsType,
    visualEditingSubmitExpress,
    logEditEvent,
    isVisualsEditingDisabled,
    isSubmitExpressDisabled,
    onSubmitExpress,
    isEditProcessingPopupOpen,
    isSubmitFailed,
    closeProccessingPopup,
    isDownloadPopupOpen,
    closeDownloadPopup
  }
})
