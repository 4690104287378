import { BEIGE_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, useCallback } from 'react'

import { AnalyticsEvent } from 'utils/analytics'
import { AssignmentStage } from 'constants/assignment'
import { CircleIcon } from 'components/common/CircleIcon'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUITooltip } from 'components/common/MUITooltip'
import { ReactComponent as OrderTypeStagingIcon } from 'assets/img/icons/order_type_staging.svg'
import { SlideFadeAppearHide } from 'utils/animations'
import { Typography } from '@mui/material'
import { useClientGallery } from '../_main/contexts/ClientGallery.context'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryConstants } from 'components/pages/Gallery/_main/contexts/GalleryConstants.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryVisualsMeta } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery merged staging type filter into one tab.
 *
 * @example <ClientGalleryStagingTypeFilter />
 */
export const ClientGalleryStagingTypeFilter: FC = () => {
  const { t } = useTranslation(['gallery'])
  const { purchasedVisualsExist } = useGalleryVisualsMeta()
  const { isAssignmentDelivered, logGalleryEvent } = useGalleryAssignment()
  const { openStagingFlow } = useClientGallery()
  const { stagingAssignments } = useGalleryDeal()
  const { stagesUnlockingStagingFlow } = useGalleryConstants()

  const assignmentsToSet = stagingAssignments.filter((assignment) => stagesUnlockingStagingFlow.has(assignment.stage))
  const isStagingFlowAllowed = isAssignmentDelivered && purchasedVisualsExist && !!assignmentsToSet.length
  const isAnyAssignmentInProgress = stagingAssignments.some(
    (assignment) => ![AssignmentStage.VISUALS_SENT_TO_CLIENT, AssignmentStage.MISSION_ORDER_PLACED].includes(assignment.stage)
  )
  const isAnyStagingInProgress = !isStagingFlowAllowed && isAnyAssignmentInProgress
  const showStagingDisabledTooltip = !isAssignmentDelivered || !purchasedVisualsExist
  const isStagingDisabled = showStagingDisabledTooltip && isAnyAssignmentInProgress
  const tooltipContent = !isAssignmentDelivered ? 'not_delivered_info' : 'disabled_staging_tooltip_info'

  const getTooltip = () => {
    if (showStagingDisabledTooltip) return `client_gallery.staging_todo.${tooltipContent}`
    else if (isAnyStagingInProgress) return 'client_gallery.staging_in_progress_tooltip_info'
    else return ''
  }

  const handleStagingFlow = useCallback(() => {
    if (isStagingFlowAllowed) {
      logGalleryEvent(AnalyticsEvent.CLICK_GALLERY_TAB_STAGE)
      openStagingFlow()
    }
  }, [isStagingFlowAllowed, logGalleryEvent, openStagingFlow])

  return (
    <MUITooltip content={showStagingDisabledTooltip || isAnyStagingInProgress ? t(getTooltip()) : undefined}>
      <FilterTab
        key="stagingTab"
        height="5.6rem"
        isDisabled={isStagingDisabled}
        icon={
          <CircleIcon
            size="3.2rem"
            circleColor={BEIGE_600}
            icon={<OrderTypeStagingIcon />}
          />
        }
        label={
          <Typography
            variant="text-sm"
            fontWeight="semiBold"
            color={!purchasedVisualsExist ? GRAY_700 : GRAY_900}
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              lineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {t('client_gallery.staging_todo.tab_title')}
          </Typography>
        }
        badge={
          <SlideFadeAppearHide visible={isStagingFlowAllowed || isAnyStagingInProgress} delay={700}>
            <>
              {isStagingFlowAllowed &&
                <MUIBadge
                  label={t('order_informations.staging_todo_label')}
                  size="sm"
                  color={!purchasedVisualsExist ? 'gray' : 'orange'}
                />
              }
              {isAnyStagingInProgress &&
                <MUIBadge
                  label={t('order_informations.staging_in_progress_label')}
                  size="sm"
                  color="blue"
                />
              }
            </>
          </SlideFadeAppearHide>
        }
        sx={{
          maxWidth: '35rem',
          flexShrink: 0,
        }}
        onClick={handleStagingFlow}
      />
    </MUITooltip>
  )
}
