import { ArchiveVisualsTag, archiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals'
import { CurrentActionType, useClientGallery } from 'components/pages/Client/ClientGallery/_main/contexts/ClientGallery.context'
import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { purgeSelectVisuals, selectVisuals } from 'redux/Individual/Visual/SelectVisuals'

import { GalleryPaymentSuccessPopup } from '../GalleryPaymentSuccessPopup'
import { Path } from 'constants/router'
import { StripeError } from '@stripe/stripe-js'
import { UpsellPayment } from 'components/pages/Gallery/UpsellPayment'
import { ZERO_DECIMAL } from 'constants/misc'
import { decimalFromBig } from 'utils/price'
import { listVisuals } from 'redux/Individual/Visual/ListVisuals'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useGalleryAdditionalVisuals } from 'components/pages/Gallery/_main/contexts/GalleryAdditionalVisuals.context'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryVisualSelection } from 'components/pages/Gallery/_main/contexts/GalleryVisualSelection.context'
import { useGalleryVisualType } from 'components/pages/Gallery/_main/contexts/GalleryVisualType.context'
import { useGalleryVisualsDownloadArchive } from 'components/pages/Gallery/_main/contexts/GalleryVisualsDownloadArchive.context'
import { useNavigate } from 'react-router-dom'
import { useVisualsEditingSection } from 'components/pages/Gallery/VisualsEditingSectionController/VIsualsEditingSection.context'

/**
 * Gallery upsell payment component.
 * @example <GalleryUpsellPayment />
 */
export const GalleryUpsellPayment: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['gallery'])
  const { roles } = useAuth0()
  const { product } = useGalleryAssignment()
  const { onSubmitExpress } = useVisualsEditingSection()
  const { setDownloadModalOpen } = useGalleryVisualsDownloadArchive()
  const {
    assignmentId,
    currentActionType
  } = useClientGallery()
  const {
    selected,
    selectVisualsState,
  } = useGalleryVisualSelection()
  const {
    normalizedOriginalType,
    transformTypes,
  } = useGalleryVisualType()
  const {
    currency,
    selectedAdditionalVisualsCount,
    totalForAdditionalVisualsAfterDiscount,
    additionalVisualProductId,
    isAdditionalVisualsPurchaseModalOpen,
    setIsAdditionalVisualsPurchaseModalOpen,
  } = useGalleryAdditionalVisuals()

  // Relist visuals if stripe payment fails and user leaves purchase modal without finishing the payment
  const handleAfterAdditionalPurchaseModalClose = useCallback((stripeError: StripeError | null) => {
    if (stripeError) {
      dispatch(listVisuals(assignmentId, normalizedOriginalType))
    }

    dispatch(purgeSelectVisuals(assignmentId))
  }, [dispatch, assignmentId, normalizedOriginalType])

  const handlePaymentSuccessAction = useCallback(() => {
    setIsAdditionalVisualsPurchaseModalOpen(false)
    switch (currentActionType) {
      case CurrentActionType.DOWNLOAD:
        dispatch(archiveVisuals(assignmentId, transformTypes(), Array.from(selected), ArchiveVisualsTag.CLIENT_ARCHIVE, roles.isAdmin))
        setDownloadModalOpen(true)
        break
      case CurrentActionType.APPLY_TEMPLATE_AND_DOWNLOAD:
        onSubmitExpress()
        break
      case CurrentActionType.EDITING:
        navigate(Path.GALLERY_VISUALS_EDIT.replace(':id', assignmentId.toString()))
        break
      default:
        break
    }
  }, [assignmentId, currentActionType, dispatch, navigate, onSubmitExpress, roles.isAdmin, selected, setDownloadModalOpen, setIsAdditionalVisualsPurchaseModalOpen, transformTypes])

  const isDownloadAction = useMemo(() => [CurrentActionType.DOWNLOAD, CurrentActionType.APPLY_TEMPLATE_AND_DOWNLOAD].includes(currentActionType), [currentActionType])
  const successSubtitle = useMemo(() => isDownloadAction ? t('additional_visuals_purchase.success_text') : t('additional_visuals_purchase.success_text_editing'), [isDownloadAction, t])
  const buttonLabel = useMemo(() => isDownloadAction ? t('download') : t('edit_visuals_button'), [isDownloadAction, t])

  return (
    <UpsellPayment
      isOpen={isAdditionalVisualsPurchaseModalOpen}
      assignmentId={assignmentId}
      productId={additionalVisualProductId}
      productQuantity={selectedAdditionalVisualsCount}
      total={!!currency ? { value: decimalFromBig(totalForAdditionalVisualsAfterDiscount), currency, discountPercentage: ZERO_DECIMAL } : null}
      beforeConfirmAction={(paymentIntentId, billingProductId) => product?.id && dispatch(selectVisuals(assignmentId, transformTypes(), Array.from(selected), normalizedOriginalType, product.id, billingProductId, paymentIntentId))}
      beforeConfirmActionState={selectVisualsState}
      onClose={() => setIsAdditionalVisualsPurchaseModalOpen(false)}
      afterClosed={handleAfterAdditionalPurchaseModalClose}
      successNode={(
        <GalleryPaymentSuccessPopup
          title={t('additional_visuals_purchase.success_title')}
          subTitle={successSubtitle}
          buttonLabel={buttonLabel}
          onClick={handlePaymentSuccessAction}
        />
      )}
    >
      <Trans t={t} i18nKey="purchase_more_visuals_prompt">
        <span></span>
      </Trans>
    </UpsellPayment>
  )
}
