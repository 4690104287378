import { BLUE_600, CORAL_100, CORAL_600, GRAY_100, GRAY_400, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { ButtonBase, InputBase, Stack, Typography, inputBaseClasses, styled } from '@mui/material'
import React, { ChangeEvent, KeyboardEvent, useMemo } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper/BorderBoxWrapper.component'
import { CopyButton } from '../CopyButton'

export enum InputFieldTypes {
  DEFAULT,
  COPY
}

/**
 * @typedef InputType Available input types
 */
export type InputType = InputFieldTypes.DEFAULT | InputFieldTypes.COPY

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Input type */
  type?: InputType,
  /** Input value */
  value?: string,
  /** Input placeholder */
  placeholder?: string,
  /** Whether the input is read only or not */
  readOnly?: boolean,
  /** Input block title */
  title?: string
  /** Whether input is error or not */
  isError?: boolean
  /** Input error text */
  errorText?: string | null
  /** Input hint text */
  hintText?: string | null
  /** Allow input field with multiple lines. Material UI will automatically treat input as textarea. */
  isMultiline?: boolean
  /** Whether the input is disabled or not */
  disabled?: boolean
  /** OnChange action to update the input value */
  onBlur?: () => void
  /** OnChange action to update the input value */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  /** OnClick action to copy the input value */
  onClickCopy?: () => void
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: KeyboardEvent) => void
}

const StyledMUIInput = styled(InputBase)(({ error }) => {
  return {
    width: '100%',
    flex: '1 1 6rem',
    [`.${inputBaseClasses.input}`]: {
      padding: '.8rem 0 .8rem 1.2rem !important',
      border: 'unset !important',
      color: `${error ? CORAL_600 : GRAY_900} !important`,
      backgroundColor: 'transparent !important',
      boxShadow: 'unset !important',
      ':focus': {
        color: `${GRAY_900} !important`
      }
    },
  }
})

const StyledCopyButton = styled(ButtonBase)(() => ({
  background: GRAY_100,
  borderLeft: `.1rem solid ${GRAY_400}`,
  padding: '.6rem .8rem',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '.8rem',
  backgroundColor: GRAY_100,
  height: '4rem',
  width: '9rem',
  boxSizing: 'border-box',
  borderRadius: '0 .8rem .8rem 0',
}))

/**
 * @component MUI Input field
 * @example
 * <MUIInputField
 *  type="copy"
 *  value="owo!"
 *  onChange={() => {}}
 * />
 */
export const MUIInputField: React.FC<Props> = ({
  type = 'default',
  value,
  isError,
  errorText,
  hintText,
  placeholder,
  readOnly = false,
  title = undefined,
  isMultiline,
  disabled = false,
  onBlur,
  onChange,
  onClickCopy,
  onKeyDown
}) => {

  const hoverBorderColor = useMemo(() => {
    if (isError) return 'inherit'
    if (disabled) return GRAY_400
    return BLUE_600
  }, [disabled, isError])

  const inputBgColor = useMemo(() => {
    if (isError) return CORAL_100
    if (disabled) return GRAY_100
    return 'inherit'
  }, [disabled, isError])

  return (
    <Stack gap={.6}>
      {!!title && <Typography variant='text-sm' fontWeight='medium' >{title}</Typography>}

      <BorderBoxWrapper
        borderColor={isError ? CORAL_600 : GRAY_400}
        backgroundColor={inputBgColor}
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transition: '.3s ease',
          '&:hover': {
            border: `.1rem solid ${hoverBorderColor}`,
          }
        }}
      >

        <StyledMUIInput
          value={value}
          error={isError}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          onBlur={onBlur}
          onChange={onChange}
          multiline={isMultiline}
          onKeyDown={onKeyDown}
        />

        {type === InputFieldTypes.COPY &&
          <StyledCopyButton>
            <CopyButton
              variant='text'
              showIcon={true}
              showTooltip={false}
              onCopy={onClickCopy}
            />
          </StyledCopyButton>
        }

      </BorderBoxWrapper>

      {!!hintText && !isError &&
        <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>{hintText}</Typography>
      }

      {isError &&
        <Typography variant="text-sm" fontWeight="medium" color={CORAL_600}>{errorText}</Typography>
      }

    </Stack>
  )
}
