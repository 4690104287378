import React, { FC } from 'react'
import classnames from 'classnames'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'

import styles from './OrderCreator.module.sass'

export const OrderCreator: FC = () => {
  const { dealData } = useGalleryDeal()

  if (!dealData) return <></>

  return (
    <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap="1rem">
      <Stack flexDirection="row" alignItems="center">
        <PersonOutlineOutlined className={classnames(styles.icon, styles.person)} />
        <span className={styles.owner}>{dealData.customer.name}</span>
      </Stack>
    </Stack>
  )
}
